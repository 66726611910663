export const Main = (props) => {
    return <main {...props}>{props.children}</main>;
};

export const H1 = (props) => {
    return <h1 {...props}>{props.children}</h1>;
};

export const H2 = (props) => {
    return <h2 {...props}>{props.children}</h2>;
};

export const H3 = (props) => {
    return <h3 {...props}>{props.children}</h3>;
};

export const H4 = (props) => {
    return <h4 {...props}>{props.children}</h4>;
};

export const H5 = (props) => {
    return <h5 {...props}>{props.children}</h5>;
};

export const H6 = (props) => {
    return <h6 {...props}>{props.children}</h6>;
};

export const Paragraph = (props) => {
    return <p {...props}>{props.children}</p>;
};

export const Span = (props) => {
    return <span {...props}>{props.children}</span>;
};

export const Section = (props) => {
    return <section {...props}>{props.children}</section>;
};
