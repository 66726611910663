export default function compareEnrolledCourses(by) {
    switch (by) {
        case 'TITLE':
            return (a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            };
        case 'INSTRUCTOR':
            return (a, b) => {
                if (a.instructor < b.instructor) {
                    return -1;
                }
                if (a.instructor > b.instructor) {
                    return 1;
                }
                return 0;
            };
        case 'RECENT':
            return (a, b) => {
                const A = a.updated_at || '1900-01-01T00:00:00.000Z';
                const B = b.updated_at || '1900-01-01T00:00:00.000Z';

                if (A > B) {
                    return -1;
                }
                if (A < B) {
                    return 1;
                }
                return 0;
            };

        case 'PERCENTAGE_COMPLTED':
            return (a, b) => {
                const A = a.percentage_completed || 0;
                const B = b.percentage_completed || 0;
                //if (a.percentage_completed && b.percentage_completed) {
                if (A > B) {
                    return -1;
                }
                if (A < B) {
                    return 1;
                }
                return 0;
                //}
                //return 0;
            };

        default:
            break;
    }
}
