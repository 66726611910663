import { useMemo } from 'react';
// import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import {
    SectionDesc,
    SectionHeading,
    SectionSubheading,
} from '../custom-components/styled-components';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    OutcomeContainer: { padding: '0.5rem', '& p': { fontSize: '1.25rem' } },
    OutcomeBox: {
        borderRadius: '0.375rem',
        '& > ul': {
            fontSize: '1.25rem',
            lineHeight: '1.75rem',
            margin: '0',
            padding: '0',
        },
        '& > ul li': {
            listStyleType: 'none',
            paddingBlock: '0.25rem',
        },
        '& > ul li::before': {
            content: "'✓ '",
            color: '#0f327e',
        },
        [theme.breakpoints.up('md')]: {
            '& > ul': {
                display: 'flex',
                gap: '2%',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                alignItems: "center"
            },
            '& > ul li': {
                flexBasis: '47%',
            },
        },
    },
}));

const NewLearningOutcomes = (props) => {
    const { classes } = useStyles();
    const { wpCourseData } = props;

    const outcomes = useMemo(() => {
        return ReactHtmlParser(wpCourseData?.learningOutcomes);
    }, [wpCourseData?.learningOutcomes]);

    return (
        <section id="learning-outcomes" className={classes.OutcomeContainer}>
            <SectionHeading>
                Learning Outcomes{' '}
                <span style={{ textTransform: 'none' }}>
                    (At the end of this program you will be able to)
                </span>
            </SectionHeading>

            <div className={classes.OutcomeBox}>{outcomes}</div>
        </section>
    );
};

// const OutcomeContainer = styled.section`
//     padding: 0.5rem;
// `;

// const OutcomeBox = styled.div`
//     border-radius: 0.375rem;

//     & > ul {
//         font-size: 1.25rem;
//         line-height: 1.75rem;
//         margin: 0;
//         padding: 0;

//         @media (min-width: 48em) {
//             display: flex;
//             gap: 2%;
//             justify-content: space-between;
//             flex-wrap: wrap;
//         }

//         & li {
//             list-style-type: none;
//             padding-block: 0.25rem;
//             &::before {
//                 content: '✓ ';
//                 color: #0f327e;
//             }

//             @media (min-width: 48em) {
//                 flex-basis: 47%;
//             }
//         }
//     }
// `;

export default NewLearningOutcomes;
