import Carousel from 'react-material-ui-carousel';
import pictureOne from './assets/splash-instructor-01.png';
import pictureTwo from './assets/splash-instructor-02.png';
import pictureThree from './assets/splash-instructor-03.png';
import pictureFour from './assets/splash-instructor-04.png';
import pictureFive from './assets/splash-instructor-05.png';
import { Box, Divider } from '../ui';
import { makeStyles } from 'tss-react/mui';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const postDetails = [
    {
        image: '',
        text: 'video',
    },
    {
        image: pictureOne,
        text: 'description1',
    },
    {
        image: pictureTwo,
        text: 'description2',
    },
    {
        image: pictureThree,
        text: 'description3',
    },
    {
        image: pictureFour,
        text: 'description3',
    },
    {
        image: pictureFive,
        text: 'description3',
    },
];

export const InstructorDisplayContent = () => {
    const { classes } = useStyles();
    return (
        <div className={classes.itemContain}>
            <Carousel
                animation="slide"
                autoPlay={false}
                interval={2000000000}
                navButtonsAlwaysVisible
            >
                {postDetails.map(({ image, text }, index) => {
                    return (
                        <Box key={index} className={classes.container}>
                            {text == 'video' ? (
                                <div style={{ height: 290 }}>
                                    <iframe
                                        title="signup video"
                                        src="https://player.vimeo.com/video/590614215?autoplay=1&loop=1"
                                        width="100%"
                                        height="100%"
                                        frameborder="0"
                                        allow="autoplay; fullscreen; picture-in-picture"
                                        allowfullscreen
                                        autoplay={true}
                                    />
                                </div>
                            ) : (
                                <LazyLoadImage
                                    className={classes.image}
                                    src={image}
                                    alt=""
                                    width="100%"
                                />
                            )}
                            <Divider style={{ width: '100%' }} />
                        </Box>
                    );
                })}
            </Carousel>
        </div>
    );
};

const useStyles = makeStyles()((theme) => ({
    container: {
        width: '82%',
        margin: '0 auto',
        display: 'flex',
        flexFlow: 'column',
    },
    itemContain: {
        marginTop: '30px',
    },
    text: {
        textAlign: 'center',
        marginTop: '10px',
        fontSize: '1.2em',
        fontWeight: 600,
    },
    image: {
        height: '400px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginTop: '20px',
        boxShadow: '0px 0px 20px 5px rgba(0,0,0,0.2)',
        [theme.breakpoints.only('md')]: {
            height: '360px',
        },
        [theme.breakpoints.only('sm')]: {
            height: '300px',
        },
        [theme.breakpoints.only('xs')]: {
            height: '250px',
        },
    },
}));
