import { Box, Button, Typography } from '@mui/material';
import ReactHTMLParser from "react-html-parser"
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
// import styled from 'styled-components';
import {
    SectionHeading,
    SectionContainer,
} from '../custom-components/styled-components';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    InstructorWrapper: { backgroundColor: 'white', padding: '1rem' },
    CardContainer: {
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        gap: '2rem',
        flexDirection: 'column',
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            textAlign: 'left',
        },
    },
    ImgWrapper: {
        width: '192px',
        height: '215.2px',
        '.my-class > img': {
            height: '100%',
            width: '100%',
            borderRadius: '10px',
            maxWidth: '1000px',
            fallbacks: [
                {
                    width: '100%',
                },
            ],
            textAlign: 'center',
            objectFit: 'cover',
        },
        [theme.breakpoints.up('md')]: {
            alignSelf: 'flex-start',
        },
    },
    InstructorName: { padding: '0', margin: '0' },
    InstructorBio: {
        maxWidth: '1000px',
        width: '100%',
        margin: '0',
        padding: '0',
        fontSize: '1rem',
        marginBlock: '0.8rem',
    },
}));

const Instructor = (props) => {
    const { classes } = useStyles();
    const { wpCourseData } = props;

    const instructors = useMemo(() => {
        return wpCourseData?.instructors;
    }, [wpCourseData?.instructors]);

    // const instructorSlug = useMemo(() => {
    //     if (
    //         wpCourseData?.instructorSlug &&
    //         wpCourseData?.instructorSlug !== ''
    //     ) {
    //         return `/channels/${wpCourseData.instructorSlug}/on-air`;
    //     } else {
    //         return '#';
    //     }
    // }, [wpCourseData.instructorSlug]);

    return (
        <SectionContainer id="instructor">
            <SectionHeading>Instructors</SectionHeading>

            <div className={classes.InstructorWrapper}>
                {instructors.map((instructor) => {
                    return (
                        <div
                            key={instructor?.instructor}
                            className={classes.CardContainer}
                        >
                            <div className={classes.ImgWrapper}>
                                <img
                                    src={
                                        instructor?.instructorPic ||
                                        instructor.instructorPicNoBackground
                                    }
                                    width="100%"
                                    alt={instructor?.instructor}
                                />
                            </div>

                            <div>
                                <h2 className={classes.InstructorName}>
                                    {instructor?.instructor}
                                </h2>

                                <p className={classes.InstructorBio}>
                                    {ReactHTMLParser(instructor?.instructorBio)}
                                </p>

                                <Link
                                    to={`/channels/${instructor?.instructorSlug}`}
                                >
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                    >
                                        View my channel
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    );
                })}
            </div>
        </SectionContainer>
    );
};

// const InstructorWrapper = styled.div`
//     background-color: white;
//     padding: 1rem;
// `;

// const CardContainer = styled.div`
//     background-color: white;
//     display: flex;
//     align-items: center;
//     gap: 2rem;
//     flex-direction: column;
//     text-align: center;

//     @media (min-width: 48em) {
//         flex-direction: row;
//         text-align: left;
//     }
// `;

// const ImgWrapper = styled.div`
//     width: 192px;
//     height: 215.2px;

//     @media (min-width: 48em) {
//         align-self: flex-start;
//     }

//     & > img {
//         height: 100%;
//         width: 100%;
//         /* box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
//             0px 10px 14px 1px rgba(0, 0, 0, 0.14),
//             0px 4px 18px 3px rgba(0, 0, 0, 0.12); */
//         border-radius: 10px;
//         max-width: 1000px;
//         width: 100%;
//         text-align: center;
//         object-fit: cover;
//     }
// `;

// const InstructorName = styled.h2`
//     padding: 0;
//     margin: 0;
// `;

// const InstructorBio = styled.p`
//     max-width: 1000px;
//     width: 100%;
//     margin: 0;
//     padding: 0;
//     font-size: 1rem;
//     margin-block: 0.8rem;
// `;

export default Instructor;
