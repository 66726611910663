import { useState } from 'react';
import { Box } from '../ui';
import { useStyles } from './styles';
import { CommentsInput } from './CommentsInput';
import { useCallback } from 'react';
import axios from 'axios';
import { ReplyItem } from './ReplyItem';
import { useDispatch, useSelector } from 'react-redux';
import {
    REPLY,
    setCommentId,
    setReplyId,
    setType,
} from './slices/commentAttachmentSlice';
// import { addReplySelector } from './recoil';
// import { useSetRecoilState } from 'recoil';

export const ReplyBox = ({
    showAddReply = false,
    showReplies = false,
    user,
    commentUserName = '',
    starweaverAdmin = false,
    commentId,
    blockAccess,
    showAllReplies = () => console.log('open-replies'),
    replies = [],
    hideAddReplies = () => console.log('hide-replies'),
    setComments = undefined,
    mentionables = [],
    tasks = [],
    teams = [],
    tasksOutputTemplate,
    showCommentAttachments = false,
    showAttachments = false,
}) => {
    const { classes } = useStyles();
    // const addReplyToState = useSetRecoilState(addReplySelector);
    const dispatch = useDispatch();
    const commentAttachments = useSelector((state) => state.commentAttachments);

    const [showEditor, setShowEditor] = useState(false);

    const addReply = useCallback(
        async ({
            userName,
            name,
            comment,
            emails,
            teamIds,
            hasFiles = false,
        }) => {
            let errorObject = {
                error: true,
                message: '',
            };
            if (!userName) {
                if (!name) {
                    errorObject.message = 'Please enter a display name';
                    return errorObject;
                }
            }
            if (!comment) {
                errorObject.message = 'Please enter a comment';
                return errorObject;
            }
            const replyObject = {
                userId: user?._id,
                commentText: comment,
                userName: userName ? '' : name,
                mentions: emails,
                mentionedTeamIds: teamIds,
                hasFiles,
            };

            try {
                const { data } = await axios.post(
                    `/api/content/${commentId}/comment/reply`,
                    replyObject
                );
                // addReplyToState({ replyObject: data.reply, commentId });
                if (commentAttachments.isFile) {
                    dispatch(setCommentId(commentId));
                    dispatch(setType(REPLY));
                    dispatch(setReplyId(data.reply._id));
                }

                if (setComments !== undefined) {
                    setComments((prev) => {
                        return prev.map((comment) => {
                            if (comment?._id === commentId) {
                                return {
                                    ...comment,
                                    replies: [data.reply, ...comment.replies],
                                };
                            } else {
                                return { ...comment };
                            }
                        });
                    });
                }
                showAllReplies();
                return data;
            } catch (error) {
                console.log(error);
                errorObject.message =
                    'Oops something happened, Please try again later!';
                return errorObject;
            }
        },
        [
            user?._id,
            commentId,
            commentAttachments.isFile,
            setComments,
            showAllReplies,
            dispatch,
        ]
    );

    return (
        <Box>
            <Box className={`${classes.replyBox}`}>
                <Box
                    className={`${classes.addReply} ${
                        showAddReply
                            ? classes.openReplies
                            : classes.closeReplies
                    }`}
                >
                    <CommentsInput
                        user={user}
                        blockAccess={blockAccess}
                        small={true}
                        buttonText="Reply"
                        label="Your Reply"
                        placeholder={`Reply to ${commentUserName}`}
                        closeComment={hideAddReplies}
                        finalFunction={addReply}
                        mentionables={mentionables}
                        tasks={tasks}
                        teams={teams}
                        tasksOutputTemplate={tasksOutputTemplate}
                        showCommentAttachments={showCommentAttachments}
                        showEditor={showEditor}
                        setShowEditor={setShowEditor}
                        hideCourseTagging={user?.is_instructor}
                    />
                </Box>
                <Box
                    className={`${classes.replies} ${
                        showReplies ? classes.openReplies : classes.closeReplies
                    }`}
                >
                    {replies.length > 0 &&
                        replies.map((reply) => (
                            <Box mt={1} key={replies?._id}>
                                <ReplyItem
                                    reply={reply}
                                    starweaverAdmin={starweaverAdmin}
                                    user={user}
                                    blockAccess={blockAccess}
                                    commentId={commentId}
                                    setComments={setComments}
                                    mentionables={mentionables}
                                    tasks={tasks}
                                    teams={teams}
                                    tasksOutputTemplate={tasksOutputTemplate}
                                    showCommentAttachments={
                                        showCommentAttachments
                                    }
                                    showAttachments={showAttachments}
                                />
                            </Box>
                        ))}
                </Box>
            </Box>
        </Box>
    );
};
