import { Avatar, Box, Step, Stepper, StepLabel } from '../';
import withStyles from '@mui/styles/withStyles';
import { makeStyles } from 'tss-react/mui';
import StepConnector from '@mui/material/StepConnector';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

const avatarDimensions = 70;

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 33,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    line: {
        height: 4,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

const avatarStyles = {
    width: avatarDimensions - 10,
    height: avatarDimensions - 10,
};
const useColorlibStepIconStyles = makeStyles()((theme) => ({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: avatarDimensions,
        height: avatarDimensions,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
}));

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={`${classes.root} ${active ? classes.active : ''} ${
                completed ? classes.completed : ''
            }
        `}
        >
            {props.imgUrl ? (
                <Avatar style={avatarStyles} src={props.imgUrl} />
            ) : (
                <Avatar style={avatarStyles}>?</Avatar>
            )}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
    imgUrl: PropTypes.string,
};

export const StepperImages = ({
    steps,
    step,
    isClickable = true,
    defaultFunction = () => {
        console.log('def' + isClickable);
    },
}) => {
    let navigate = useNavigate();
    const handleClick = (link) => {
        if (link) {
            navigate(link);
        } else {
            defaultFunction();
        }
    };

    return (
        <Box mt={2}>
            <Stepper
                alternativeLabel
                activeStep={step}
                style={{
                    backgroundColor: '#fafafa',
                }}
                connector={<ColorlibConnector />}
            >
                {steps.map(({ label, imgUrl, link }) => (
                    <Step onClick={() => handleClick(link)} key={label}>
                        <StepLabel
                            style={{
                                cursor: isClickable ? 'pointer' : 'default',
                            }}
                            StepIconComponent={ColorlibStepIcon}
                            StepIconProps={{ imgUrl: imgUrl }}
                        >
                            {ReactHtmlParser(label)}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};
