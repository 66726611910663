import JourneyImg from '../img/journey.svg';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    noJourneyContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'column',
        '& > p': {
            margin: 0,
            fontWeight: '400',
            fontSize: '1.5rem',
            color: 'gray',
        },
    },
    imgContainer: {
        width: '100%',
        maxWidth: '210px',
        '& > img': {
            width: '100%',
            height: 'auto',
            aspectRatio: '1/1',
        },
    },
}));

export const NoPivateJourney = () => {
    const { classes } = useStyles();

    return (
        <div className={classes.noJourneyContainer}>
            <div className={classes.imgContainer}>
                <img src={JourneyImg} alt="journey" height={100} width={100} />
            </div>
            <p>We are adding more journeys please wait!!</p>
        </div>
    );
};
