import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { useMemo } from 'react';

export const SelectOptions = ({
    value,
    setValue = () => {},
    title = 'Sort By',
    options = [],
    dense = false,
    index,
    filterGroupIndexes,
    handleChangeInIndex,
    disabled,
    size,
}) => {
    const memoizedLabel = useMemo(() => {
        if (options.length > 0) {
            return options.filter((option) => option.value === value)[0]?.label;
        }

        return value;
    }, [options, value]);

    // const handleChange = (event) => {
    //     setValue(event.target.value);
    // };

    /* return options.length ? (
        <Box m={1}>
            <TextField
                size={size || 'normal'}
                id="standard-select-currency"
                select
                label={title}
                variant="outlined"
                value={value}
                margin={dense ? 'dense' : 'normal'}
                SelectProps={{
                    MenuProps: {
                        disablePortal: true,
                    },
                }}
                style={{
                    minWidth: '180px',
                }}
                color="secondary"
                inputProps={{
                    MenuProps: {
                        disableScrollLock: true,
                        style: { height: '500px' },
                    },
                }}
                onChange={
                    handleChangeInIndex
                        ? (e) => {
                              handleChangeInIndex(
                                  e.target.value,
                                  index,
                                  filterGroupIndexes
                              );
                          }
                        : handleChange
                }
                disabled={disabled}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        </Box>
    ) : null; */

    return (
        <>
            {options.length > 0 && (
                <Box m={1}>
                    <Autocomplete
                        id="options-free-solo"
                        disablePortal
                        options={options}
                        disabled={disabled}
                        getOptionLabel={(option) =>
                            option?.label || memoizedLabel || value
                        }
                        value={value}
                        sx={{ minWidth: '180px' }}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                if (handleChangeInIndex) {
                                    handleChangeInIndex(
                                        newValue.value,
                                        index,
                                        filterGroupIndexes
                                    );
                                } else {
                                    setValue(newValue.value);
                                }
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={title}
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    style: { minWidth: '180px' },
                                }}
                                size={size || 'normal'}
                                variant="outlined"
                                margin={dense ? 'dense' : 'normal'}
                                color="secondary"
                            />
                        )}
                        renderOption={(props, option) => (
                            <Box component="li">
                                <Typography variant="body1" {...props}>
                                    {option.label}
                                </Typography>
                            </Box>
                        )}
                    />
                </Box>
            )}
        </>
    );
};
