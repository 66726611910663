import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

export const useCourseLike = (courseId) => {
    const [likes, setLikes] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchLikes = useCallback(async () => {
        if (courseId) {
            setIsLoading(true);
            try {
                console.log('Fetching Likes...');
                const {
                    data: { likes },
                } = await axios.get(`/api/content/${courseId}/course/likes`);
                setLikes(likes);
                console.log('Fetched Likes...');
            } catch (error) {
                console.log(error.message);
            }
            setIsLoading(false);
        } else {
            console.log('no-course-id');
        }
    }, [courseId]);

    useEffect(() => {
        if (courseId && !likes) {
            fetchLikes();
        } else {
            if (likes.length) {
                setIsLoading(false);
            }
        }
        // eslint-disable-next-line
    }, [courseId, likes, fetchLikes]);

    return { likes, isLoading, updateLikes: fetchLikes };
};
