import axios from 'axios';
import {
    Grid,
    CircularProgress,
    Typography,
    Button,
    Box,
    UnEnrollDialog,
} from '../ui';
import { FormatListBulletedIcon } from '../icons';
import { Link } from 'react-router-dom';
import { setOnAirValues } from '../dashboard';
import { useSetRecoilState } from 'recoil';
import { UserRsvps } from './UserRsvps';
import { useStyles } from './styles';
import { useState } from 'react';
import { SavedCourses } from '../dashboard/SavedCourses';

export const SavedOnAirRsvps = ({ data, user, savedCourse }) => {
    const remove = useSetRecoilState(setOnAirValues);
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);

    const handleClickOnCard = (course) => {
        setSelectedCourse(course);
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const removeRSVP = async (course) => {
        let arrayForUpdateInState = [];

        console.log(course.endDate);
        const rsvpObject = {
            slug: course.name,
            endTime: course.endDate.date,
            timezone:
                course.endDate.timezone === 'UTC'
                    ? 'America/New_York'
                    : course.endDate.timezone,
        };

        data.forEach((item) => {
            if (
                item.name !== rsvpObject.slug &&
                item.endTime !== rsvpObject.endTime
            ) {
                arrayForUpdateInState.push(item);
            }
        });

        try {
            remove(arrayForUpdateInState);
            await axios.put(`/api/users/${user._id}/removeRsvp`, {
                updatedArray: rsvpObject,
            });
            console.log('removed');
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <Grid container xs={12}>
            {!data || !savedCourse || data == null ? (
                <Grid item xs={12} className={classes.load}>
                    <CircularProgress color="secondary" />
                </Grid>
            ) : // IF LENGTH OF DATA IS OF ZERO LENGTH
            data.length > 0 || savedCourse.length > 0 ? (
                <Grid container xs={12}>
                    {savedCourse.length > 0 ? (
                        <Grid container xs={12}>
                            <SavedCourses
                                savedCourse={savedCourse}
                                user={user}
                                isDashboard={true}
                            />
                        </Grid>
                    ) : (
                        ''
                    )}{' '}
                    {data.length > 0 ? (
                        <>
                            <UserRsvps
                                data={data}
                                onClickRemove={handleClickOnCard}
                            />
                            {selectedCourse && (
                                <UnEnrollDialog
                                    onClose={onClose}
                                    title={selectedCourse.title}
                                    handleRemove={removeRSVP}
                                    open={open}
                                    course={selectedCourse}
                                />
                            )}
                        </>
                    ) : (
                        ''
                    )}
                </Grid>
            ) : (
                <Grid item xs={12} className={classes.load}>
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            You don't have any saved courses or RSVPs
                        </Typography>
                        {/* <Typography variant="h3" gutterBottom>
                            <strong>Well, let's fix that!</strong>
                        </Typography> */}
                        <Link to="/browse" className={classes.browseLink}>
                            <Button
                                variant="contained"
                                startIcon={<FormatListBulletedIcon />}
                            >
                                Browse
                            </Button>
                        </Link>
                        {/* <Link to="/on-air">
                            <Button
                                variant="outlined"
                                color="secondary"
                                style={{ marginRight: '25px' }}
                            >
                                View ON AIR
                            </Button>
                        </Link>
                        <Link to="/courses">
                            <Button variant="outlined" color="secondary">
                                View Courses
                            </Button>
                        </Link> */}
                    </Box>
                </Grid>
            )}
        </Grid>
    );
};
