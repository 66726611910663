export const mentionsOutputTemplate = `<span style="display:inline;font-weight:bold;color:blue"  class="sw-ckeditor-name">{_name}<span style="display:none" class="sw-ckeditor-email">{email}</span></span>&nbsp;`;
export const mentionsItemTemplate =
    '<li data-id="{id}"><div style="padding:8px">{_name}</div> <div style="color:grey">{email}</div></li>';
export const mentionsItemTemplateNameOnly =
    '<li data-id="{id}">' +
    '<div style="display:flex; align-items:center; padding:8px;">' +
    '<img src="{profilePicUrl}" alt="{_name}" style="width:40px; height:40px; border-radius:50%; margin-right:8px; object-fit:cover;">' +
    '<div style="font-size:0.9rem;">{_name}</div>' +
    '</div>' +
    '</li>';

export const requirementTasksOutputTemplate = (requirementId) =>
    // `<a href="/admin/opportunity-management/details/${requirementId}/logistics" target="_blank" style="display:inline;font-weight:bold;color:green">{_name}</a>&nbsp;`;
    `<span style="display:inline;font-weight:bold;color:green" class="sw-ckeditor-task">{_name}</span>&nbsp;`;

/**
 *
 * @param {*} requirementId
 * @param {*} name taskName
 * @returns
 */
export const requirementTasksOutputTemplateInit = (requirementId, name) => {
    const template = requirementTasksOutputTemplate(requirementId);
    return '<p>' + template.replace('{_name}', name) + '</p>';
};

export const tasksItemTemplate =
    '<li data-id="{id}"><div style="font-size:0.9rem;padding:8px">{_name}</div></li>';

export const teamsOutputTemplate = `<span style="display:inline;font-weight:bold;color:red"  class="sw-ckeditor-team-name">{_name}<span style="display:none" class="sw-ckeditor-team-id">{id}</span></span>&nbsp;`;
export const teamsItemTemplate =
    '<li data-id="{id}"><div style="font-size:0.9rem;padding:8px">{_name}</div></li>';

export const requirementsOutputTemplate = `<span style="display:inline;font-weight:bold;color:orange"  class="sw-ckeditor-requirement-name">{_name}<span style="display:none" class="sw-ckeditor-requirement-id">{id}</span></span>&nbsp;`;
export const requirementsItemTemplate =
    '<li data-id="{id}"><div style="font-size:0.9rem;padding:8px">{_name}</div></li>';
