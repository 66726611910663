import { atom } from 'recoil';

export const allOnAirState = atom({
    key: 'allOnAirState',
    default: null,
});

export const topOnAirCourseState = atom({
    key: 'topOnAirCourseState',
    default: null,
});

export const liveLabsState = atom({
    key: 'liveLabsState',
    default: null,
});
export const userOnAirState = atom({
    key: 'userOnAirState',
    default: null,
});

export const userLiveLabsState = atom({
    key: 'userLiveLabsState',
    default: null,
});

export const savedCourseState = atom({
    key: 'savedCourseState',
    default: [],
});

export const savedOnDemandState = atom({
    key: 'savedOnDemandState',
    default: [],
});

export const savedCertificationsState = atom({
    key: 'savedCertificationsState',
    default: [],
});

export const enrolledAndPurchasedOnDemandState = atom({
    key: 'enrolledAndPurchasedOnDemandState',
    default: null,
});

export const dismissedRecommededCourseIdsState = atom({
    key: 'dismissedRecommededCourseIdsState',
    default: null,
});

export const dismissedRecommededOnAirSlugDateState = atom({
    key: 'dismissedRecommededOnAirSlugDateState',
    default: null,
});

export const privateCourseState = atom({
    key: 'privateCourseState',
    default: [],
});

export const purchasedCourseState = atom({
    key: 'purchasedCourseState',
    default: [],
});

export const purchasedCourseFetchedForUserState = atom({
    key: 'purchasedCourseFetchedForUserState',
    default: null,
});

export const purchasedJourneysState = atom({
    key: 'purchasedJourneysState',
    default: [],
});

export const purchasedRetroCoursesState = atom({
    key: 'purchasedRetroCoursesState',
    default: [],
});
