import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            border: '1px dashed',
            borderColor: theme.palette.grey['400'],
            borderRadius: '0.25rem',
            marginTop: '0.5rem',
            transition: 'box-shadow 150ms linear',
            '&:hover': {
                boxShadow:
                    '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
            },
        },
        dropboxHeader: {
            padding: theme.spacing(1.5),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
        },
        dropboxHeader__selectText: {
            color: theme.palette.success['light'],
            fontWeight: '500',
            cursor: 'pointer',
            userSelect: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        dropboxHeader__noteText: {
            color: theme.palette.secondary.main,
            fontWeight: '500',
        },
        selectedFiles: {
            padding: theme.spacing(1, 1.5),
            borderTop: '1px dashed',
            borderColor: theme.palette.grey['400'],
        },
        selectedFiles__items: {
            padding: 0,
            margin: 0,
        },
        fileItem: {
            listStyleType: 'none',
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
            },
        },
        fileItem__left: {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(2),
        },
        fileItem__info: {
            display: 'flex',
            alignItems: 'center',
            gap: '0.6rem',
            flexWrap: 'wrap',
        },
        fileItem__name: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '25ch',
            [theme.breakpoints.up('sm')]: {
                maxWidth: '30ch',
            },
            [theme.breakpoints.up('md')]: {
                maxWidth: '55',
            },
            [theme.breakpoints.up('lg')]: {
                maxWidth: 'none',
            },
        },
        fileItem__right: {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(4),
            marginLeft: 'auto',
        },
        fileItem__icon: {
            padding: '0.1rem 0.3rem',
            borderRadius: '0.25rem',
            border: `1px solid ${theme.palette.grey['300']}`,
            color: theme.palette.primary.light,
            fontSize: '0.75rem',
            fontWeight: '500',
            letterSpacing: '0.05rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        attachments: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: '0.5rem',
        },
        attachment: {
            userSelect: 'none',
            border: `1px solid ${theme.palette.grey['300']}`,
            width: '8rem',
            height: '8rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            borderRadius: '0.25rem',
            [theme.breakpoints.up('md')]: {
                // width: '10rem',
                // height: '10rem',
                '& > .attachment__delete': {
                    transition: 'opacity 150ms linear',
                    opacity: 0,
                },
                '&:hover > .attachment__delete': {
                    opacity: 1,
                },
            },
        },
        attachment__header: {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingInline: '0.5rem',
        },
        attachment__ext: {
            textAlign: 'center',
            textTransform: 'uppercase',
            fontSize: '0.75rem',
            lineHeight: '1rem',
            fontWeight: 600,
            backgroundColor: '#dbeafe',
            padding: '0.5rem',
            width: 'fit-content',
            aspectRatio: 1,
            borderRadius: '0.25rem',
            alignSelf: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // [theme.breakpoints.up('md')]: {
            //     padding: '1rem',
            //     fontSize: '1rem',
            //     lineHeight: '1.5rem',
            // },
        },
        attachment__info: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderTop: `1px solid ${theme.palette.grey['300']}`,
            paddingInline: '0.5rem',
            paddingBlock: '0.25rem',
            gap: '1rem',
        },
        attachment__name: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    };
});

export default useStyles;
