import { Box, Loading } from '../ui';
// import { useUserComments } from './useUserComments';
import { CourseComments } from '../instructor/CourseComments';
import { makeStyles } from 'tss-react/mui';
import NoCommentImg from '../img/no_comment.svg';
import { useMemo } from 'react';

const useStyles = makeStyles()((theme) => ({
    noCommentContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        padding: '5px',
        '& > h3': {
            color: 'gray',
        },
    },
    noCommentImg: {
        maxHeight: 150,
        aspectRatio: '1 / 1',
        height: 'auto',
        objectFit: 'cover',
    },
}));

const NoComment = () => {
    const { classes } = useStyles();

    return (
        <div className={classes.noCommentContainer}>
            <img
                src={NoCommentImg}
                alt="no comments"
                height={300}
                width={200}
                className={classes.noCommentImg}
            />
            <h3>No comments to display</h3>
        </div>
    );
};

export const UserChats = ({
    // user,
    isUserLoading,
    enrolledAndPurchasedOnDemand, //all the enrolled courses
    loading, // loading state for fetching enrolled courses
}) => {
    // const { isLoading, courses } = useUserComments(user._id);

    const memoizedEnrolledCourses = useMemo(() => {
        if (!enrolledAndPurchasedOnDemand) return [];

        return enrolledAndPurchasedOnDemand?.map((course) => {
            return {
                course_id: course?.course_id?.toString(),
                course_name: course?.name,
                course_slug: course?.slug,
                id: course?.id?.toString(),
                name: course?.name,
                slug: course?.slug,
            };
        });
    }, [enrolledAndPurchasedOnDemand]);

    return (
        <Box>
            {/* {isLoading || isUserLoading ? (
                <Loading height="500px" />
            ) : courses.length > 0 ? (
                <Box>
                    <CourseComments courses={courses} />
                </Box>
            ) : (
                <NoComment />
            )} */}

            {loading || isUserLoading ? (
                <Loading height="500px" />
            ) : memoizedEnrolledCourses.length > 0 ? (
                <Box>
                    <CourseComments courses={memoizedEnrolledCourses} />
                </Box>
            ) : (
                <NoComment />
            )}
        </Box>
    );
};
