import { IconButton } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import useStyles from './styles';
import { AzureBlobStorage } from '../../util/BlobStorage';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../../app/recoil';
import { CircularProgressWithLabel, PageLoader } from '../../ui';

function Attachment({
    file,
    containerName,
    setFileData,
    dirName,
    handleAttachmentClick = () => console.log('fn'),
    showPageLoader = false,
}) {
    const { classes } = useStyles();
    const [isDonwloading, setIsDownloading] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState(0);
    const setAlert = useSetRecoilState(alertState);
    const [isDeleting, setIsDeleting] = useState(false);

    const onDownload = async (e) => {
        try {
            e.stopPropagation();
            const azureBlobStorage = new AzureBlobStorage();
            setIsDownloading(true);
            const url = await azureBlobStorage.downloadFile(
                file?.actual,
                containerName,
                setDownloadProgress
            );
            if (!url) throw new Error('Something went worng');
            const a = document.createElement('a');
            const id = Math.random();
            a.setAttribute('id', id);
            document.body.appendChild(a);
            a.style = 'display: none';
            a.href = url;
            a.download = file?.formatted;
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            setAlert({
                show: true,
                severity: severity.SUCCESS,
                message: 'Download successful',
            });
        } catch (e) {
            setAlert({
                show: true,
                severity: severity.ERROR,
                message: e?.message,
            });
        } finally {
            setDownloadProgress(0);
            setIsDownloading(false);
        }
    };

    const onDelete = async (e) => {
        try {
            e.stopPropagation();
            setIsDeleting(true);
            const azureBlobStorage = new AzureBlobStorage();
            const { fileNames, size } = await azureBlobStorage.deleteFile(
                file?.actual,
                containerName,
                dirName
            );
            setFileData({ fileNames, size });
        } catch (error) {
            console.log(error);
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <div
            className={classes.attachment}
            style={{
                cursor: ['IMAGE', 'VIDEO', 'PDF'].includes(file.type)
                    ? 'pointer'
                    : 'auto',
            }}
            onClick={() => handleAttachmentClick(file)}
        >
            <PageLoader open={isDeleting || isDonwloading || showPageLoader} />
            <div className={`${classes.attachment__header} attachment__delete`}>
                <IconButton
                    size="small"
                    disabled={isDeleting || isDonwloading}
                    onClick={onDelete}
                >
                    <DeleteOutlinedIcon fontSize="small" color="error" />
                </IconButton>
            </div>
            <div className={classes.attachment__ext}>{file.extension}</div>
            <div className={classes.attachment__info}>
                <span className={classes.attachment__name}>
                    {file.formatted}
                </span>
                {isDonwloading ? (
                    <CircularProgressWithLabel
                        value={downloadProgress}
                        color="secondary"
                        size={28}
                        fontSize={'0.8rem'}
                    />
                ) : (
                    <IconButton
                        size="small"
                        onClick={onDownload}
                        disabled={isDeleting || isDonwloading}
                    >
                        <FileDownloadOutlinedIcon
                            fontSize="small"
                            color="primary"
                        />
                    </IconButton>
                )}
            </div>
        </div>
    );
}

export default Attachment;
