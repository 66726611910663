import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const CircularSpinner = (props) => {
    const { show } = props;
    const { classes } = useStyles();

    return (
        <Backdrop className={classes.backdrop} open={show}>
            <CircularProgress color="secondary" />
        </Backdrop>
    );
};

export default CircularSpinner;
