import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid } from '@mui/material';
import { useUser } from '../auth/useUser';
import { useNavigate } from 'react-router-dom';
//import { PrivateCoursesListItem } from './PrivateCoursesListItem';
import { CoursesListItem } from './CoursesListItem';
import { CoursesListItemSkeleton } from './CoursesListItemSkeleton';

export const PrivateCoursesList = ({ bundleName }) => {
    const { user } = useUser();
    const [courses, setCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    let navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                setCourses([]);
                setIsLoading(true);

                const authtoken = await user.getIdToken();
                const res = await axios.get(
                    `/api/content/courses/private-bundle?bundleName=${bundleName}`,
                    {
                        headers: { authtoken },
                    }
                );

                setCourses(res.data);
                setIsLoading(false);
            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    const onClickCourse = async (slug) => {
        navigate(`/courses/${slug}`);

        /* try {
            const authtoken = await user.getIdToken();
            const response = await axios.get(`/api/sso/${slug}/thinkific?clientId=${
                    user.client_id
                }`, {
                headers: { authtoken },
                // firstName: user.user_firstName,
                // lastName: user.user_lastName,
            });
            const ssoUrl = response.data;
            window.open(ssoUrl, '_blank');
        } catch (e) {
            console.log(e);
        } */
    };

    // We need to provide some fake data for the Skeleton ui while loading
    /* const displayCourses = isLoading
        ? Array(3).fill({ slug: '' }) // the fake data
        : courses; */

    return (
        <Grid container spacing={2} justifyContent="flex-start">
            {/* {displayCourses.map((course, i) => (
            <PrivateCoursesListItem
                isLoading={isLoading}
                video={course}
                onClickCourse={onClickCourse}
                key={i}
                xs={12}
                md={6}
                lg={4}
            />
        ))} */}

            {!isLoading
                ? courses.map((course, i) => (
                      <CoursesListItem
                          key={i}
                          xs={12}
                          md={4}
                          lg={3}
                          onClickCourse={onClickCourse}
                          slug={course.slug}
                          thumbnail={course.course_card_image_url}
                          title={course.name}
                          description={course.description}
                      />
                  ))
                : Array(3)
                      .fill()
                      .map(() => (
                          <CoursesListItemSkeleton xs={12} md={4} lg={3} />
                      ))}
        </Grid>
    );
};
