import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Box,
    CircularProgress,
    Grid,
    Typography,
    Button,
    //UnEnrollDialog,
} from '../ui';
import { FormatListBulletedIcon } from '../icons';
// import { JourneyCourseCarousel } from './JourneyCourseCarousel';
import { UnEnrollJourneyDialog } from '../journeys';

import { useStyles } from './styles';
import axios from 'axios';
import { JourneyCard } from './JourneyCard';

export const SavedJourneys = ({
    user,
    displayCertification = false,
    sectionType,
}) => {
    const { classes } = useStyles();

    const [journeys, setJourneys] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openUnEnrollDialog, setOpenUnEnrollDialog] = useState(false);
    const [journeyIdToUnEnroll, setJourneyIdToUnEnroll] = useState(null);

    const onUnEnrollDialogClose = () => {
        setOpenUnEnrollDialog(false);
    };

    // useEffect(() => {
    //     (async () => {
    //         try {
    //             setIsLoading(true);

    //             if (user && user.user_subscription_id !== '') {
    //                 const { data } = await axios.get(
    //                     `/api/journeys-old/${user._id}`
    //                 );
    //                 const journeyIds = data
    //                     .filter(
    //                         (item) =>
    //                             item.hasCertificate === displayCertification
    //                     )
    //                     .map((item) => item.id);
    //                 const resJourneys = await axios.get(
    //                     `${
    //                         process.env.REACT_APP_SW_WORDPRESS_DOMAIN
    //                     }/wp-json/headless/journeys/by-ids?id=${journeyIds.join()}`
    //                 );
    //                 console.log(resJourneys);
    //                 setJourneys(resJourneys.data);
    //                 setIsLoading(false);
    //             } else {
    //                 const {
    //                     data: { products },
    //                 } = await axios.get(
    //                     `/api/users/${user._id}/purchased-journeys`
    //                 );
    //                 const journeyIds = products
    //                     .filter(
    //                         (item) =>
    //                             item.hasCertificate === displayCertification
    //                     )
    //                     .map((item) => item.product_id);
    //                 const resJourneys = await axios.get(
    //                     `${
    //                         process.env.REACT_APP_SW_WORDPRESS_DOMAIN
    //                     }/wp-json/headless/journeys/by-ids?id=${journeyIds.join()}`
    //                 );
    //                 setJourneys(resJourneys.data);
    //                 setIsLoading(false);
    //             }
    //         } catch (err) {
    //             setIsLoading(false);
    //             console.log(err);
    //         }
    //     })();
    // }, [user, displayCertification]);

    const removeJourney = async () => {
        try {
            await axios.delete(
                `/api/journeys-old/${journeyIdToUnEnroll}/${user._id}?type=${sectionType}`
            );

            setJourneys(
                journeys.filter(({ id }) => journeyIdToUnEnroll !== id)
            );
            //setJourneyIdToUnEnroll(null);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Box>
            {!isLoading ? (
                journeys.length > 0 ? (
                    <>
                        {journeys.map((journey) => (
                            <JourneyCard
                                journey={journey}
                                setOpenUnEnrollDialog={setOpenUnEnrollDialog}
                                setJourneyIdToUnEnroll={setJourneyIdToUnEnroll}
                            />
                        ))}
                        {/* NOT USED */}
                        {/* {journeys.map(
                            ({ id, title, coursesIncluded, slug }, index) => (
                                <JourneyCourseCarousel
                                    data={coursesIncluded}
                                    heading={title}
                                    user={user}
                                    headingLink={`/${sectionType}/${slug}`}
                                    journeyId={id}
                                    setOpenUnEnrollDialog={
                                        setOpenUnEnrollDialog
                                    }
                                    setJourneyIdToUnEnroll={
                                        setJourneyIdToUnEnroll
                                    }
                                />
                            )
                        )} */}

                        <UnEnrollJourneyDialog
                            onClose={onUnEnrollDialogClose}
                            handleRemove={removeJourney}
                            open={openUnEnrollDialog}
                        />
                    </>
                ) : (
                    <Grid
                        container
                        alignItems="center"
                        style={{
                            minHeight: '300px',
                        }}
                    >
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                style={{ textAlign: 'center' }}
                            >
                                You don't have any saved Journeys
                            </Typography>
                            {/* <Typography variant="h3" gutterBottom>
                                <strong>Well, let's fix that!</strong>
                            </Typography> */}
                            {/* <Link to="/journeys">
                                <Button variant="outlined" color="secondary">
                                    View Journeys
                                </Button>
                            </Link> */}
                            <Link to="/browse" className={classes.browseLink}>
                                <Button
                                    variant="contained"
                                    startIcon={<FormatListBulletedIcon />}
                                >
                                    Browse
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                )
            ) : (
                <Box className={classes.load}>
                    <CircularProgress color="secondary" />
                </Box>
            )}
        </Box>
    );
};
