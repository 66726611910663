// import styled from 'styled-components';
import { styled } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { theme } from '../../theme';
import { Box, LinearProgress } from '../../ui';
import {
    Main,
    H1,
    H2,
    H3,
    H4,
    H5,
    H6,
    Span,
    Section,
    Paragraph,
} from './primitive';

export const PageWrapper = styled(Main)({
    padding: '2%',

    [theme.breakpoints.up('md')]: {
        padding: '1%',
    },
});

export const HeaderTitle = styled(H1)({
    fontWeight: '600',
    padding: '0',
    margin: '0',
    fontSize: '2.25rem',
    lineHeight: '2.5rem',

    [theme.breakpoints.up('md')]: {
        fontSize: '3rem',
        lineHeight: '1',
    },
});

export const SectionText = styled(Paragraph)({
    padding: '0',
    margin: '1.5rem 0',
    fontWeight: '400',
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
    [theme.breakpoints.up('md')]: {
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
    },
});

export const CourseStatsContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.5rem',
    flexWrap: 'wrap',
});

export const StyledSpan = styled(Span)({
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    textTransform: 'capitalize',
    [theme.breakpoints.up('md')]: {
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
    },
});
export const HideLg = styled(Box)({
    display: 'block',
    margin: '0.5rem 0',
    [theme.breakpoints.up('md')]: { display: 'none' },
});

export const StyledLink = styled(Span)({
    cursor: 'pointer',
    '&:hover': {
        textDecoration: 'underline',
    },
});
export const GridSectionLayout = styled(Section)((props) => ({
    marginTop: '1rem',
    padding: '0.5rem',
    display: 'grid',
    gap: '1rem',
    [theme.breakpoints.up('md')]: { gridTemplateColumns: props.col },
}));
export const SectionHeading = styled(H1)({
    fontSize: '1.5rem',
    lineHeight: '2rem',
    color: '#0f327e',
    textTransform: 'capitalize',
});
export const UppercaseSectionHeading = styled(SectionHeading)`
    text-transform: uppercase;
`;

export const SectionSubheading = styled(H3)({
    textAlign: 'center',
    color: '#0f327e',
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
    textTransform: 'uppercase',
});
export const ListBox = styled(Box)({
    border: "'2px solid red'",
    '& > ul, & > p': {
        padding: '0',
        margin: '0',
        fontWeight: '400',
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
    },
    '& > ul li, & > p li': {
        padding: '0',
        margin: '0',
        marginLeft: '1.2rem',
    },
});

export const SectionDesc = styled(H4)({
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
});
export const SectionContainer = styled(Section)({
    padding: '0.5rem',
});
export const ActionContainer = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
});

export const FlexLayout = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingBottom: '0.5rem',
});

export const HeaderContentWrapper = styled(Box)({
    [theme.breakpoints.up('md')]: {
        flexBasis: '52%',
        display: 'flex',
        alignItems: 'center',
    },
});

export const HeaderContent = styled(Box)({
    padding: '0.5rem',
    [theme.breakpoints.up('md')]: { padding: '1.25rem 0 1.25rem 1.8rem' },
});

export const CourseHeaderImgBox = styled(Box)((props) => ({
    backgroundImage: `url(${props.backgroundImageUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    backgroundPosition: 'right',
    clipPath: 'polygon(20% 0, 100% 0, 100% 100%, 0% 100%)',
    flexBasis: '48%',
    position: 'relative',
    display: 'none',
    minHeight: '26rem',
    [theme.breakpoints.up('md')]: { display: 'block' },
}));

export const EnrollBtnContainer = styled(Box)({
    position: 'absolute',
    right: '0',
    bottom: '10%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    paddingInline: '1rem',
    alignItems: 'start',
});

export const HideLgBtnGroup = styled(HideLg)({
    [theme.breakpoints.down('md')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1rem',
        flexWrap: 'wrap',
    },
});
export const SmallSectionText = styled(SectionText)({
    fontSize: '0.875rem',
    [theme.breakpoints.up('md')]: { fontSize: '1rem' },
});
export const DiscussionContainer = styled(Box)({
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    paddingInline: '0.25rem',
    '& > *:first-child': {
        margin: '0',
        padding: '0',
        fontSize: '18px',
        fontWeight: '400',
    },
    '& > *': {
        flexWrap: 'nowrap !important',
    },
    [theme.breakpoints.up('md')]: { gap: '0.25rem' },
});

// export const BorderLinearProgress = withStyles((theme) => ({
//     root: {
//         height: 10,
//         borderRadius: 5,
//     },
//     colorPrimary: {
//         color: theme.palette.secondary.main,
//         backgroundColor:
//             theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
//     },
//     bar: {
//         borderRadius: 5,
//         backgroundColor: '#1a90ff',
//     },
// }))(LinearProgress);
