import { IconButton } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import { AzureBlobStorage } from '../util/BlobStorage';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../app/recoil';
import { CircularProgressWithLabel, PageLoader } from '../ui';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        attachment: {
            userSelect: 'none',
            border: `1px solid ${theme.palette.grey['300']}`,
            width: '8rem',
            height: '8rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            borderRadius: '0.25rem',
            [theme.breakpoints.up('md')]: {
                // width: '10rem',
                // height: '10rem',
                '& > .attachment__delete': {
                    transition: 'opacity 150ms linear',
                    opacity: 0,
                },
                '&:hover > .attachment__delete': {
                    opacity: 1,
                },
            },
        },
        attachment__header: {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingInline: '0.5rem',
        },
        attachment__ext: {
            textAlign: 'center',
            textTransform: 'uppercase',
            fontSize: '0.75rem',
            lineHeight: '1rem',
            fontWeight: 600,
            backgroundColor: '#dbeafe',
            padding: '0.5rem',
            width: 'fit-content',
            aspectRatio: 1,
            borderRadius: '0.25rem',
            alignSelf: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // [theme.breakpoints.up('md')]: {
            //     padding: '1rem',
            //     fontSize: '1rem',
            //     lineHeight: '1.5rem',
            // },
        },
        attachment__info: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderTop: `1px solid ${theme.palette.grey['300']}`,
            paddingInline: '0.5rem',
            paddingBlock: '0.25rem',
            gap: '1rem',
        },
        attachment__name: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    };
});

function Attachment({
    file,
    containerName,
    // setFileData,
    dirName,
    handleAttachmentClick = () => console.log('fn'),
    showPageLoader = false,
    deletable = true,
}) {
    const { classes } = useStyles();
    const [isDonwloading, setIsDownloading] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState(0);
    const setAlert = useSetRecoilState(alertState);
    const [isDeleting, setIsDeleting] = useState(false);

    const onDownload = async (e) => {
        try {
            e.stopPropagation();
            const azureBlobStorage = new AzureBlobStorage();
            setIsDownloading(true);
            const url = await azureBlobStorage.downloadFile(
                file?.actual,
                containerName,
                setDownloadProgress
            );
            if (!url) throw new Error('Something went worng');
            const a = document.createElement('a');
            const id = Math.random();
            a.setAttribute('id', id);
            document.body.appendChild(a);
            a.style = 'display: none';
            a.href = url;
            a.download = file?.formatted;
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            setAlert({
                show: true,
                severity: severity.SUCCESS,
                message: 'Download successful',
            });
        } catch (e) {
            setAlert({
                show: true,
                severity: severity.ERROR,
                message: e?.message,
            });
        } finally {
            setDownloadProgress(0);
            setIsDownloading(false);
        }
    };

    const onDelete = async (e) => {
        try {
            e.stopPropagation();
            setIsDeleting(true);
            const azureBlobStorage = new AzureBlobStorage();
            const { fileNames, size } = await azureBlobStorage.deleteFile(
                file?.actual,
                containerName,
                dirName
            );
            // setFileData({ fileNames, size });
        } catch (error) {
            console.log(error);
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <div
            className={classes.attachment}
            style={{
                cursor: ['IMAGE', 'VIDEO', 'PDF'].includes(file.type)
                    ? 'pointer'
                    : 'auto',
            }}
            onClick={() => handleAttachmentClick(file)}
        >
            <PageLoader open={isDeleting || isDonwloading || showPageLoader} />
            <div className={`${classes.attachment__header} attachment__delete`}>
                {deletable && (
                    <IconButton
                        size="small"
                        disabled={isDeleting || isDonwloading}
                        onClick={onDelete}
                    >
                        <DeleteOutlinedIcon fontSize="small" color="error" />
                    </IconButton>
                )}
            </div>
            <div className={classes.attachment__ext}>{file.extension}</div>
            <div className={classes.attachment__info}>
                <span className={classes.attachment__name}>
                    {file.formatted}
                </span>
                {isDonwloading ? (
                    <CircularProgressWithLabel
                        value={downloadProgress}
                        color="secondary"
                        size={28}
                        fontSize={'0.8rem'}
                    />
                ) : (
                    <IconButton
                        size="small"
                        onClick={onDownload}
                        disabled={isDeleting || isDonwloading}
                    >
                        <FileDownloadOutlinedIcon
                            fontSize="small"
                            color="primary"
                        />
                    </IconButton>
                )}
            </div>
        </div>
    );
}

export default Attachment;
