import { useMemo } from 'react';
import ReactHTMLParser from 'react-html-parser';
import {
    ListBox,
    SectionContainer,
    SectionHeading,
} from '../custom-components/styled-components';

const Attend = (props) => {
    const { wpCourseData } = props;

    const targetAudience = useMemo(() => {
        return ReactHTMLParser(wpCourseData?.targetAudience);
    }, [wpCourseData?.targetAudience]);

    return (
        <SectionContainer>
            <SectionHeading>Who Should Attend</SectionHeading>
            <ListBox>{targetAudience}</ListBox>
        </SectionContainer>
    );
};

export default Attend;
