import { IconButton, Typography } from '@mui/material';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import UserIcon from '../../img/user.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailIcon from '@mui/icons-material/Mail';

const useStyles = makeStyles()((theme) => {
    return {
        card: {
            backgroundColor: '#fff',
            boxShadow:
                '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
            borderRadius: '0.25rem',
            padding: '0.875rem',
            textAlign: 'center',
            cursor: 'pointer',
            border: '2px solid #fff',
            transition: 'border 150ms ease-in-out',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '1rem',
            [theme.breakpoints.up('md')]: {
                marginBottom: '0',
            },
            '&:hover': {
                border: '2px solid #d1d5db',
            },
        },
        card__imgContainer: {
            border: '2px solid #f8f8f8',
            maxWidth: '6rem',
            width: '100%',
            height: 'auto',
            aspectRatio: 1,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginInline: 'auto',
            overflow: 'hidden',
        },
        card__img: {
            width: '100%',
            height: 'auto',
            aspectRatio: 1,
            objectFit: 'cover',
        },
        card__name: {
            textTransform: 'capitalize',
            fontWeight: '500',
            fontSize: '1.125rem',
            lineHeight: '1.75rem',
            marginTop: '1rem',
        },
        card__role: {
            fontSize: '1rem',
            lineHeight: '1.5rem',
        },
        card__socials: {
            marginTop: 'auto',
        },
    };
});

function TeamCard({
    imgURL,
    name,
    role,
    linkedin,
    mail,
    onCardClick = () => console.log('Card clicked'),
    _id,
}) {
    const { classes } = useStyles();

    return (
        <div className={classes.card} onClick={() => onCardClick(_id)}>
            <div className={classes.card__imgContainer}>
                {imgURL.length > 0 ? (
                    <img
                        className={classes.card__img}
                        src={imgURL}
                        alt={name}
                        height={100}
                        width={100}
                        loading="lazy"
                    />
                ) : (
                    <img
                        className={classes.card__img}
                        src={UserIcon}
                        alt={name}
                        height={100}
                        width={100}
                        loading="lazy"
                    />
                )}
            </div>
            <Typography className={classes.card__name}>{name}</Typography>
            <Typography className={classes.card__role}>{role}</Typography>
            <div className={classes.card__socials}>
                {linkedin?.length > 0 && (
                    <a
                        href={linkedin}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <IconButton>
                            <LinkedInIcon color="primary" />
                        </IconButton>
                    </a>
                )}
                <a href={`mailto:${mail}`} onClick={(e) => e.stopPropagation()}>
                    <IconButton>
                        <MailIcon color="primary" />
                    </IconButton>
                </a>
            </div>
        </div>
    );
}

export { TeamCard };
