import {
    Box,
    CircularProgress,
    Grid,
    IconButton,
    Typography,
    Divider,
    Button,
} from '../ui';
import { useRecoilState } from 'recoil';
import { dismissedRecommededOnAirSlugDateState } from './Recoil/State';
import Fade from '@mui/material/Fade';
import { useNavigate, Link } from 'react-router-dom';
import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useStyles } from './stylesRecommend';
import { ChevronRightIcon, ChevronLeftIcon, CloseIcon } from '../icons';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import { DismissRecommendationDialog } from './DismissRecommendationDialog';
import SWRecomendationCard from '../custom-components/SWRecomendationCard';

export const TopPicksRecommended = ({
    user,
    minu,
    hur,
    domains,
    color = '#fafafa',
    heading = 'Here are some top picks for you',
    data,
    showViewAll = true,
    launchText = 'Launch',
    // uncomment it if in any case this prop is not filled we can have a default value
    removeText = 'Un-Enroll',
    // negative function
    handleOnClick = (item) => {
        console.log('No On Save Function');
        console.log(item);
    },
    // positive function
    handleLaunch = (item) => {
        console.log('No Default Launch Function');
        console.log(item);
    },
    showActions = false,
    dismissCourse,
    setRecommendedCoursesIDToDismiss,
}) => {
    const isLarge = useMediaQuery('(min-width:1200px)');
    const isSmall = useMediaQuery('(min-width:900px)');
    const isMedium = useMediaQuery('(min-width:600px)');
    const { classes } = useStyles();
    const currentIndex = useRef(0);
    const currentIndexOnAir = useRef(0);

    const [displayCourse, setDisplayCourse] = useState(null);
    const [displayCourse1, setDisplayCourse1] = useState(null);
    const [size, setSize] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [loadedDoing, setLoadedDoing] = useState(false);
    let navigate = useNavigate();
    const [currentOnAirIndex, setCurrentOnAirIndex] = useState([]);
    const [courses, setCourses] = useState([]);

    const [recommendedOnAirToDismiss, setRecommendedOnAirToDismiss] = useState(
        null
    );

    const [
        dismissedRecommededOnAirSlugDates,
        setDismissedRecommededOnAirSlugDates,
    ] = useRecoilState(dismissedRecommededOnAirSlugDateState);

    const [isDismissing, setIsDismissing] = useState(false);

    useEffect(() => {
        if (user && !dismissedRecommededOnAirSlugDates) {
            console.log(dismissedRecommededOnAirSlugDates);
            setDismissedRecommededOnAirSlugDates(
                user.dismissed_recommended_on_air_slug_dates || []
            );
        }
    }, [user]);

    const registrants = useMemo(() => {
        return courses.map((course) => course.registrants);
    }, [courses]);

    const allMaxRegistrants = useMemo(() => {
        return courses.map((course) =>
            parseInt(course.noOfMaxRegistrations)
                ? parseInt(course.noOfMaxRegistrations)
                : 50
        );
    }, [courses]);

    const isregistrationOpenArray = useMemo(() => {
        return courses.map((course) =>
            course.hasOwnProperty('isNotRegistrationForceClosed') &&
            course.isNotRegistrationForceClosed !== null
                ? course?.isNotRegistrationForceClosed
                : true
        );
    }, [courses]);

    const getCourseRegistraiton = useCallback(
        (index) => {
            if (currentOnAirIndex.length > index) {
                const courseIndex = currentOnAirIndex[index] || 0;
                if (isregistrationOpenArray[courseIndex]) {
                    const currentCourseRegistrants =
                        registrants[courseIndex].length;
                    const currentCourseMaxRegistrations =
                        allMaxRegistrants[courseIndex];
                    return (
                        currentCourseMaxRegistrations - currentCourseRegistrants
                    );
                } else {
                    return -1;
                }
            }
        },
        [
            currentOnAirIndex,
            registrants,
            allMaxRegistrants,
            isregistrationOpenArray,
        ]
    );

    function getSize() {
        if (isLarge || isSmall) {
            return 3;
        } else if (isMedium) {
            return 2;
        } else {
            return 1;
        }
    }

    const isRegistrationOpen = useMemo(() => {
        if (
            courses.hasOwnProperty('isNotRegistrationForceClosed') &&
            courses.isNotRegistrationForceClosed !== null
        ) {
            return courses?.isNotRegistrationForceClosed;
        } else {
            return true;
        }
    }, [courses]);

    useEffect(() => {
        const getNewSize = getSize();
        setSize(getNewSize);
    }, [isLarge, isMedium]);

    const setNext = () => {
        currentIndex.current += size;
        if (currentIndex.current > data.length - 1) {
            currentIndex.current = 0;
        }

        const newAr = data.slice(
            currentIndex.current,
            currentIndex.current + size
        );
        setDisplayCourse(newAr);
        setLoaded(true);
    };

    const setNextOnDoing = () => {
        currentIndexOnAir.current += size;
        if (currentIndexOnAir.current > courses.length - 1) {
            currentIndexOnAir.current = 0;
        }
        const newAr = courses.slice(
            currentIndexOnAir.current,
            currentIndexOnAir.current + size
        );
        // savign the index of all the current displayed courses
        setCurrentOnAirIndex(
            new Array(size)
                .fill(currentIndexOnAir.current)
                .map((val, i) => val + i)
        );
        setDisplayCourse1(newAr);
        setLoadedDoing(true);
    };

    const setPrev = () => {
        currentIndex.current -= size;
        if (currentIndex.current < 0) {
            currentIndex.current = data.length - size;
        }
        const newAr = data.slice(
            currentIndex.current,
            currentIndex.current + size
        );
        setDisplayCourse(newAr);
        setLoaded(true);
    };

    const setPrevDoing = () => {
        currentIndexOnAir.current -= size;
        if (currentIndexOnAir.current < 0) {
            currentIndexOnAir.current = courses.length - size;
        }
        const newAr = courses.slice(
            currentIndexOnAir.current,
            currentIndexOnAir.current + size
        );
        setCurrentOnAirIndex(
            new Array(size)
                .fill(currentIndexOnAir.current)
                .map((val, i) => val + i)
        );
        setDisplayCourse1(newAr);
        setLoadedDoing(true);
    };

    useEffect(() => {
        if (size) {
            currentIndex.current = -size;
            setNext();
        }
    }, [size]);

    useEffect(() => {
        if (size) {
            currentIndexOnAir.current = -size;
            setNextOnDoing();
        }
    }, [size]);

    useEffect(() => {
        if (data && size) {
            currentIndexOnAir.current = -size;
            setNextOnDoing();
        }
    }, [courses]);

    useEffect(() => {
        if (data && size) {
            currentIndex.current = -size;
            setNext();
        }
    }, [data]);

    const handleClick = (course) => {
        navigate(`/courses/${course.slug}`);
    };

    const handleOnAirClick = (url) => {
        navigate(`/on-air/${url}`);
    };

    useEffect(() => {
        if (user) {
            console.log(user.dismissed_recommended_on_air_slug_dates);
            getonAirCourse();
        }
    }, [user]);

    const getonAirCourse = async () => {
        // let res = await axios.get(
        //     `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-json/headless/on-air?channel=on_air`
        // );
        // // const encodedChannel = encodeURI('On Air');
        // // const { data } = await axios.get(
        // //     `/api/content/on-air/registrations?domain=${encodedChannel}`
        // // );
        // const registrationsMap = new Map();
        // // data?.registrations.forEach((registration) => {
        // //     const key = `${registration.slug}-${registration.endDate}`;
        // //     registrationsMap.set(key, registration);
        // // });
        // // map the results to the registrations
        // const courseData = res.data.map((course) => {
        //     const key = `${course.name}-${course.endDate.date}`;
        //     let registrants = [];
        //     if (registrationsMap.get(key)) {
        //         registrants = registrationsMap.get(key).registraints;
        //     }
        //     return { ...course, registrants };
        // });
        // setCourses(
        //     courseData.filter((course) => {
        //         const key = `${course.name}-${course.endDate.date}`;
        //         return !(
        //             user.dismissed_recommended_on_air_slug_dates || []
        //         ).includes(key);
        //     })
        // );
    };

    const getDurationFormat = useCallback((item) => {
        const duration = `${
            parseInt(item.hours) !== 0 ? parseInt(item.hours) + ' hour' : ''
        }${
            parseInt(item.hours) !== 0 && parseInt(item.hours) !== 1 ? 's' : ''
        } ${
            parseInt(item.minutes) !== 0
                ? parseInt(item.minutes) + ' minutes'
                : ''
        }`;
        return duration;
    }, []);

    const getDuration = (id) => {
        let hrs = hur?.get(id);
        let min = minu?.get(id);

        let domain = domains?.get(id);
        // console.log(hrs);
        // console.log(min);
        // console.log(domain);
        if (hrs !== undefined) {
            let duration = '';
            if (hrs != 0) {
                duration = hrs > 1 ? hrs + ' hours ' : hrs + ' hour ';
            }
            if (min != 0) {
                duration += min + ' minutes';
            }

            const ret = ' ' + domain + ' | ' + duration;
            return ret;
        }
    };

    const dismissOnAir = async () => {
        try {
            if (/* !isDismissing && */ recommendedOnAirToDismiss) {
                //setIsDismissing(true);

                console.log(recommendedOnAirToDismiss);
                await axios.put(`/api/onboarding/dismiss-onair/${user._id}`, {
                    slug_date: recommendedOnAirToDismiss,
                });
                setDismissedRecommededOnAirSlugDates((prev) =>
                    prev.filter((slug) => slug != recommendedOnAirToDismiss)
                );

                setCourses((prev) =>
                    prev.filter((course) => {
                        const key = `${course.name}-${course.endDate.date}`;
                        return key !== recommendedOnAirToDismiss;
                    })
                );
                setRecommendedOnAirToDismiss(null);
                //setIsDismissing(false);
            }
        } catch (error) {
            console.log({ error });
        }
    };

    return (
        <Box my={3}>
            <Typography
                style={{ color: color }}
                className={classes.sectionHeading}
            >
                {heading} &nbsp;
            </Typography>
            {showViewAll && (
                <Link to="/courses" className={classes.link}>
                    view all&nbsp;
                    <ChevronRightIcon size="small" />{' '}
                </Link>
            )}

            <Grid container className={classes.cardsConatiner}>
                <Grid
                    xs={12}
                    style={{ color: color }}
                    className={classes.learningTxt}
                >
                    Learning ({data?.length} program
                    {data?.length > 1 ? 's' : ''})
                    <Divider />
                </Grid>

                {displayCourse ? (
                    <>
                        {displayCourse.map((item, index) => {
                            let delay = 0;
                            if (index > 0) {
                                delay = Math.trunc((500 / size) * index);
                            }
                            return (
                                <Fade
                                    in={loaded}
                                    style={{
                                        transitionDelay: loaded
                                            ? `${delay}ms`
                                            : '0ms',
                                    }}
                                    key={'recommended-' + index}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={4}
                                        key={'recommended-' + index}
                                    >
                                        <SWRecomendationCard
                                            imgSrc={
                                                item.card_image_url ||
                                                item.course_card_image_url
                                            }
                                            courseName={item.name}
                                            instructorName={
                                                item.instructor_names
                                            }
                                            duration={getDuration(
                                                String(item.productable_id)
                                            )}
                                            content={item.description}
                                            id={item.id}
                                            setRecommendedCoursesIDToDismiss={
                                                setRecommendedCoursesIDToDismiss
                                            }
                                            showEnrollBtn={item?.showUnEnroll}
                                            handleEnrollClick={handleOnClick}
                                            item={item}
                                            handleViewBtnClick={handleLaunch}
                                            showCardActions={false}
                                            showCloseBtn={true}
                                        />
                                    </Grid>
                                </Fade>
                            );
                            // return (
                            //     <>
                            //         <Fade
                            //             in={loaded}
                            //             style={{
                            //                 transitionDelay: loaded
                            //                     ? `${delay}ms`
                            //                     : '0ms',
                            //             }}
                            //         >
                            //             <Grid
                            //                 key={index}
                            //                 item
                            //                 xs={12}
                            //                 sm={6}
                            //                 md={3}
                            //                 className={classes.mainBoxWrap}
                            //             >
                            //                 <Box
                            //                     className={classes.mainBox}
                            //                     onClick={() =>
                            //                         handleClick(item)
                            //                     }
                            //                 >
                            //                     {' '}
                            //                     <IconButton
                            //                         style={{
                            //                             position: 'absolute',
                            //                             top: 0,
                            //                             right: 0,
                            //                         }}
                            //                         onClick={(e) => {
                            //                             e.stopPropagation();

                            //                             setRecommendedCoursesIDToDismiss(
                            //                                 item.id
                            //                             );
                            //                             //dismissCourse(item.id);
                            //                         }}
                            //                     >
                            //                         <CloseIcon />
                            //                     </IconButton>
                            //                     <Box
                            //                         component="div"
                            //                         style={{
                            //                             backgroundImage: `url(${
                            //                                 item.card_image_url ||
                            //                                 item.course_card_image_url
                            //                             })`,
                            //                         }}
                            //                         className={classes.cardImg}
                            //                     >
                            //                         <img
                            //                             src={
                            //                                 item.card_image_url ||
                            //                                 item.course_card_image_url
                            //                             }
                            //                             width="100%"
                            //                             alt="top"
                            //                         ></img>
                            //                     </Box>
                            //                     <Typography
                            //                         className={
                            //                             classes.cardHeading
                            //                         }
                            //                     >
                            //                         {item.name}
                            //                     </Typography>
                            //                     <Box
                            //                         component="div"
                            //                         className={
                            //                             classes.contentBox
                            //                         }
                            //                     >
                            //                         <Box>
                            //                             {item.instructor_names && (
                            //                                 <Typography
                            //                                     className={
                            //                                         classes.subtitle
                            //                                     }
                            //                                     variant="body2"
                            //                                 >
                            //                                     {' '}
                            //                                     {
                            //                                         item.instructor_names
                            //                                     }{' '}
                            //                                     |
                            //                                     {getDuration(
                            //                                         String(
                            //                                             item.productable_id
                            //                                         )
                            //                                     )}
                            //                                     {/*  |{' '}
                            //                             {courseDuration
                            //                                 ? Math.floor(courseDuration)
                            //                                 : null}{' '}
                            //                             mins */}
                            //                                 </Typography>
                            //                             )}
                            //                         </Box>

                            //                         <p className={classes.desc}>
                            //                             {item.description
                            //                                 .length > 150 ? (
                            //                                 <>
                            //                                     {item.description.substr(
                            //                                         0,
                            //                                         150
                            //                                     )}
                            //                                     ...
                            //                                 </>
                            //                             ) : (
                            //                                 <>
                            //                                     {
                            //                                         item.description
                            //                                     }
                            //                                 </>
                            //                             )}
                            //                         </p>
                            //                     </Box>
                            //                     {showActions && (
                            //                         <Grid
                            //                             container
                            //                             spacing={2}
                            //                             className={
                            //                                 classes.cardView
                            //                             }
                            //                             style={{
                            //                                 border:
                            //                                     '2px solid red',
                            //                             }}
                            //                         >
                            //                             <Grid
                            //                                 item
                            //                                 xs={12}
                            //                                 sm={12}
                            //                                 md={6}
                            //                             >
                            //                                 <Button
                            //                                     variant="contained"
                            //                                     color="secondary"
                            //                                     onClick={(
                            //                                         e
                            //                                     ) => {
                            //                                         e.stopPropagation();
                            //                                         handleLaunch(
                            //                                             item
                            //                                         );
                            //                                     }}
                            //                                     className={
                            //                                         classes.cardBtn
                            //                                     }
                            //                                 >
                            //                                     {/* this piece of code will help us to change
                            //                             the text on basis of a condition on a parent component  */}
                            //                                     {/* won't mess up the code eight now but have to make
                            //                             it so we can use it later */}
                            //                                     {launchText instanceof
                            //                                     Function
                            //                                         ? 'function'
                            //                                         : launchText}
                            //                                 </Button>
                            //                             </Grid>

                            //                             {item.hasOwnProperty(
                            //                                 'showUnEnroll'
                            //                             ) &&
                            //                             item.showUnEnroll ===
                            //                                 false ? (
                            //                                 <> </>
                            //                             ) : (
                            //                                 <Grid
                            //                                     item
                            //                                     xs={12}
                            //                                     sm={12}
                            //                                     md={6}
                            //                                 >
                            //                                     <Button
                            //                                         className={
                            //                                             classes.cardBtn
                            //                                         }
                            //                                         color="secondary"
                            //                                         variant="outlined"
                            //                                         onClick={(
                            //                                             e
                            //                                         ) => {
                            //                                             e.stopPropagation();
                            //                                             handleOnClick(
                            //                                                 item
                            //                                             );
                            //                                         }}
                            //                                     >
                            //                                         {removeText}
                            //                                     </Button>
                            //                                 </Grid>
                            //                             )}
                            //                         </Grid>
                            //                     )}
                            //                 </Box>
                            //             </Grid>
                            //         </Fade>
                            //     </>
                            // );
                        })}
                    </>
                ) : (
                    <Box className={classes.load}>
                        <CircularProgress color="secondary" />
                    </Box>
                )}

                <IconButton
                    className={`${classes.controlBtn} ${classes.leftArrow}`}
                    onClick={() => {
                        if (size >= data.length) {
                            return;
                        }
                        setLoaded(false);
                        setTimeout(() => setPrev(), 500);
                    }}
                    size="large"
                >
                    <ChevronLeftIcon />
                </IconButton>
                <IconButton
                    className={`${classes.controlBtn} ${classes.rightArrow}`}
                    onClick={() => {
                        if (size >= data.length) {
                            return;
                        }
                        setLoaded(false);
                        setTimeout(() => setNext(), 500);
                    }}
                    size="large"
                >
                    <ChevronRightIcon />
                </IconButton>
            </Grid>
            <Grid container className={classes.cardsConatiner}>
                <Grid
                    xs={12}
                    style={{ color: color }}
                    className={classes.learningTxt}
                >
                    Doing ({courses?.length} program
                    {courses?.length > 1 ? 's' : ''})
                    <Divider />
                </Grid>

                {displayCourse1 ? (
                    <>
                        {displayCourse1.map((item, index) => {
                            let delay = 0;
                            if (index > 0) {
                                delay = Math.trunc((500 / size) * index);
                            }
                            return (
                                <Fade
                                    in={loadedDoing}
                                    style={{
                                        transitionDelay: loadedDoing
                                            ? `${delay}ms`
                                            : '0ms',
                                    }}
                                >
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <SWRecomendationCard
                                            imgSrc={item.sliderImage}
                                            courseName={item.title}
                                            instructorName={item.instructor}
                                            duration={`${
                                                item.domain
                                            } | ${getDurationFormat(item)}`}
                                            content={
                                                item?.description?.length > 150
                                                    ? ReactHtmlParser(
                                                          item?.description
                                                              .slice(0, 150)
                                                              .concat('...')
                                                      )
                                                    : ReactHtmlParser(
                                                          item?.description
                                                      )
                                            }
                                            id={`${item.name}-${item.endDate.date}`}
                                            setRecommendedCoursesIDToDismiss={
                                                setRecommendedOnAirToDismiss
                                            }
                                            showEnrollBtn={item?.showUnEnroll}
                                            handleEnrollClick={handleOnClick}
                                            item={item?.name}
                                            handleViewBtnClick={
                                                handleOnAirClick
                                            }
                                            showCardActions={true}
                                            day={item.weekDay}
                                            date={item.date}
                                            closeStatus={
                                                getCourseRegistraiton(index) > 1
                                                    ? `${getCourseRegistraiton(
                                                          index
                                                      )} SLOT${
                                                          getCourseRegistraiton(
                                                              index
                                                          ) > 1
                                                              ? 'S'
                                                              : ''
                                                      } LEFT`
                                                    : 'CLOSED'
                                            }
                                            showCloseBtn={true}
                                        />
                                    </Grid>
                                    {/* <Grid
                                        key={index}
                                        item
                                        xs={12}
                                        sm={6}
                                        md={3}
                                        className={classes.mainBoxWrap}
                                    >
                                        <Box
                                            className={classes.mainBox}
                                            onClick={() =>
                                                handleOnAirClick(item.name)
                                            }
                                        >
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                className={classes.cardheader}
                                            >
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                >
                                                    <Typography
                                                        className={
                                                            classes.subtitle
                                                        }
                                                        variant="body2"
                                                        style={{
                                                            padding:
                                                                '5px 0 0 5px',
                                                            textTransform:
                                                                'uppercase',
                                                        }}
                                                    >
                                                        {' '}
                                                        {item.weekDay}
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.subtitle
                                                        }
                                                        variant="body2"
                                                        style={{
                                                            padding:
                                                                '5px 0 0 5px',
                                                            textTransform:
                                                                'uppercase',
                                                        }}
                                                    >
                                                        {item.date}
                                                    </Typography>
                                                </Box>

                                                <Box
                                                    display="flex"
                                                    alignItems="flex-end"
                                                    style={{
                                                        color: '#a5a5a5',
                                                    }}
                                                >
                                                    {getCourseRegistraiton(
                                                        index
                                                    ) > 1 ? (
                                                        <Typography
                                                            align="right"
                                                            variant="body2"
                                                            style={{
                                                                fontWeight: 500,
                                                            }}
                                                        >
                                                            {getCourseRegistraiton(
                                                                index
                                                            )}{' '}
                                                            {`SLOT${
                                                                getCourseRegistraiton(
                                                                    index
                                                                ) > 1
                                                                    ? 'S'
                                                                    : ''
                                                            }`}{' '}
                                                            LEFT
                                                        </Typography>
                                                    ) : (
                                                        <Typography
                                                            align="right"
                                                            variant="body2"
                                                            style={{
                                                                fontWeight: 500,
                                                            }}
                                                        >
                                                            CLOSED
                                                        </Typography>
                                                    )}
                                                </Box>
                                            </Box>

                                            <Box
                                                component="div"
                                                className={classes.cardImg}
                                                style={{
                                                    marginTop: 19,
                                                    position: 'relative',
                                                }}
                                            >
                                                <img
                                                    src={item.sliderImage}
                                                    width="100%"
                                                    alt="top"
                                                ></img>
                                                <IconButton
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 0,
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();

                                                        setRecommendedOnAirToDismiss(
                                                            `${item.name}-${item.endDate.date}`
                                                        );
                                                        //dismissCourse(item.id);
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </Box>
                                            <Typography
                                                className={classes.cardHeading}
                                            >
                                                {item.title}
                                            </Typography>

                                            <Box
                                                component="div"
                                                className={classes.contentBox}
                                            >
                                                <Box>
                                                    {item.instructor && (
                                                        <Typography
                                                            className={
                                                                classes.subtitle
                                                            }
                                                            variant="body2"
                                                        >
                                                            {' '}
                                                            {
                                                                item.instructor
                                                            } | {item.domain} |{' '}
                                                            {getDurationFormat(
                                                                item
                                                            )}
                                                        </Typography>
                                                    )}
                                                </Box>

                                                <p className={classes.desc}>
                                                    {item.description.length >
                                                    150 ? (
                                                        <>
                                                            {ReactHtmlParser(
                                                                item.description.substr(
                                                                    0,
                                                                    150
                                                                )
                                                            )}
                                                            ...
                                                        </>
                                                    ) : (
                                                        <>
                                                            {ReactHtmlParser(
                                                                item.description
                                                            )}
                                                        </>
                                                    )}
                                                </p>
                                            </Box>
                                        </Box>
                                    </Grid> */}
                                </Fade>
                            );
                        })}
                    </>
                ) : (
                    <Box className={classes.load}>
                        <CircularProgress color="secondary" />
                    </Box>
                )}

                <IconButton
                    className={`${classes.controlBtn} ${classes.leftArrow}`}
                    onClick={() => {
                        if (size >= courses.length) {
                            return;
                        }
                        setLoadedDoing(false);
                        setTimeout(() => setPrevDoing(), 500);
                    }}
                    size="large"
                >
                    <ChevronLeftIcon />
                </IconButton>
                <IconButton
                    className={`${classes.controlBtn} ${classes.rightArrow}`}
                    onClick={() => {
                        if (size >= courses.length) {
                            return;
                        }
                        setLoadedDoing(false);
                        setTimeout(() => setNextOnDoing(), 500);
                    }}
                    size="large"
                >
                    <ChevronRightIcon />
                </IconButton>
            </Grid>
            <DismissRecommendationDialog
                onClose={() => {
                    setRecommendedOnAirToDismiss(null);
                }}
                handleRemove={dismissOnAir}
                open={recommendedOnAirToDismiss}
            />
        </Box>
    );
};
