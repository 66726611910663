import { Box, Grid, Icon, Typography } from '../ui';
import { makeStyles } from 'tss-react/mui';
import { CheckIcon } from '../icons';
import { signupPageLogos } from './logos';
import { InstructorDisplayContent } from './InstructorDisplayContent';

const useStyles = makeStyles()((theme) => ({
    info: {
        width: '82%',
        margin: '0 auto',
        marginTop: 50,
        display: 'flex',
        flexFlow: 'column',
    },
    heading: {
        fontSize: 'min(1.75em, 3.5vw)',
    },
    subHeading: {
        fontSize: 'min(1.4em, 2.7vw)',
    },
    informationBullets: {
        fontSize: 'min(1.3em, 2.5vw)',
    },
    conditions: {
        fontSize: '0.9em',
        color: '#a5a5a5',
    },
    logo: {
        boxSizing: 'border-box',
        height: '70px',
        marginTop: '10px',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
}));

export const SignUpDescription = ({
    handleJoinFreeClick,
    showSubHeading = true,
    heading = `Education for students and seasoned professionals keeping you sharp and career ready`,
    showInstructorContent,
}) => {
    const { classes } = useStyles();

    return (
        <>
            <Box my={2} className={classes.info}>
                <Typography
                    variant="h4"
                    className={classes.heading}
                    gutterBottom
                    color="secondary"
                >
                    {heading}
                </Typography>
                <Box my={2}>
                    {showSubHeading && (
                        <Typography
                            className={classes.subHeading}
                            variant="h6"
                            gutterBottom
                        >
                            Your free 5-day trial includes virtually unlimited
                            access
                            <Typography
                                variant="caption"
                                component="span"
                                gutterBottom
                                style={{
                                    verticalAlign: 'super',
                                }}
                            >
                                &#8224;
                            </Typography>{' '}
                            to the Starweaver platform with:{' '}
                        </Typography>
                    )}
                </Box>
                {showInstructorContent && <InstructorDisplayContent />}
                <Box my={1} pl={2}>
                    {bullets.map((content) => (
                        <Box display="flex" alignItems="flex-start" my={1}>
                            <Box mr={2}>
                                <Icon color="secondary">
                                    <CheckIcon />
                                </Icon>
                            </Box>
                            <Typography
                                className={classes.informationBullets}
                                variant="subtitle2"
                            >
                                {content}
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <Box my={2}>
                    <Typography
                        className={classes.subHeading}
                        variant="h6"
                        gutterBottom
                    >
                        We are on a mission is to transform technologists into
                        world-class experts and business people into tech savvy
                        leaders. It’s that simple.​
                    </Typography>
                </Box>
                {showSubHeading && (
                    <Box mt={2}>
                        <Typography variant="h5" gutterBottom>
                            Welcome aboard!
                        </Typography>
                    </Box>
                )}
                <Box mt={1}>
                    <Grid container justifyContent="center" alignItems="center">
                        {signupPageLogos.map((item, index) => (
                            <Grid
                                key={index}
                                item
                                xs={6}
                                sm={4}
                                lg={3}
                                style={{ backgroundImage: `url(${item})` }}
                                className={classes.logo}
                            />
                        ))}
                    </Grid>
                </Box>
                {showSubHeading && (
                    <Box mt={1}>
                        <Typography className={classes.conditions} gutterBottom>
                            &#8224; Certifications and test prep programs are
                            excluded during the trial.​
                        </Typography>
                    </Box>
                )}
            </Box>
        </>
    );
};

const bullets = [
    'Daily live and interactive courses by top instructors.​',
    '1000s of hours of on demand and engaging courses, with many dozens of new courses added monthly.​',
    'AI-driven learning Journeys customized for you serving as roadmaps that guide you toward excellence.​',
];
