import { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Chip,
    Grid,
    Loading,
    Typography,
    MultipleSelectCheckbox,
    TextField,
} from '../ui';
// import { CourseCard } from './CourseCards';
// import { useCategoryJourney } from './useCategoryJourney';
// import { useGetCustomJourneys } from '../admin/content-curation/useGetCustomJourneys';

import {
    AccessTimeIcon,
    CheckCircleOutlineIcon,
    ControlPointOutlinedIcon,
} from '../icons';
import SWCourseCards from '../custom-components/SWCourseCards';
import { NoPivateJourney } from '../custom-components/NoPrivateJourney';

export const PriavteJourneysTab = ({
    domain,
    // client,
    // starweaverAdmin,
    journeys,
    isLoading,
}) => {
    // const { journey: journeys, isLoading } = useCategoryJourney(collectionId);
    // const { journeys, isLoading } = useGetCustomJourneys(
    //     client._id,
    //     starweaverAdmin
    // );

    const [filteredJourneys, setFilteredJourneys] = useState([]);

    const DURATION_FILTER_TAGS = [
        {
            label: 'All',
            value: '99-99',
        },
        {
            label: 'Less than 1 hour',
            value: '0-1',
        },
        {
            label: 'Between 1-3 hour',
            value: '1-3',
        },
        {
            label: 'Greater than 3 hour',
            value: '3-99',
        },
    ];
    const [duration, setDuration] = useState(DURATION_FILTER_TAGS[0].value);

    const [skills, setSkills] = useState([]);

    const [selectedSkills, setSelectedSkills] = useState(new Map());

    //const levels = ['Core', 'Intermediate', 'Advanced'];
    const [levels, setLevels] = useState([]);

    const [selectLevels, setSelectedLevels] = useState(new Map());

    useEffect(() => {
        if (journeys && !isLoading) {
            // console.log([
            //     ...new Set(journeys.map(({ skills }) => skills).flat()),
            // ]);
            setFilteredJourneys(journeys);

            resetDuration();
            setSelectedSkills(new Map());
            setSelectedLevels(new Map());

            setSkills([
                ...new Set(journeys.map(({ skills }) => skills).flat()),
            ]);

            setLevels([
                ...new Set(journeys.map(({ level }) => level).filter((l) => l)),
            ]);
        }
    }, [journeys, isLoading]);

    useEffect(() => {
        if (journeys?.length > 0) {
            // duration filters
            const splitValue = duration.split('-');
            const lower = parseInt(splitValue[0]);
            const greater = parseInt(splitValue[1]);
            // duration filters

            // sorting
            // sorting

            // filtering
            let newfilteredJourneys = journeys;
            if (lower === 99 && greater === 99) {
            } else {
                newfilteredJourneys = newfilteredJourneys.filter(
                    (journey) =>
                        journey.hours >= lower && journey.hours <= greater
                );
            }

            //console.log(newfilteredJourneys);

            if (selectLevels.size !== 0)
                newfilteredJourneys = newfilteredJourneys.filter((journey) =>
                    selectLevels.has(journey.level)
                );

            if (selectedSkills.size !== 0)
                newfilteredJourneys = newfilteredJourneys.filter((journey) =>
                    journey.skills?.some((s) => selectedSkills.has(s))
                );

            // newfilteredJourneys.forEach((journey) => {
            //     console.log(journey.skills.some((s) => selectedSkills.has(s)));
            // });

            //console.log(newfilteredJourneys);
            setFilteredJourneys(newfilteredJourneys);
            // filtering
        }
        // eslint-disable-next-line
    }, [duration, selectedSkills, selectLevels]);

    const durationChange = (e) => {
        const value = e.target.value;
        if (value) {
            setDuration(value);
        }
    };

    const resetDuration = () => {
        setDuration(DURATION_FILTER_TAGS[0].value);
    };

    const removeSkill = (skillToRemove) => () => {
        //setSelectedSkills((prev) => prev.filter((s) => s !== skillToRemove));
        setSelectedSkills(
            (prev) =>
                new Map(
                    [...prev.keys()]
                        .filter((s) => s !== skillToRemove)
                        .map((k) => [k, true])
                )
        );
    };

    const removeLevel = (levelToRemove) => () => {
        setSelectedLevels(
            (prev) =>
                new Map(
                    [...prev.keys()]
                        .filter((s) => s !== levelToRemove)
                        .map((k) => [k, true])
                )
        );
    };
    return (
        <Box>
            <Container maxWidth="xl">
                {journeys.length > 0 && (
                    <Grid container>
                        <Grid item xs={12}>
                            {/* Filter select */}
                            <Box
                                display="flex"
                                alignItems={'center'}
                                justifyContent="center"
                                flexWrap={'wrap'}
                            >
                                <Box m={1}>
                                    <TextField
                                        id="outlined-select-currency-native"
                                        select
                                        label="Select Duration"
                                        value={duration}
                                        onChange={durationChange}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        color="secondary"
                                        variant="outlined"
                                    >
                                        {DURATION_FILTER_TAGS.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        ))}
                                    </TextField>
                                </Box>
                                <Box m={1}>
                                    <MultipleSelectCheckbox
                                        label="Skills"
                                        options={skills}
                                        selection={selectedSkills}
                                        setSelection={setSelectedSkills}
                                    />
                                </Box>
                                <Box m={1}>
                                    <MultipleSelectCheckbox
                                        label="Levels"
                                        options={levels}
                                        selection={selectLevels}
                                        setSelection={setSelectedLevels}
                                    />
                                </Box>
                            </Box>
                            {/* Filter select */}
                        </Grid>
                        {/* Active Filters */}
                        <Grid item xs={12}>
                            <Box my={1}>
                                {/* Duration */}
                                {duration !== DURATION_FILTER_TAGS[0].value && (
                                    <Box display="inline" marginRight={2}>
                                        <Chip
                                            color={'secondary'}
                                            icon={<AccessTimeIcon />}
                                            onDelete={resetDuration}
                                            label={`${
                                                DURATION_FILTER_TAGS.filter(
                                                    (tag) =>
                                                        tag.value === duration
                                                )[0].label
                                            }`}
                                        />
                                    </Box>
                                )}

                                {[...selectedSkills.keys()].map((skill) => (
                                    <Box display="inline" marginRight={2}>
                                        <Chip
                                            color={'secondary'}
                                            icon={<CheckCircleOutlineIcon />}
                                            onDelete={removeSkill(skill)}
                                            label={skill}
                                        />
                                    </Box>
                                ))}

                                {[...selectLevels.keys()].map((level) => (
                                    <Box display="inline" marginRight={2}>
                                        <Chip
                                            color={'secondary'}
                                            icon={<ControlPointOutlinedIcon />}
                                            onDelete={removeLevel(level)}
                                            label={level}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
            {isLoading ? (
                <Loading />
            ) : filteredJourneys.length > 0 ? (
                <Container maxWidth="xl">
                    <Grid container>
                        {filteredJourneys.map((journey) => (
                            <Grid item xs={12} sm={6} md={4}>
                                {/* <CourseCard course={journey} domain={domain} /> */}
                                <SWCourseCards
                                    course={journey}
                                    domain={domain}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            ) : (
                // <Container maxWidth="lg">
                //     {' '}
                //     <Typography variant="h3">
                //         We are adding more journeys please wait!!
                //     </Typography>{' '}
                // </Container>
                <NoPivateJourney />
            )}
        </Box>
    );
};
