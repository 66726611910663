import { Box } from '@mui/material';
import DropBox from './DropBox';
import useStyles from './styles';

function CommentAttachment() {
    const { classes } = useStyles();

    return (
        <Box className={classes.container}>
            <DropBox />
        </Box>
    );
}

export { CommentAttachment };
