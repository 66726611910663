import { Box, Divider, Typography, Container, Grid } from '../ui';
import { logoArray } from './logos';
import { makeStyles } from 'tss-react/mui';

export const CompanyLogos = () => {
    const { classes } = useStyles();
    return (
        <Container maxWidth="lg">
            <Box my={5}>
                <Typography className={classes.heading}>
                    10s of 1000s of professionals and students trust us to learn
                    by doing​
                </Typography>
                <Grid container justifyContent="center" alignItems="center">
                    {logoArray.map((item, index) => (
                        <Grid
                            key={index}
                            item
                            xs={2}
                            md={1}
                            style={{ backgroundImage: `url(${item})` }}
                            className={classes.logo}
                        />
                    ))}
                </Grid>
            </Box>
            <Divider />
        </Container>
    );
};

const useStyles = makeStyles()((theme) => ({
    heading: {
        width: 'fit-content',
        fontWeight: 700,
        margin: '10px auto 30px',
        fontSize: 'min(1.5em, 6vw)',
        position: 'relative',
        '&::before': {
            position: 'absolute',
            content: '""',
            left: 0,
            bottom: '-3px',
            height: '3px',
            borderRadius: '5px',
            width: '100%',
            backgroundColor: theme.palette.secondary.main,
        },
    },
    logo: {
        boxSizing: 'border-box',
        height: '70px',
        margin: '10px',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
}));
