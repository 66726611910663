// import styled from 'styled-components';
import { DateRange } from '@mui/icons-material';
import { useMemo } from 'react';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { searchQueryState } from '../search-and-browse/state';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import {
    CourseHeaderImgBox,
    CourseStatsContainer,
    EnrollBtnContainer,
    HeaderContent,
    HeaderContentWrapper,
    HeaderTitle,
    HideLgBtnGroup,
    SectionContainer,
    SectionText,
    StyledLink,
    StyledSpan,
} from '../custom-components/styled-components';
import ProfileWrapper from '../custom-components/ProfileWrapper';
import AccessContainer from './AccessContainer';
import NewCoursePreview from './NewCoursePreview';
import { makeStyles } from 'tss-react/mui';
import ReactHTMLParser from 'react-html-parser';

const useStyles = makeStyles()((theme) => ({
    InstructorWrapper: { display: 'flex', alignItems: 'center', gap: '0.5rem' },
    InstructorNames: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.25rem',
        flexWrap: 'wrap',
        '& > a:not(:last-child)::after': {
            content: "','",
        },
        '& > a': {
            padding: '0',
            margin: '0',
            fontSize: '1rem',
            lineHeight: '1.5rem',
            cursor: 'pointer',
        },
        [theme.breakpoints.up('md')]: {
            '& > a': {
                fontSize: '1.125rem',
                lineHeight: '1.75rem',
            },
            '& > a:hover': {
                textDecoration: 'underline',
            },
        },
    },
    CourseHeader: {
        backgroundColor: 'white',
        padding: '0',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    CourseHeaderContentWrapper: {},
    CourseHeaderContent: {},
}));

const ChannelLink = (props) => {
    const { instructorSlug, instructor } = props;
    return (
        <Link to={`/channels/${instructorSlug}`} key={instructor}>
            {instructor}
        </Link>
    );
};

const CoursePageHeader = (props) => {
    const { classes } = useStyles();

    const { courseData, wpCourseData } = props;
    const [_, setQuery] = useRecoilState(searchQueryState);
    let navigate = useNavigate();

    const instructors = useMemo(() => {
        return wpCourseData?.instructors;
    }, [wpCourseData?.instructors]);

    const instructorPic = useMemo(() => {
        const instructors = wpCourseData?.instructors;

        if (instructors.length > 0) {
            return (
                instructors[0]?.instructorPic ||
                instructors[0]?.instructorPicNoBackground
            );
        }

        return '';
    }, [wpCourseData?.instructors]);

    const instructorName = useMemo(() => {
        const instructors = wpCourseData?.instructors;

        if (instructors.length > 0) {
            return instructors[0]?.instructor;
        }

        return '';
    }, [wpCourseData?.instructors]);

    const courseDuration = useMemo(() => {
        const hours = wpCourseData?.hours || 0;
        const minutes = wpCourseData?.minutes || 0;

        const hoursText =
            hours > 1 ? `${hours} hours` : hours === 1 ? '1 hour' : '';
        const minutesText =
            minutes > 1
                ? `${minutes} minutes`
                : minutes === 1
                ? '1 minute'
                : '';

        if (hours && !minutes) return hoursText;
        if (!hours && minutes) return minutesText;

        return `${hoursText} ${minutesText}`.trim();
    }, [wpCourseData.hours, wpCourseData.minutes]);

    const formattedPublishedDate = useMemo(() => {
        return moment(courseData?.created_at).format('MMM YYYY');
    }, [courseData?.created_at]);

    const instructorSlug = useMemo(() => {
        if (
            wpCourseData?.instructorSlug &&
            wpCourseData?.instructorSlug !== ''
        ) {
            return `/channels/${wpCourseData.instructorSlug}/on-air`;
        } else {
            return '#';
        }
    }, [wpCourseData.instructorSlug]);

    const computedDescription = useMemo(() => {
        if (courseData?.description) return courseData.description;

        if (courseData?.seo_description) return courseData.seo_description;

        return 'No course description available';
    }, [courseData?.description, courseData?.seo_description]);

    const setQueryParam = (tagVal) => {
        setQuery(tagVal);
        if (tagVal !== '') {
            navigate({
                pathname: '/search',
                /* search: `?q=${searchString}`, */
            });
        }
    };

    const showPreviewButton = useMemo(() => {
        if (wpCourseData?.previewVideo) {
            return true;
        } else {
            return false;
        }
    }, [wpCourseData?.previewVideo]);

    return (
        <SectionContainer id="overview" className={classes.CourseHeader}>
            <HeaderContentWrapper
                className={classes.CourseHeaderContentWrapper}
            >
                <HeaderContent className={classes.CourseHeaderContent}>
                    <HeaderTitle>{courseData?.name}</HeaderTitle>

                    <SectionText>
                        {ReactHTMLParser(computedDescription)}
                    </SectionText>

                    {/* <ProfileWrapper
                        instructorName={instructorName}
                        instructorSlug={instructorSlug}
                        instructorPic={instructorPic}
                    /> */}

                    {/* INSTRUCTOR DATA */}

                    <div className={classes.InstructorWrapper}>
                        <AvatarGroup max={3}>
                            {instructors.map((data) => {
                                return (
                                    <Avatar
                                        key={data?.instructor}
                                        alt={data?.instructor}
                                        src={
                                            data?.instructorPic ||
                                            data?.instructorPicNoBackground
                                        }
                                    />
                                );
                            })}
                        </AvatarGroup>
                        {instructors.length > 2 ? (
                            <div className={classes.InstructorNames}>
                                {instructors.slice(0, 2).map((data) => {
                                    return (
                                        <ChannelLink
                                            key={data?.instructorSlug}
                                            instructorSlug={
                                                data?.instructorSlug
                                            }
                                            instructor={data?.instructor}
                                        />
                                    );
                                })}
                                ...
                            </div>
                        ) : (
                            <div className={classes.InstructorNames}>
                                {instructors.map((data) => {
                                    return (
                                        <ChannelLink
                                            key={data?.instructorSlug}
                                            instructorSlug={
                                                data?.instructorSlug
                                            }
                                            instructor={data?.instructor}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </div>

                    <CourseStatsContainer>
                        <StyledSpan
                            onClick={(e) => setQueryParam(wpCourseData?.domain)}
                        >
                            <StyledLink>{wpCourseData?.domain}</StyledLink>
                            &nbsp;&#124;&nbsp;
                        </StyledSpan>
                        <StyledSpan>
                            {wpCourseData?.level} &#124;&nbsp;
                        </StyledSpan>
                        <StyledSpan>{courseDuration} &#124;&nbsp;</StyledSpan>
                        <StyledSpan>
                            <DateRange
                                fontSize="small"
                                style={{ color: 'gray', padding: 0, margin: 0 }}
                            />
                            Published: {formattedPublishedDate}
                        </StyledSpan>
                    </CourseStatsContainer>

                    <HideLgBtnGroup>
                        {/* PREVIEW BUTTON */}
                        {showPreviewButton && (
                            <NewCoursePreview wpCourseData={wpCourseData} />
                        )}
                        <AccessContainer
                            course={courseData}
                            courseId={courseData?.productable_id}
                            courseTag={wpCourseData?.courseTag}
                            domain={wpCourseData?.domain}
                            time={courseDuration}
                            credits={
                                wpCourseData?.credits?.trim() === ''
                                    ? 10
                                    : wpCourseData?.credits
                            }
                        />
                    </HideLgBtnGroup>
                </HeaderContent>
            </HeaderContentWrapper>

            <CourseHeaderImgBox backgroundImageUrl={wpCourseData?.thumbnail}>
                <EnrollBtnContainer>
                    {/* PREVIEW BUTTON */}
                    {showPreviewButton && (
                        <NewCoursePreview wpCourseData={wpCourseData} />
                    )}

                    {/* ENROLL BUTTON */}
                    <AccessContainer
                        course={courseData}
                        courseId={courseData?.productable_id}
                        courseTag={wpCourseData?.courseTag}
                        domain={wpCourseData?.domain}
                        time={courseDuration}
                        credits={
                            wpCourseData?.credits?.trim() === ''
                                ? 10
                                : wpCourseData?.credits
                        }
                    />
                </EnrollBtnContainer>
            </CourseHeaderImgBox>
        </SectionContainer>
    );
};

// const CourseHeader = styled(SectionContainer)`
//     background-color: white;
//     padding: 0;

//     @media (min-width: 48em) {
//         display: flex;
//     }
// `;

// const InstructorWrapper = styled.div`
//     display: flex;
//     align-items: center;
//     gap: 0.5rem;
// `;

// const InstructorNames = styled.div`
//     display: flex;
//     align-items: center;
//     gap: 0.25rem;
//     flex-wrap: wrap;

//     & > a:not(:last-child)::after {
//         content: ',';
//     }

//     & > a {
//         padding: 0;
//         margin: 0;
//         font-size: 1rem;
//         line-height: 1.5rem;
//         cursor: pointer;

//         @media (min-width: 48em) {
//             font-size: 1.125rem; /* 18px */
//             line-height: 1.75rem;
//             &:hover {
//                 text-decoration: underline;
//             }
//         }
//     }
// `;

// const CourseHeaderContentWrapper = styled(HeaderContentWrapper)``;

// const CourseHeaderContent = styled(HeaderContent)``;

export default CoursePageHeader;
