import { getCoursesForCollection } from '../courses/getCoursesForCollection';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { categories } from './useCategoryDetails';
import { bundleCoursesSectionState } from './recoil';
import { useRecoilState } from 'recoil';
import { shuffle } from '../util/shuffle';
import axios from 'axios';

const NEW_TAG = 'NEW';
const POPULAR_TAG = 'POPULAR';
const HIGHLY_RATED_TAG = 'HIGHLY-RATED';
const TRENDING_TAG = 'TRENDING';
const UPCOMING_TAG = 'UPCOMING';
const ALL_TAG = 'ALL';

const TAGS = [
    NEW_TAG,
    POPULAR_TAG,
    HIGHLY_RATED_TAG,
    TRENDING_TAG,
    UPCOMING_TAG,
    ALL_TAG,
];

export const useBundleCourses = (bundleId) => {
    const [coursesDetails, setCoursesDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [allCourses, setAllCourses] = useRecoilState(
        bundleCoursesSectionState
    );
    // console.log(allCourses);
    // const courses = useSelector(getCoursesForCollection(bundleId));
    const formatData = useCallback((data = []) => {
        console.log('formatting data...');

        // const newCourse = [];
        // const popularCourse = [];
        // const upcomingCourse = [];
        // const trendingCourse = [];
        // const highlyRatedCourse = [];

        const courses = [];
        // const notMarkedCourse = [];

        // const allAssorted = [];

        // formatting course
        data.forEach((data) => {
            const dataObject = {
                title: data.name,
                image: data.course_card_image_url,
                instructor: data.instructor,
                instructors: data.instructors,
                time: data.time,
                domain: data.domain,
                id: data.product_id,
                productId: data.id,
                description: data.description,
                rating: data.rating,
                baseClaps: data.baseClaps,
                level: data.level,
                link: `/courses/${data.slug}`,
                slug: data.slug,
                type: 'BUNDLE-ON-DEMAND',
                // wpSlug: data.wpSlug,
                likes: data.likes,
                comments: data.comments,
                noOfStudents: data.noOfStudents,
                courseTag: data.courseTag,
                skills: data.skills,

                hours: data.hours,
                minutes: data.minutes,
                // includedInCurationLibrary: data.includedInCurationLibrary,
            };
            // allAssorted.push(dataObject);
            // if (dataObject.courseTag === NEW_TAG) {
            //     newCourse.push(dataObject);
            //     return;
            // }
            // if (dataObject.courseTag === POPULAR_TAG) {
            //     popularCourse.push(dataObject);
            //     return;
            // }

            // if (dataObject.courseTag === UPCOMING_TAG) {
            //     upcomingCourse.push(dataObject);
            //     return;
            // }
            // if (dataObject.courseTag === HIGHLY_RATED_TAG) {
            //     highlyRatedCourse.push(dataObject);
            //     return;
            // }
            // if (dataObject.courseTag === TRENDING_TAG) {
            //     trendingCourse.push(dataObject);
            //     return;
            // }
            // if (data.courseTag) {
            //     markedCourses.push(dataObject);
            // } else {
            courses.push(dataObject);
            // }
        });
        // getting all courses
        return courses;
    }, []);

    const updateInRecoil = useCallback(
        (newCourse, bundleId) => {
            console.log('updating in recoil data...');
            let newAllCourses = { ...allCourses };
            newAllCourses[`${bundleId}`] = newCourse;
            setAllCourses(newAllCourses);
        },
        [allCourses, setAllCourses]
    );

    const getData = useCallback(async () => {
        if (bundleId) {
            try {
                setIsLoading(true);
                console.log('Fetching data...');
                const { data: courses } = await axios.get(
                    `/api/content/courses/private-bundle?bundleId=${bundleId}`
                );

                // const url = `${
                //     process.env.REACT_APP_SW_WORDPRESS_DOMAIN
                // }/wp-json/headless/catalogue/extras?id=${courses
                //     .map(({ id }) => id)
                //     .join()}&withInstructor=1`;

                const url = `/api/courses?thinkificCourseIds=${courses
                    .map(({ id }) => id)
                    .join()}`;

                const { data } = await axios.get(url);
                const extraDetails = data.courses.reduce(
                    (groupedData, extraData) => {
                        groupedData[
                            `${extraData.thinkificCourseId}`
                        ] = extraData;
                        return groupedData;
                    },
                    {}
                );
                // const { data } = await axios.get('/api/content/details/');
                const newCourses = {};
                const courseIds = [];
                // console.log(extraDetails);

                courses?.forEach((course) => {
                    if (!extraDetails[course.id]) {
                        console.log('Extra details not found in WordPress!');
                        return;
                    }
                    courseIds.push(`${course.product_id}`);
                    const {
                        noOfStudents,
                        hours,
                        minutes,
                        domain,
                        baseClaps,
                        rating,
                        courseTag,
                        skills,
                        level,
                        // slug: wpSlug,
                        // includedInCurationLibrary,
                        instructor,
                        instructors,
                        type,
                    } = extraDetails[course.id] || {};
                    const hrs = parseInt(hours);
                    const mins = parseInt(minutes);
                    const hoursString = hrs
                        ? `${hrs} ${hrs > 1 ? 'hrs' : 'hr'}`
                        : '';
                    const minutesString = mins
                        ? `${mins} ${mins > 1 ? 'mins' : 'min'}`
                        : '';
                    const newCourseObject = {
                        ...course,
                        time: `${hoursString} ${minutesString}`,
                        domain,
                        hours: hrs,
                        minutes: mins,
                        baseClaps,
                        rating,
                        courseTag,
                        skills,
                        level,
                        instructor,
                        instructors,
                        // wpSlug,
                        type,
                        noOfStudents: `${parseFloat(
                            parseFloat(noOfStudents) / 1000
                        ).toFixed(2)}k`,
                        likes: [],
                        comments: [],
                        // includedInCurationLibrary,
                    };
                    newCourses[`${course.product_id}`] = newCourseObject;
                });
                try {
                    const {
                        data: { feedback: courseFeedbacks },
                    } = await axios.get(
                        `/api/content/feedback/courses?ids=${courseIds.join(
                            ','
                        )}`
                    );
                    courseFeedbacks.forEach(({ courseId, value }) => {
                        newCourses[`${courseId}`] = {
                            ...newCourses[`${courseId}`],
                            likes: value.likes,
                            comments: value.comments,
                        };
                    });
                } catch (error) {
                    console.log('Error in fetching comments and likes');
                    console.log(error.message);
                }
                // can make a format card here
                const formattedCoursesData = formatData(
                    Object.values(newCourses)
                );
                // console.log(formattedCoursesData);
                // updating them in the recoil
                updateInRecoil(formattedCoursesData, bundleId);
                setCoursesDetails(formattedCoursesData);
                setIsLoading(false);
            } catch (error) {
                console.log(error.message);
                setIsLoading(false);
            }
        }
        // eslint-disable-next-line
    }, [bundleId, /* courses, */ allCourses]);

    useEffect(() => {
        if (/* courses &&  */ bundleId) {
            if (allCourses[`${bundleId}`]) {
                console.log('Course found!!');
                setCoursesDetails(allCourses[`${bundleId}`]);
                setIsLoading(false);
            } else {
                getData();
            }
        }
        // eslint-disable-next-line
    }, [/* courses, */ bundleId]);

    // const sortedCourse = useMemo(() => {
    //     if (coursesDetails) {
    //         let filteredCourses = [];
    //         Object.keys(coursesDetails).forEach((key) => {
    //             if (coursesDetails[key]?.length) {
    //                 filteredCourses = [
    //                     ...filteredCourses,
    //                     ...coursesDetails[key],
    //                 ];
    //             }
    //         });
    //         return filteredCourses;
    //     } else {
    //         return [];
    //     }
    // }, [coursesDetails]);

    return { courses: coursesDetails, isLoading, sortingTags: TAGS };
};
