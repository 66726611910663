export const IN_PROGRESS = 'IN PROGRESS';
export const COMPLETED = 'COMPLETED';
export const REQUIREMENT = 'OPEN';
export const OPEN = 'OPEN';
export const DRAFT = 'DRAFT';
export const OVERVIEW = 'Analysis';
export const DETAILS = 'Details';
export const LOGISTICS = 'Logistics';
export const DESCRIPTION = 'Description';
export const COMMENTS = 'comments';
export const FEEDS = 'Feeds';
export const ATTACHMENTS = 'Content2';
export const SHAREPOINT = 'Content';
// export const SHAREPOINTINSTRUCTOR = 'Instructor Content';
export const SAMPLES = 'Sample Outlines';
export const GUIDANCE = 'Guidance';
export const MARKET_METRICS = 'Market Metrics';
export const NOTES = 'Internal Notes';
export const ADMINISTRATION = 'Administration';
export const ACTIVITY = 'Activity';
