import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import axios from 'axios';
import { coursesSlice } from '../courses/coursesSlice';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import { enrolledAndPurchasedOnDemandState } from './Recoil/State';
import { resetPasswordRoute } from '../seo/routeMetaData';

const { getCourses } = coursesSlice;

export const useEnrolledAndPurchasedOnDemand = (user) => {
    const courses = useSelector(getCourses);
    const [loading, setLoading] = useState(false);

    const [
        enrolledAndPurchasedOnDemand,
        setEnrolledAndPurchasedOnDemand,
    ] = useRecoilState(enrolledAndPurchasedOnDemandState);

    useEffect(() => {
        (async () => {
            setLoading(true);
            if (user) {
                if (user.user_save.length === 0) {
                    setEnrolledAndPurchasedOnDemand([]);
                } else {
                    if (!enrolledAndPurchasedOnDemand) {
                        const enrolledCourseIds = new Map(
                            user.user_save.map((id) => [id, id])
                        );
                        const enrolledCourses = courses.filter(({ id }) =>
                            enrolledCourseIds.has(id)
                        );

                        const {
                            data: { products },
                        } = await axios.get(
                            `/api/users/${user._id}/purchased-on-demand`
                        );
                        const purchasedCourseIds = new Map(
                            products.map(({ product_id }) => [
                                product_id,
                                product_id,
                            ])
                        );
                        const purchasedCourses = courses.filter(
                            ({ id }) => id == purchasedCourseIds.get(id)
                        );

                        let userCourses = [
                            ...enrolledCourses,
                            ...purchasedCourses,
                        ];

                        // const resExtrasWordPress = await axios.get(
                        //     `${
                        //         process.env.REACT_APP_SW_WORDPRESS_DOMAIN
                        //     }/wp-json/headless/catalogue/dashboard?nowprocket=1&id=${userCourses
                        //         .map(({ productable_id }) => productable_id)
                        //         .join()}`
                        // );

                        const resExtrasWordPress = await axios.get(
                            `/api/courses?thinkificCourseIds=${userCourses
                                .map(({ productable_id }) => productable_id)
                                .join()}`
                        );

                        const extrasWP = new Map(
                            resExtrasWordPress.data?.courses?.map((c) => [
                                parseInt(c.thinkificCourseId),
                                c,
                            ])
                        );

                        //instructor, instructorSlug, hours, minutes
                        userCourses = userCourses.map((course) => ({
                            ...course,
                            ...extrasWP.get(course.productable_id),
                        }));

                        // console.log(
                        //     `/api/enrollments/thinkific-user?thinkificUserId=${
                        //         user.user_thinkific_id || 0
                        //     }&thinkificCourseIds=${userCourses
                        //         .map(({ productable_id }) => productable_id)
                        //         .join()}`
                        // );
                        const resExtrasThinkific = await axios.get(
                            `/api/enrollments/thinkific-user?thinkificUserId=${
                                user.user_thinkific_id || 0
                            }&thinkificCourseIds=${userCourses
                                .map(({ productable_id }) => productable_id)
                                .join()}`
                        );

                        //console.log(resExtrasThinkific.data);
                        const extrasThink = new Map(
                            resExtrasThinkific.data?.map((c) => [
                                parseInt(c.course_id),
                                c,
                            ])
                        );

                        //console.log(extrasThink);

                        //percentage_completed, updated_at
                        userCourses = userCourses.map((course) => ({
                            ...course,
                            ...extrasThink.get(course.productable_id),
                        }));

                        //console.log(userCourses);

                        setEnrolledAndPurchasedOnDemand(userCourses);
                    }
                }
            }

            setLoading(false);
        })();
    }, [
        courses,
        // enrolledAndPurchasedOnDemand,
        setEnrolledAndPurchasedOnDemand,
        user,
    ]);

    return { enrolledAndPurchasedOnDemand, loading };
};
