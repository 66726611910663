import { Button } from '@mui/material';
import { useMemo } from 'react';
import useStyles from './styles';

function FileItem({ file, index, handleRemoveSelectedFile }) {
    const { classes } = useStyles();

    const mimeIconName = useMemo(() => {
        const fileName = file.name;
        return fileName.split('.').pop().toUpperCase();
    }, [file.name]);

    const fileSize = useMemo(() => {
        const size = file.size;
        if (size < 1000) {
            return `${size}B`;
        } else if (size > 1000 && size < 1000 * 1000) {
            return `${Math.floor(size / 1000)}KB`;
        } else {
            return `${Math.floor(size / 1000000)}MB`;
        }
    }, [file.size]);

    return (
        <li key={file.name} className={classes.fileItem}>
            <span className={classes.fileItem__left}>
                <span className={classes.fileItem__num}>{index + 1}.</span>
                <span className={classes.fileItem__info}>
                    <span className={classes.fileItem__icon}>
                        {mimeIconName}
                    </span>
                    <span className={classes.fileItem__name}>{file.name}</span>
                </span>
            </span>
            <span className={classes.fileItem__right}>
                <span>{fileSize}</span>
                <Button
                    size="small"
                    color="error"
                    onClick={(e) => handleRemoveSelectedFile(e, index)}
                >
                    Remove
                </Button>
            </span>
        </li>
    );
}

export default FileItem;
