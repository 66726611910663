import { useEffect, useMemo } from 'react';
import { Box, Button, Tooltip, TextField, MenuItem } from '../ui';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
// import { MANAGER } from '../auth/roles';
import { theme } from '../theme';
import { makeStyles } from 'tss-react/mui';
import { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { selectedRoleState } from './state';
import StreetviewIcon from '@mui/icons-material/Streetview';
import { roleViews, roleViewState } from './recoil/roleView';

const useStyles = makeStyles()((theme) => ({
    selectionInput: {
        color: theme.palette.secondary.main,
        // border: 'none',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '0.9rem',
        // outline: 'none',
        '& fieldset': { border: 'none' },
    },
    selectionItem: {
        color: theme.palette.secondary.main,
        textTransform: 'uppercase',
        textTransform: 'uppercase',
        fontSize: '0.9rem',
    },
}));

const homePaths = {
    student: '/on-air',
    admin: '/admin',
    instructor: '/profile/instructor-dashboard',
    // manager: '/opportunity-management',
    collaborator: '/collaborator/opportunity-management',
};

export const ViewButton = ({
    user,
    isAdmin = false,
    isAdminRoute = false,
    isInstructorRoute = false,
    // routeRoles = [],
    routeRole,
    onToggleDrawer = undefined,
    isSideNav = false,
    allowedRoleViews = [],
}) => {
    let navigate = useNavigate();
    const { classes } = useStyles();
    const theme = useTheme();
    const [roleView, setRoleView] = useRecoilState(roleViewState);

    const viewValue = useMemo(() => {
        if (allowedRoleViews?.length > 0) {
            return roleView.selectedView;
        } else {
            return isAdminRoute
                ? 'admin'
                : isInstructorRoute
                ? 'instructor'
                : routeRole
                ? routeRole
                : 'student';
        }
    }, [
        allowedRoleViews?.length,
        isAdminRoute,
        isInstructorRoute,
        roleView.selectedView,
        routeRole,
    ]);
    // const biggerThanMd = useMediaQuery(theme.breakpoints.up('md'));

    // const [selectedRole, setSelectedRole] = useRecoilState(selectedRoleState);
    // // console.log(selectedRole);

    // useEffect(() => {
    //     // console.log('auto set selected role.');
    //     // console.log(routeRoles);
    //     if (
    //         !isAdminRoute &&
    //         !isInstructorRoute &&
    //         selectedRole === null &&
    //         routeRoles?.length
    //     ) {
    //         // console.log(selectedRole, routeRoles[0]);
    //         setSelectedRole(routeRoles[0]);
    //     } else if (selectedRole && !routeRoles?.length) {
    //         // console.log(selectedRole, routeRoles);
    //         setSelectedRole(null);
    //     } else if (selectedRole && !routeRoles?.includes(selectedRole)) {
    //         // console.log(selectedRole, routeRoles);
    //         setSelectedRole(null);
    //     }
    // }, [navigate.location]);
    const onMenuItemClick = () => {
        if (!onToggleDrawer) return;
        onToggleDrawer();
    };
    return (
        <>
            {user?.is_instructor || isAdmin || user?.roles?.length ? (
                <Box
                    mr={1}
                    display="flex"
                    alignItems={'center'}
                    style={{ gap: isSideNav ? '1rem' : 0 }}
                >
                    {/* <Tooltip
                        title={
                            isInstructorRoute ||
                            isAdminRoute ||
                            routeRoles.length
                                ? 'Student View - Access how students view the Starweaver platform'
                                : !user?.is_instructor
                                ? ''
                                : 'Instructor View - Access your content, reports, messaging and more'
                        }
                    >
                        <Button
                            color="secondary"
                            //href={button.path}
                            variant="text"
                            fullWidth={biggerThanMd ? false : true}
                            onClick={() => {
                                if (
                                    isInstructorRoute ||
                                    isAdminRoute ||
                                    routeRoles.length
                                )
                                    navigate(('/on-air'));
                                else if (isAdmin) navigate(('/admin'));
                                else if (user?.is_instructor)
                                    navigate((
                                        '/profile/instructor-dashboard'
                                    ));
                                else if (user?.roles?.includes(MANAGER))
                                    navigate(('/opportunity-management'));
                                else navigate(('/on-air'));
                            }}
                        >
                            {isInstructorRoute ||
                            isAdminRoute ||
                            routeRoles.length ? (
                                <>
                                    {' '}
                                    {biggerThanMd
                                        ? 'Student'
                                        : 'Switch to student view'}{' '}
                                </>
                            ) : isAdmin ? (
                                <>
                                    {biggerThanMd
                                        ? 'Admin'
                                        : 'Switch to admin view'}
                                </>
                            ) : user?.is_instructor ? (
                                <>
                                    {biggerThanMd
                                        ? 'Instructor'
                                        : 'Switch to instructor view'}
                                </>
                            ) : user?.roles?.length ? (
                                <>{user?.roles[0]}</>
                            ) : (
                                <></>
                            )}
                        </Button>
                    </Tooltip> */}
                    {isSideNav && (
                        <StreetviewIcon
                            style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                        />
                    )}

                    <TextField
                        select
                        // value={
                        //     isAdminRoute
                        //         ? 'admin'
                        //         : isInstructorRoute
                        //         ? 'instructor'
                        //         : // : routeRoles.length
                        //         // ? selectedRole &&
                        //         //   routeRoles.includes(selectedRole)
                        //         //     ? selectedRole
                        //         //     : routeRoles[0]
                        //         routeRole
                        //         ? routeRole
                        //         : 'student'
                        // }
                        value={viewValue}
                        variant="outlined"
                        size="small"
                        onChange={(e) => {
                            if (e.target.value === 'student') {
                                // setSelectedRole(null);
                                setRoleView({
                                    selectedView: roleViews.STUDENT,
                                });
                                setTimeout(() => {
                                    navigate(homePaths['student']);
                                }, 0);
                            } else if (e.target.value === 'admin') {
                                // setSelectedRole(null);
                                setRoleView({ selectedView: roleViews.ADMIN });
                                setTimeout(() => {}, 0);
                                navigate(homePaths['admin']);
                            } else if (e.target.value === 'instructor') {
                                // setSelectedRole(null);
                                setRoleView({
                                    selectedView: roleViews.INSTRUCTOR,
                                });
                                setTimeout(() => {
                                    navigate(homePaths['instructor']);
                                }, 0);
                            } else {
                                if (e.target.value === 'collaborator') {
                                    setRoleView({
                                        selectedView: roleViews.COLLABORATOR,
                                    });
                                }
                                // setSelectedRole(e.target.value);
                                setTimeout(() => {
                                    navigate(homePaths[e.target.value]);
                                }, 0);
                            }
                        }}
                        InputProps={{
                            className: classes.selectionInput,
                            // notchedOutline: classes.noBorder,
                        }}
                        fullWidth={isSideNav}
                    >
                        <MenuItem
                            className={classes.selectionItem}
                            value={'student'}
                            onClick={onMenuItemClick}
                        >
                            {'student'}
                        </MenuItem>
                        {isAdmin && (
                            <MenuItem
                                className={classes.selectionItem}
                                value={'admin'}
                                onClick={onMenuItemClick}
                            >
                                {'admin'}
                            </MenuItem>
                        )}
                        {user?.is_instructor && (
                            <MenuItem
                                className={classes.selectionItem}
                                value={'instructor'}
                                onClick={onMenuItemClick}
                            >
                                {'instructor'}
                            </MenuItem>
                        )}
                        {user?.roles?.map((option) => {
                            return (
                                <MenuItem
                                    className={classes.selectionItem}
                                    key={option}
                                    value={option}
                                    onClick={onMenuItemClick}
                                >
                                    {option}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </Box>
            ) : null}
        </>
    );
};
