import { Box } from '@mui/material';
import { CourseChapterAccordion } from './CourseChapterAccordion';
import { useStyles } from './Styles';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { userTrack } from '../util/tracking';
import {
    SectionContainer,
    SectionHeading,
} from '../custom-components/styled-components';

const Curriculum = (props) => {
    const { courseData, wpCourseData } = props;
    const { classes } = useStyles();
    const [chapters, setChapters] = useState([]);

    useEffect(() => {
        if (!courseData?.name) return;
        (async () => {
            try {
                const res = await axios.get(
                    `/api/content/courses/${courseData?.productable_id}/chapters`
                );

                setChapters(res.data);
            } catch (err) {
                console.log('Error In Curriculum.js', err);
            }

            const trackingObject = {
                is_user_registered:
                    localStorage.getItem('userId') !== 'null' ? true : false,
                user:
                    localStorage.getItem('userId') !== 'null'
                        ? localStorage.getItem('userId')
                        : null,
                page_slug: window.location.pathname,
                activity_type: 'VISIT',
                activity_value: courseData.name,
                course_id: courseData?.id,
            };
            userTrack('visit-on-demand', trackingObject);
        })();
    }, [courseData?.id, courseData.name, courseData?.productable_id]);

    return (
        <SectionContainer id="curriculum">
            <SectionHeading>Curriculum</SectionHeading>
            <Box className={classes.chapterList}>
                {chapters
                    .filter(({ name }) => !name.includes('(draft)'))
                    .map(({ name, id }, index) => (
                        <CourseChapterAccordion
                            name={name}
                            chapterId={id}
                            chapterNumber={index + 1}
                            key={index}
                            course={courseData}
                            courseId={courseData?.productable_id}
                            domain={wpCourseData?.domain}
                            time={`${wpCourseData?.hours} hours & ${wpCourseData?.minutes} minutes`}
                        />
                    ))}
            </Box>
        </SectionContainer>
    );
};

export default Curriculum;
