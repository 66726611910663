import { Box, Grid, Button, Typography } from '../ui';
import { ChevronRightIcon } from '../icons';
import { useState } from 'react';
import { useStyles } from './styles';
import { NavigationTab } from '../ui/CustomUI';
import { PlanCard } from './PlanCard';
import { PLAN_TYPE, PLANS_CARD_DETAILS } from './PlanDetails';
import { Link } from 'react-router-dom';
import { plansRoute as route } from '../seo/routeMetaData';
import { HelmetWrapper } from '../seo';
import { SignUpContent } from '../auth/SignUpContent';
import { CompanyLogos } from '../auth/CompanyLogos';
import { FreeSignupPopup } from '../auth/FreeSignupPopup';
import { useUser } from '../../src/auth/useUser';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

const AllPlans = (props) => {
    const { hideSections, hideHelmet = false } = props;
    const [value, setValue] = useState(0);
    const { classes } = useStyles();
    const [isLoginModalOpen, setIsModalOpen] = useState(false);
    let navigate = useNavigate();
    const { user } = useUser();

    const hide = useMemo(() => {
        if (!hideSections) {
            return true;
        } else if (hideSections) {
            return false;
        }
    }, [hideSections]);

    const openModalOpen = (e) => {
        navigate('/signup');
        // console.log('CLOSE POUP');
        // if (user) {
        //     redirectPage(e);
        // } else {
        //     setIsModalOpen(true);
        // }
        return;
    };
    const onModelClose = () => {
        setIsModalOpen(false);
    };
    const redirectPage = (e) => {
        e.stopPropagation();
        navigate('/signup');
    };

    return (
        <Box mb={6}>
            {!hideHelmet && <HelmetWrapper route={route} />}
            <Box p={4} pt={0}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                >
                    <Grid item className={classes.navTab}>
                        <NavigationTab
                            collections={PLAN_TYPE}
                            value={value}
                            setValue={setValue}
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent="center"
                        xs={12}
                        className={classes.planContainer}
                    >
                        {PLANS_CARD_DETAILS.map((item, index) => (
                            <>
                                <Grid key={index} item xs={12} sm={7} md={4}>
                                    <PlanCard
                                        value={value}
                                        active={item.active}
                                        name={item.name}
                                        caption={item.caption}
                                        originalPrice={item.originalPrice}
                                        price={item && item.price}
                                        features={
                                            item.features && item.features
                                        }
                                        creditId={item.creditId}
                                        creditTooltipText={item.creditTooltip}
                                        id={item.p_id}
                                        trial={item.freeTrial}
                                        discription={item.discription}
                                    />
                                </Grid>
                            </>
                        ))}
                    </Grid>
                    {!user && (
                        <Grid
                            item
                            xs={12}
                            className={classes.browsingContainer}
                        >
                            <Typography align="center" variant="h6">
                                Want to look around? Set-up a free Voyager
                                account
                            </Typography>
                            <Box style={{ width: 'fit-content' }} ml={2}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={(e) => openModalOpen(e)}
                                >
                                    START ABSOLUTELY FREE​{' '}
                                </Button>
                            </Box>
                        </Grid>
                    )}

                    {hide && (
                        <Grid
                            item
                            xs={12}
                            style={{
                                margin: '0 auto',
                                width: '90%',
                            }}
                        >
                            <Link to="/pricing/compare">
                                <Button
                                    className={classes.compareBtn}
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    endIcon={
                                        <ChevronRightIcon fontSize="large" />
                                    }
                                >
                                    Compare our plans
                                </Button>
                            </Link>
                        </Grid>
                    )}
                </Grid>
            </Box>

            {!user && openModalOpen && (
                <FreeSignupPopup
                    open={isLoginModalOpen}
                    onRequestClose={() => {
                        onModelClose(false);
                    }}
                />
            )}

            {hide && <CompanyLogos />}
            {hide && <SignUpContent />}
        </Box>
    );
};
export default AllPlans;
