import { useState } from 'react';
import { Box, Button, IconButton, Tooltip, Typography } from '../ui';
import { CommentIcon } from '../icons';
import { CommentsSideDrawer } from './CommentsSideDrawer';
import useMediaQuery from '@mui/material/useMediaQuery';

export const CommentsButton = ({
    courseId,
    courseName,
    courseSlug,
    mentionables,
    placeholder,
    tooltip,
    tasks,
    teams,
    tasksOutputTemplate,
    visibilityOptions,
    defaultVisibility,
    clientId,
}) => {
    const [open, setOpen] = useState(false);
    const [commentsLength, setCommentsLength] = useState(0);
    const isSmall = useMediaQuery('(max-width:600px)');

    const toggleComments = () => {
        setOpen((prevState) => !prevState);
    };

    return (
        <Box>
            <Box display="flex" alignItems="center">
                <Tooltip title={tooltip || 'Questions & Answers'}>
                    <IconButton
                        size={isSmall ? 'small' : 'default'}
                        onClick={toggleComments}
                    >
                        <CommentIcon fontSize={isSmall ? 'small' : 'default'} />
                    </IconButton>
                    {/* <Button
                        onClick={toggleComments}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        style={{ marginTop: '0.5rem' }}
                    >
                        Q&#x26;A
                    </Button> */}
                </Tooltip>
                {commentsLength > 0 && (
                    <Typography variant="body2">{commentsLength}</Typography>
                )}
            </Box>
            <CommentsSideDrawer
                showDrawer={open}
                onToggleDrawer={toggleComments}
                courseId={courseId}
                courseName={courseName}
                setCommentsLength={setCommentsLength}
                courseSlug={courseSlug}
                mentionables={mentionables}
                placeholder={placeholder}
                tasks={tasks}
                teams={teams}
                tasksOutputTemplate={tasksOutputTemplate}
                visibilityOptions={visibilityOptions}
                defaultVisibility={defaultVisibility}
                clientId={clientId}
            />
        </Box>
    );
};
