import { useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import { Box, Container, CircularProgress, Grid, Button } from '../ui';
import { useStyles } from './styles';
import axios from 'axios';
import { useUser } from '../auth';
import { useNavigate } from 'react-router-dom';
import { EnrollDialog } from '../courses-detail-page/EnrollDialog';
import { EnrollDialogWithSubscription } from './EnrollDialogWithSubscription';
import { Step0 } from '../student-onboarding';
import { useSetRecoilState } from 'recoil';
import { addEnrolledCourse } from '../my-account/Recoil/Selector';
import { userTrack } from '../util/tracking';
import { PLANS_CARD_DETAILS } from '../plans/PlanDetails';
import { TopPicksRecommended } from './TopPicksRecommended';
import { dismissedRecommededCourseIdsState } from './Recoil/State';

import { DismissRecommendationDialog } from './DismissRecommendationDialog';

export const RecommendedOnDemand = ({
    user,
    allAccessibleCourse,
    minutes,
    hours,
    domainname,
    setUser,
}) => {
    const { classes } = useStyles();

    let navigate = useNavigate();
    // enroll
    const [openEnrollWithCredits, setOpenEnrollWithCredits] = useState(false);
    const [openEnrollForSubscription, setOpenEnrollForSubscription] = useState(
        false
    );
    const enrollCourseToUser = useSetRecoilState(addEnrolledCourse);
    const [selectedCourse, setSelectedCourse] = useState({});
    const [recommendedCourses, setRecommendedCourses] = useState([]);

    const [
        recommendedCoursesIDToDismiss,
        setRecommendedCoursesIDToDismiss,
    ] = useState(null);

    const [
        dismissedRecommededCourseIds,
        setDismissedRecommededCourseIds,
    ] = useRecoilState(dismissedRecommededCourseIdsState);

    useEffect(() => {
        console.log(dismissedRecommededCourseIds);

        if (user && !dismissedRecommededCourseIds) {
            console.log(dismissedRecommededCourseIds);
            setDismissedRecommededCourseIds(
                user.dismissed_recommended_course_ids || []
            );
        }
    }, [user]);
    //console.log(dismissedRecommededCourseIds);

    const [isDismissing, setIsDismissing] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingCourse, setIsLoadingCourse] = useState(false);

    useEffect(() => {
        if (user /* && dismissedRecommededCourseIds */) {
            (async () => {
                try {
                    setIsLoading(true);
                    const res = await axios.get(
                        `/api/onboarding/recommendations/${user?._id}`
                    );

                    setRecommendedCourses(
                        res.data.recommendedThinkificCourses.filter(
                            ({ id }) =>
                                !dismissedRecommededCourseIds?.includes(id)
                        )
                    );
                    setIsLoading(false);
                } catch (err) {
                    setIsLoading(false);
                    console.log('Error In StudentOnboardingPage.js');
                    console.log(err);
                }
            })();
        }
    }, [user /* , dismissedRecommededCourseIds */]);

    const planType = useMemo(() => {
        if (user) {
            const plan = PLANS_CARD_DETAILS.filter(
                (item) => item.p_id === user.user_current_plan
            );
            if (plan.length) {
                return plan[0].name;
            } else {
                return user.plan_name;
            }
        } else {
            return 'Voyager';
        }
    }, [user]);

    const memoizeNewData = useMemo(() => {
        if (
            recommendedCourses &&
            recommendedCourses.length > 0 &&
            dismissedRecommededCourseIds
        ) {
            let modifiedDataToUse = [];
            recommendedCourses.forEach((course) => {
                const enrolledStatus = allAccessibleCourse.some(
                    (accessibleCourse) => accessibleCourse.id === course.id
                );
                modifiedDataToUse.push({
                    ...course,
                    showUnEnroll: !enrolledStatus,
                });
            });

            modifiedDataToUse = modifiedDataToUse.filter(
                ({ id }) => !dismissedRecommededCourseIds?.includes(id)
            );

            return modifiedDataToUse;
        } else {
            return [];
        }
    }, [recommendedCourses, allAccessibleCourse, dismissedRecommededCourseIds]);

    const dismissCourse = async () => {
        try {
            if (!isDismissing && recommendedCoursesIDToDismiss) {
                setIsDismissing(true);
                await axios.put(`/api/onboarding/dismiss/${user._id}`, {
                    courseId: recommendedCoursesIDToDismiss,
                });
                setDismissedRecommededCourseIds((prev) =>
                    prev.filter((_id) => _id != recommendedCoursesIDToDismiss)
                );
                // setUser((prev) => ({
                //     ...prev,
                //     dismissed_recommended_course_ids: dismissedRecommededCourseIds.filter(
                //         (_id) => _id != id
                //     ),
                // }));
                setRecommendedCourses((prev) =>
                    prev.filter((c) => c.id != recommendedCoursesIDToDismiss)
                );
                setRecommendedCoursesIDToDismiss(null);
                setIsDismissing(false);
            }
        } catch (error) {
            console.log({ error });
        }
    };

    const handleView = (course) => {
        navigate(`/courses/${course.slug}`);
    };
    // launch the course
    const handleLaunch = async (course) => {
        try {
            await axios.post(`/api/users/thinkific/enroll`, {
                courseId: course.productable_id,
                userId: user.user_thinkific_id,
            });
            const response = await axios.get(
                `/api/sso/${course.slug}/thinkific?clientId=${user.client_id}`,
                {
                    firstName: user.user_firstName,
                    lastName: user.user_lastName,
                }
            );
            const ssoUrl = response.data;
            window.open(ssoUrl, '_blank');
        } catch (e) {
            console.log(e);
        }
    };
    // check what enrollment to open
    const enrollCourse = async (course) => {
        const ifCourseIsAccessible = memoizeNewData.some(
            ({ id, showUnEnroll }) => id === course.id && !showUnEnroll
        );
        if (ifCourseIsAccessible) {
            await handleLaunch(course);
        } else {
            setSelectedCourse(course);
            if (user.user_subscription_id !== '') {
                setOpenEnrollForSubscription(true);
            } else {
                setOpenEnrollWithCredits(true);
            }
        }
    };
    // enrolls the users from here
    const handleEnrollSubscription = async () => {
        setIsLoadingCourse(true);
        try {
            await axios.put(`/api/users/saveUnSaveCourse/${user._id}`, {
                id: selectedCourse.id,
                action: 'save',
                course: selectedCourse,
            });
            enrollCourseToUser({ action: 'ADD', payload: selectedCourse.id });
            trackThisCourse(selectedCourse);
            await handleLaunch(selectedCourse);
        } catch (error) {
            console.error(error);
        }
        setIsLoadingCourse(false);
    };

    // track the course
    const trackThisCourse = (course) => {
        const trackingObject = {
            is_user_registered: localStorage.getItem('userId') ? true : false,
            user: localStorage.getItem('userId'),
            page_slug: window.location.pathname,
            activity_type: 'CLICK',
            activity_value: course.name,
            course_id: course.id,
        };
        userTrack('click-enroll-on-demand', trackingObject);
    };

    const onClose = () => {
        setOpenEnrollForSubscription(false);
        setSelectedCourse({});
    };

    return (
        <Box>
            {user && !isLoading ? (
                memoizeNewData.length > 0 ? (
                    <>
                        <TopPicksRecommended
                            user={user}
                            minu={minutes}
                            hur={hours}
                            domains={domainname}
                            color="#333"
                            data={memoizeNewData}
                            heading="Your recommended courses"
                            showViewAll={false}
                            showActions={true}
                            launchText="View"
                            removeText="Enroll"
                            handleOnClick={enrollCourse}
                            handleLaunch={handleView}
                            dismissCourse={dismissCourse}
                            setRecommendedCoursesIDToDismiss={
                                setRecommendedCoursesIDToDismiss
                            }
                        />
                    </>
                ) : (
                    <Container
                        maxWidth="md"
                        className={classes.introRecommendation}
                    >
                        <Step0 planType={planType} />

                        <Link to="/student-onboarding?s=1">
                            <Button
                                color="secondary"
                                variant="contained"
                                fullWidth
                                className={classes.continueBtn}
                                //onClick={nextStep}
                            >
                                Continue
                            </Button>
                        </Link>
                        <Grid container>
                            {/* <Grid item className={classes.skipBtnContainer}>
                                <Link to="/profile/dashboard/saved-register">
                                    <Button
                                        className={classes.skipButton}
                                        //onClick={nextStep}
                                    >
                                        Skip
                                    </Button>
                                </Link>
                            </Grid> */}
                        </Grid>
                    </Container>
                )
            ) : (
                <Box className={classes.load}>
                    <CircularProgress color="secondary" />
                </Box>
            )}
            {selectedCourse.id && (
                <>
                    <EnrollDialog
                        open={openEnrollWithCredits}
                        setOpen={setOpenEnrollWithCredits}
                        course={selectedCourse}
                        user={user}
                        track={() => handleLaunch(selectedCourse)}
                    />
                    <EnrollDialogWithSubscription
                        onClose={onClose}
                        handleEnroll={handleEnrollSubscription}
                        open={openEnrollForSubscription}
                        course={selectedCourse}
                        title={selectedCourse.name}
                        isLoading={isLoadingCourse}
                    />
                </>
            )}

            <DismissRecommendationDialog
                onClose={() => {
                    setRecommendedCoursesIDToDismiss(null);
                }}
                handleRemove={dismissCourse}
                open={recommendedCoursesIDToDismiss}
            />
        </Box>
    );
};
