import { TopPicks } from './TopPicks';
import { Link } from 'react-router-dom';
import { Box, CircularProgress, Grid, Typography, Button } from '../ui';
import { useStyles } from './styles';
import { useSetRecoilState } from 'recoil';
import { addCourseToUser } from '../my-account/Recoil/Selector';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../auth/useUser';

export const SavedCourses = ({ user, savedCourse, isDashboard = false }) => {
    const { classes } = useStyles();
    let navigate = useNavigate();
    const { user: firebaseUser } = useUser();
    const addOrRemoveCourse = useSetRecoilState(addCourseToUser);

    const removeCourse = async (course) => {
        try {
            await axios.put(`/api/users/${user._id}/save-and-remove`, {
                id: course.id,
                action: 'unsave',
            });
            addOrRemoveCourse({ action: 'REMOVE', payload: course.id });
            console.log('removed');
        } catch (error) {
            console.log(error);
        }
    };
    const handleView = (course) => {
        navigate(`/courses/${course.slug}`);
    };

    return (
        <Grid xs={12}>
            {user ? (
                savedCourse && savedCourse.length > 0 ? (
                    <>
                        <TopPicks
                            color="#333"
                            data={savedCourse}
                            heading="Saved Courses"
                            showViewAll={false}
                            showActions={true}
                            launchText="View"
                            removeText="Remove"
                            handleOnClick={removeCourse}
                            handleLaunch={handleView}
                            showRemoveBtn={true}
                            isDashboard={isDashboard}
                        />
                    </>
                ) : (
                    ''
                )
            ) : (
                <Box className={classes.load}>
                    <CircularProgress color="secondary" />
                </Box>
            )}
        </Grid>
    );
};
