import { useCallback, useEffect, useRef } from 'react';
import firebase from 'firebase/app';
import axios from 'axios';

export const useLoginLayer2 = () => {
    const isUserRef = useRef(false);

    const loginLayer2 = useCallback(() => {
        console.log('login layer 2 called');
        axios.get('/api/auth/login2').catch(console.error);
    }, []);

    const logoutLayer2 = useCallback(() => {
        console.log('logout layer 2 called');
        axios.get('/api/auth/logout2').catch(console.error);
    }, []);

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            console.log('18');
            if (user && isUserRef.current === false) {
                console.log('20');
                isUserRef.current = true;
                loginLayer2();
            } else if (!user && isUserRef.current === true) {
                console.log('24');
                isUserRef.current = false;
                logoutLayer2();
            }
        });

        return unsubscribe;
    }, [loginLayer2, logoutLayer2]);
};
