export const jobTitles = [
    {
        id: 1,
        name: 'Web Developer',
    },
    {
        id: 2,
        name: 'UX Designer & UI Developer',
    },
    {
        id: 3,
        name: 'System Engineer',
    },
    {
        id: 4,
        name: 'Software Engineer',
    },
    {
        id: 5,
        name: 'Developer',
    },
    {
        id: 6,
        name: 'Network Engineer',
    },
    {
        id: 7,
        name: 'Cloud System Administrator',
    },
    {
        id: 8,
        name: 'Program/Product Manager',
    },
    {
        id: 9,
        name: 'Database Administrator',
    },
    {
        id: 10,
        name: 'DevOps Engineer',
    },
];

export const professionalGoals = [
    {
        id: 1,
        name: 'Get Into Heroku',
    },
    {
        id: 2,
        name: 'Get Into AWS ',
    },
    {
        id: 3,
        name: 'Get Into Angular',
    },
    {
        id: 4,
        name: 'Build Great Websites',
    },
    {
        id: 5,
        name: 'Get Into Wireframing',
    },
    {
        id: 6,
        name: 'Design A website from Scratch',
    },
    {
        id: 7,
        name: 'Get Into Prototyping',
    },
    {
        id: 8,
        name: 'Design A website from Scratch',
    },
    {
        id: 9,
        name: 'Get Into Jenkins',
    },
    {
        id: 10,
        name: 'Learn to build serverless apps',
    },
];

export const topics = [
    {
        id: 1,
        name: '3D Game Development',
    },
    {
        id: 2,
        name: '3D Modeling',
    },
    {
        id: 3,
        name: 'Adobe Illustrator',
    },
    {
        id: 4,
        name: 'Adobe Premiere',
    },
    {
        id: 5,
        name: 'After Effects',
    },
    {
        id: 6,
        name: 'Agile',
    },
    {
        id: 7,
        name: 'Algorithms',
    },
    {
        id: 8,
        name: 'Amazon AWS',
    },
    {
        id: 9,
        name: 'Android Development',
    },
    {
        id: 10,
        name: 'Angular',
    },
    {
        id: 11,
        name: 'Apache Kafka',
    },
    {
        id: 12,
        name: 'Apache Spark',
    },
    {
        id: 13,
        name: 'Artificial Intelligence',
    },
    {
        id: 14,
        name: 'ASP.NET Core',
    },
    {
        id: 15,
        name: 'ASP.NET MVC',
    },
    {
        id: 16,
        name: 'AWS Certification',
    },
    {
        id: 17,
        name: 'AWS Certified Cloud Practitioner',
    },
    {
        id: 18,
        name: 'AWS Certified Developer - Associate',
    },
    {
        id: 19,
        name: 'AWS Certified Solutions Architect - Associate',
    },
    {
        id: 20,
        name: 'Big Data',
    },
    {
        id: 21,
        name: 'Blender',
    },
    {
        id: 22,
        name: 'Blockchain',
    },
    {
        id: 23,
        name: 'Bootstrap',
    },
    {
        id: 24,
        name: 'Business Analysis',
    },
    {
        id: 25,
        name: 'Business Analytics',
    },
    {
        id: 26,
        name: 'C',
    },
    {
        id: 27,
        name: 'C#',
    },
    {
        id: 28,
        name: 'C++',
    },
    {
        id: 29,
        name: 'CCNA 200-301',
    },
    {
        id: 30,
        name: 'Certified Kubernetes Application Developer (CKAD)',
    },
    {
        id: 31,
        name: 'Character Design',
    },
    {
        id: 32,
        name: 'Cisco CCNA',
    },
    {
        id: 33,
        name: 'Coding Interview',
    },
    {
        id: 34,
        name: 'CompTIA A+',
    },
    {
        id: 35,
        name: 'CompTIA Security+',
    },
    {
        id: 36,
        name: 'Computer Vision',
    },
    {
        id: 37,
        name: 'Credit Analysis',
    },
    {
        id: 38,
        name: 'Credit Derivatives',
    },
    {
        id: 39,
        name: 'Cryptocurrency',
    },
    {
        id: 40,
        name: 'CSS',
    },
    {
        id: 41,
        name: 'Dart Programming Language',
    },
    {
        id: 42,
        name: 'Data Analysis',
    },
    {
        id: 43,
        name: 'Data Science',
    },
    {
        id: 44,
        name: 'Data Structures',
    },
    {
        id: 45,
        name: 'Data Visualization',
    },
    {
        id: 46,
        name: 'Day Trading',
    },
    {
        id: 47,
        name: 'Deep Learning',
    },
    {
        id: 48,
        name: 'Derivatives',
    },
    {
        id: 49,
        name: 'Design Pattern',
    },
    {
        id: 50,
        name: 'DevOps',
    },
    {
        id: 51,
        name: 'Digital Marketing',
    },
    {
        id: 52,
        name: 'Django',
    },
    {
        id: 53,
        name: 'Docker',
    },
    {
        id: 54,
        name: 'Ethereum',
    },
    {
        id: 55,
        name: 'Ethical Hacking',
    },
    {
        id: 56,
        name: 'Excel',
    },
    {
        id: 57,
        name: 'Excel VBA',
    },
    {
        id: 58,
        name: 'Express Framework',
    },
    {
        id: 59,
        name: 'Facebook Ads',
    },
    {
        id: 60,
        name: 'Finance Fundamentals',
    },
    {
        id: 61,
        name: 'Financial Analysis',
    },
    {
        id: 62,
        name: 'Financial Services',
    },
    {
        id: 63,
        name: 'Flask',
    },
    {
        id: 64,
        name: 'Forex',
    },
    {
        id: 65,
        name: 'Front End Web Development',
    },
    {
        id: 66,
        name: 'Full Stack Web Development',
    },
    {
        id: 67,
        name: 'Game Development Fundamentals',
    },
    {
        id: 68,
        name: 'Git',
    },
    {
        id: 69,
        name: 'Go Programming Language',
    },
    {
        id: 70,
        name: 'Google Ads (Adwords)',
    },
    {
        id: 71,
        name: 'Google Flutter',
    },
    {
        id: 72,
        name: 'Graphic Design',
    },
    {
        id: 73,
        name: 'GraphQL',
    },
    {
        id: 74,
        name: 'Healthcare',
    },
    {
        id: 75,
        name: 'Hibernate',
    },
    {
        id: 76,
        name: 'HTML',
    },
    {
        id: 77,
        name: 'HTML5',
    },
    {
        id: 78,
        name: 'IELTS',
    },
    {
        id: 79,
        name: 'Insdustry Analysis',
    },
    {
        id: 80,
        name: 'Instagram Marketing',
    },
    {
        id: 81,
        name: 'Insurance',
    },
    {
        id: 82,
        name: 'Investing',
    },
    {
        id: 83,
        name: 'iOS Development',
    },
    {
        id: 84,
        name: 'Java',
    },
    {
        id: 85,
        name: 'JavaScript',
    },
    {
        id: 86,
        name: 'Jenkins',
    },
    {
        id: 87,
        name: 'Kotlin',
    },
    {
        id: 88,
        name: 'Kubernetes',
    },
    {
        id: 89,
        name: 'Laravel',
    },
    {
        id: 90,
        name: 'Linux',
    },
    {
        id: 91,
        name: 'Machine Learning',
    },
    {
        id: 92,
        name: 'MERN Stack',
    },
    {
        id: 93,
        name: 'Microservices',
    },
    {
        id: 94,
        name: 'Microsoft AZ-900',
    },
    {
        id: 95,
        name: 'Microsoft Azure',
    },
    {
        id: 96,
        name: 'Microsoft Certification',
    },
    {
        id: 97,
        name: 'Microsoft Power BI',
    },
    {
        id: 98,
        name: 'Mobile Development',
    },
    {
        id: 99,
        name: 'MongoDB',
    },
    {
        id: 100,
        name: 'MySQL',
    },
    {
        id: 101,
        name: 'Natural Language Processing',
    },
    {
        id: 102,
        name: 'Neural Networks',
    },
    {
        id: 103,
        name: 'Next.js',
    },
    {
        id: 104,
        name: 'Node.Js',
    },
    {
        id: 105,
        name: 'Object Oriented Programming',
    },
    {
        id: 106,
        name: 'Oracle Certification',
    },
    {
        id: 107,
        name: 'Oracle SQL',
    },
    {
        id: 108,
        name: 'Pharmaecutical',
    },
    {
        id: 109,
        name: 'PHP',
    },
    {
        id: 110,
        name: 'PMBOK',
    },
    {
        id: 111,
        name: 'PMP',
    },
    {
        id: 112,
        name: 'Programming Fundamentals',
    },
    {
        id: 113,
        name: 'Project Management',
    },
    {
        id: 114,
        name: 'Python',
    },
    {
        id: 115,
        name: 'R',
    },
    {
        id: 116,
        name: 'React',
    },
    {
        id: 117,
        name: 'React Hooks',
    },
    {
        id: 118,
        name: 'React Native',
    },
    {
        id: 119,
        name: 'Real Estate Analysis',
    },
    {
        id: 120,
        name: 'Real Estate Investing',
    },
    {
        id: 121,
        name: 'Redux Framework',
    },
    {
        id: 122,
        name: 'Reiki',
    },
    {
        id: 123,
        name: 'Responsive Design',
    },
    {
        id: 124,
        name: 'REST API',
    },
    {
        id: 125,
        name: 'Ruby on Rails',
    },
    {
        id: 126,
        name: 'SaaS',
    },
    {
        id: 127,
        name: 'Scala',
    },
    {
        id: 128,
        name: 'Scrum',
    },
    {
        id: 129,
        name: 'Selenium Testing Framework',
    },
    {
        id: 130,
        name: 'Selenium WebDriver',
    },
    {
        id: 131,
        name: 'SEO',
    },
    {
        id: 132,
        name: 'Social Media Marketing',
    },
    {
        id: 133,
        name: 'Software Testing',
    },
    {
        id: 134,
        name: 'Solidity',
    },
    {
        id: 135,
        name: 'Spring Boot',
    },
    {
        id: 136,
        name: 'Spring Framework',
    },
    {
        id: 137,
        name: 'SQL',
    },
    {
        id: 138,
        name: 'SQL Server',
    },
    {
        id: 139,
        name: 'Statistics',
    },
    {
        id: 140,
        name: 'Stock Trading',
    },
    {
        id: 141,
        name: 'Swift',
    },
    {
        id: 142,
        name: 'SwiftUI',
    },
    {
        id: 143,
        name: 'Tableau',
    },
    {
        id: 144,
        name: 'TDD Test Driven Development',
    },
    {
        id: 145,
        name: 'Technical Analysis',
    },
    {
        id: 146,
        name: 'TensorFlow',
    },
    {
        id: 147,
        name: 'Typescript',
    },
    {
        id: 148,
        name: 'Unity',
    },
    {
        id: 149,
        name: 'Unreal Engine',
    },
    {
        id: 150,
        name: 'Unreal Engine Blueprints',
    },
    {
        id: 151,
        name: 'User Experience Design',
    },
    {
        id: 152,
        name: 'Video Editing',
    },
    {
        id: 153,
        name: 'Vue JS',
    },
    {
        id: 154,
        name: 'Vuex',
    },
    {
        id: 155,
        name: 'Web Design',
    },
    {
        id: 156,
        name: 'Web Development',
    },
    {
        id: 157,
        name: 'WordPress',
    },
];

export const designationLabelsAndValues = [
    { label: "I'm a student", value: 'Student' },
    { label: 'I’m a working professional', value: 'Professional' },
    {
        label: 'I’m between jobs and looking for my next opportunity',
        value: 'Job seeker',
    },
    { label: 'Other', value: 'Other' },
];

export const yesAndNoLabelsAndValues = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
    { label: 'Not sure', value: 'Not sure' },
];

/* export const topics = [
    '3D Game Development',

    '3D Modeling',

    'Adobe Illustrator',

    'Adobe Premiere',

    'After Effects',

    'Agile',

    'Algorithms',

    'Amazon AWS',

    'Android Development',

    'Angular',

    'Apache Kafka',

    'Apache Spark',

    'Artificial Intelligence',

    'ASP.NET Core',

    'ASP.NET MVC',

    'AWS Certification',

    'AWS Certified Cloud Practitioner',

    'AWS Certified Developer - Associate',

    'AWS Certified Solutions Architect - Associate',

    'Big Data',

    'Blender',

    'Blockchain',

    'Bootstrap',

    'Business Analysis',

    'Business Analytics',

    'C',

    'C#',

    'C++',

    'CCNA 200-301',

    'Certified Kubernetes Application Developer (CKAD)',

    'Character Design',

    'Cisco CCNA',

    'Coding Interview',

    'CompTIA A+',

    'CompTIA Security+',

    'Computer Vision',

    'Credit Analysis',

    'Credit Derivatives',

    'Cryptocurrency',

    'CSS',

    'Dart Programming Language',

    'Data Analysis',

    'Data Science',

    'Data Structures',

    'Data Visualization',

    'Day Trading',

    'Deep Learning',

    'Derivatives',

    'Design Pattern',

    'DevOps',

    'Digital Marketing',

    'Django',

    'Docker',

    'Ethereum',

    'Ethical Hacking',

    'Excel',

    'Excel VBA',

    'Express Framework',

    'Facebook Ads',

    'Finance Fundamentals',

    'Financial Analysis',

    'Financial Services',

    'Flask',

    'Forex',

    'Front End Web Development',

    'Full Stack Web Development',

    'Game Development Fundamentals',

    'Git',

    'Go Programming Language',

    'Google Ads (Adwords)',

    'Google Flutter',

    'Graphic Design',

    'GraphQL',

    'Healthcare',

    'Hibernate',

    'HTML',

    'HTML5',

    'IELTS',

    'Insdustry Analysis',

    'Instagram Marketing',

    'Insurance',

    'Investing',

    'iOS Development',

    'Java',

    'JavaScript',

    'Jenkins',

    'Kotlin',

    'Kubernetes',

    'Laravel',

    'Linux',

    'Machine Learning',

    'MERN Stack',

    'Microservices',

    'Microsoft AZ-900',

    'Microsoft Azure',

    'Microsoft Certification',

    'Microsoft Power BI',

    'Mobile Development',

    'MongoDB',

    'MySQL',

    'Natural Language Processing',

    'Neural Networks',

    'Next.js',

    'Node.Js',

    'Object Oriented Programming',

    'Oracle Certification',

    'Oracle SQL',

    'Pharmaecutical',

    'PHP',

    'PMBOK',

    'PMP',

    'Programming Fundamentals',

    'Project Management',

    'Python',

    'R',

    'React',

    'React Hooks',

    'React Native',

    'Real Estate Analysis',

    'Real Estate Investing',

    'Redux Framework',

    'Reiki',

    'Responsive Design',

    'REST API',

    'Ruby on Rails',

    'SaaS',

    'Scala',

    'Scrum',

    'Selenium Testing Framework',

    'Selenium WebDriver',

    'SEO',

    'Social Media Marketing',

    'Software Testing',

    'Solidity',

    'Spring Boot',

    'Spring Framework',

    'SQL',

    'SQL Server',

    'Statistics',

    'Stock Trading',

    'Swift',

    'SwiftUI',

    'Tableau',

    'TDD Test Driven Development',

    'Technical Analysis',

    'TensorFlow',

    'Typescript',

    'Unity',

    'Unreal Engine',

    'Unreal Engine Blueprints',

    'User Experience Design',

    'Video Editing',

    'Vue JS',

    'Vuex',

    'Web Design',

    'Web Development',

    'WordPress',
];
 */
