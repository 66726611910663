import { useState, useRef } from 'react';
import { Alert, Box, Container, Grid, Typography } from '../ui';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import { SignUpSuccess } from '../auth/SignUpSuccess';
import axios from 'axios';
import { SignUpInputs } from '../auth/SignUpInputs';
import { userTrack } from '../util/tracking';
import firebase from 'firebase/app';
import { SignUpJourneyDescription } from './SignUpJourneyDescription';
import { blue } from '@mui/material/colors';

const useStyles = makeStyles()((theme) => ({
    info: {
        width: '82%',
        margin: '0 auto',
        marginTop: 50,
        display: 'flex',
        flexFlow: 'column',
    },
    heading: {
        fontSize: 'min(2.5em, 5vw)',
        textAlign: 'center',
    },
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 'auto',
        maxWidth: '600px',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    alreadyHaveAccout: {
        margin: theme.spacing(1, 0, 2),
    },
    join: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'flex-end',
        color: theme.palette.primary.main,
        padding: '10px 20px',
        borderRadius: '2px',
        cursor: 'pointer',
        marginTop: '10px',
        backgroundColor: blue[700],
        '&:hover': {
            backgroundColor: blue[800],
        },
    },
    conditions: {
        fontSize: '0.9em',
        color: '#a5a5a5',
        marginTop: '50px',
    },
}));

export const Step4Optional = ({
    updateOnBoarding = async () => console.log('update-on-boarding function'),
}) => {
    const formRef = useRef();
    const { classes } = useStyles();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('');
    const [password, setPassword] = useState('');
    const [survey, setSurvey] = useState('');
    const [clientCode, setClientCode] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [accountCreated, setAccountCreated] = useState(false);
    const [error, setError] = useState('');

    const createAccount = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (firstName && lastName && email && country) {
            setError('');
            try {
                if (password === confirmPassword) {
                    const userData = await firebase
                        .auth()
                        .createUserWithEmailAndPassword(email, password);

                    let res = await axios.post(`/api/users/thinkific`, {
                        email: userData.user.email,
                        firstName,
                        lastName,
                    });

                    let thinkificUserId = res.data;

                    const {
                        data: { user },
                    } = await axios.post(`/api/users`, {
                        user_email: userData.user.email,
                        user_firstName: firstName,
                        user_lastName: lastName,
                        user_country: country,
                        company_code: clientCode,
                        user_thinkific_id: thinkificUserId,
                        heard_from: survey,
                    });
                    await axios.post(`/api/send/${email}/send-verification`);
                    const trackingObject = {
                        page_slug: window.location.pathname,
                        activity_type: 'CLICK',
                        activity_value: email,
                    };
                    userTrack('sign-in', trackingObject);
                    const { success } = await updateOnBoarding(user?._id);
                    if (success) {
                        console.log('updated-on-boarding');
                    }
                    setAccountCreated(true);
                } else {
                    setError("Passwords don't match");
                }
            } catch (e) {
                console.log(e);
                setError(e.message);
                setLoading(false);
            }
        } else {
            setError('Please Fill All Fields');
        }
        setLoading(false);
    };

    return (
        <>
            <Container maxWidth="xl">
                <Grid container justifyContent="center" alignItems="flex-start">
                    <Grid item xs={12} sm={6}>
                        {accountCreated ? (
                            <SignUpSuccess />
                        ) : (
                            <div className={classes.paper}>
                                <Typography
                                    color="secondary"
                                    style={{ paddingTop: '10px' }}
                                    variant="h3"
                                    gutterBottom
                                    className={classes.heading}
                                >
                                    We're done! <br /> Register for free to
                                    receive your detailed roadmap.
                                </Typography>
                                {error && (
                                    <Box p={2}>
                                        <Alert severity="error">{error}</Alert>
                                    </Box>
                                )}
                                <SignUpInputs
                                    formRef={formRef}
                                    firstName={firstName}
                                    setFirstName={setFirstName}
                                    lastName={lastName}
                                    setLastName={setLastName}
                                    email={email}
                                    setEmail={setEmail}
                                    password={password}
                                    setPassword={setPassword}
                                    confirmPassword={confirmPassword}
                                    setCountry={setCountry}
                                    country={country}
                                    setSurvey={setSurvey}
                                    survey={survey}
                                    setConfirmPassword={setConfirmPassword}
                                    createAccount={createAccount}
                                    loading={loading}
                                    setClientCode={setClientCode}
                                    clientCode={clientCode}
                                />
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SignUpJourneyDescription />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default Step4Optional;
