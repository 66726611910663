import {
    AccessibilityNewIcon,
    AccountCircleIcon,
    AssessmentIcon,
    BuildIcon,
    Brightness4Icon,
    BusinessIcon,
    CodeIcon,
    ContactsIcon,
    DashboardIcon,
    FaceIcon,
    FlareIcon,
    FlightTakeoffIcon,
    HelpIcon,
    InboxIcon,
    InsertChartIcon,
    LaptopChromebookIcon,
    ListAltIcon,
    LiveTvIcon,
    MicIcon,
    PaymentIcon,
    PeopleIcon,
    SchoolIcon,
    StarBorderIcon,
    SupervisedUserCircleIcon,
    TrendingUpIcon,
} from '../icons';

export const couponRoute = {
    title: 'Coupons',
    icon: FaceIcon,
    path: '/udemy-coupon-codes',
    isPublic: true,
    hideSideNav: true,
};

/* export const authorSignupRoute = {
    title: 'Author Signup',
    icon: FaceIcon,
    path: '/author-signup',
    isPublic: true,
    hideSideNav: true,
}; */

export const adminRoute = {
    title: 'Admin',
    icon: FaceIcon,
    path: '/admin',
};

export const billingRoute = {
    title: 'Billing',
    icon: PaymentIcon,
    path: '/billing',
};

export const certificationsRoute = {
    title: 'Certifications',
    icon: SchoolIcon,
    path: '/certifications',
    isPublic: true,
};

export const challengesRoute = {
    title: 'Challenges',
    icon: ListAltIcon,
    path: '/challenges',
    releaseDate: 'Spring 2021',
};

export const communityRoute = {
    title: 'Community',
    icon: SupervisedUserCircleIcon,
    path: 'https://forums.starweaver.com/categories',
    isExternalLink: true,
};

export const constellationsRoute = {
    title: 'Constellations',
    icon: FlareIcon,
    path: 'https://forums.starweaver.com/category/2/constellations',
    releaseDate: 'Spring 2021',
    isExternalLink: true,
};

/* export const coursePaywallRoute = {
    title: 'Course Paywall',
    path: '/courses/:title',
    isPublic: true,
    hideSideNav: true,
}; */

export const coursesRoute = {
    title: 'On Demand',
    icon: LaptopChromebookIcon,
    path: '/courses',
    isPublic: true,
};

export const coursesDetailRoute = {
    title: 'On Demand Course Detail',
    path: '/courses/:productId',
    isPublic: true,
};

export const dashboardRoute = {
    title: 'Dashboard',
    icon: DashboardIcon,
    path: '/profile/dashboard',
};

export const datasetsRoute = {
    title: 'Datasets',
    icon: AssessmentIcon,
    releaseDate: 'Spring 2021',
    path: '/datasets',
};

export const faqsRoute = {
    title: 'Support & FAQs',
    icon: HelpIcon,
    path: 'https://support.starweaver.com/portal/en/home',
    isExternalLink: true,
};

export const firstTimeUserRoute = {
    title: 'First Time Users',
    icon: AccessibilityNewIcon,
    path: '/first-time',
};

export const forBusiness = {
    title: 'Starweaver for Business',
    icon: BusinessIcon,
    path: `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/free-enterprise-trial`,
    isExternalLink: false,
};

export const forOrganisation = {
    title: 'For Organisation',
    icon: FlightTakeoffIcon,
    path: '/organisation',
    isPublic: true,
    hideSideNav: true,
};

export const globalAccessPage = {
    title: 'Global Access page',
    icon: null,
    path: '/globalaccesspage',
};

export const meetTheGurusRoute = {
    title: 'Meet the Gurus',
    icon: PeopleIcon,
    path: `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/meet-the-gurus-events/`,
    isExternalLink: true,
    isPublic: true,
    hideSideNav: true,
};

export const migrationSignUpRoute = {
    title: 'Migration',
    icon: '',
    path: '/migration',
    isPublic: true,
    hideSideNav: true,
};

export const howItWorksRoute = {
    title: 'How It Works',
    icon: BuildIcon,
    path: '/how-it-works',
    isPublic: true,
};

// export const instructorApplicationSubmissionsRoute = {
//     title: 'Instructor Application Submissions',
//     icon: FaceIcon,
//     path: '/admin/instructors/submissions',
// };

export const instructorDashboard = {
    title: 'Instructor Dashboard',
    path: '/profile/instructor-dashboard',
};

export const instructorSignUp = {
    title: 'Teach With Starweaver - Signup',
    path: '/instructor-signup',
    isPublic: true,
    // hideSideNav: true,
};

export const instructorLogin = {
    title: 'instructor Login In',
    path: '/instructor-login',
    isPublic: true,
    // hideSideNav: true,
};

export const instructorOnboarding = {
    title: 'Teach With Starweaver - Instructor Onboarding',
    icon: '',
    path: '/instructor-onboarding',
};
export const instructorSubmissionMessage = {
    title: 'submissionMessage',
    icon: '',
    path: '/profile/submission-message',
};

/* export const instructorOnboardingSignUp = {
    title: 'InstructorOnboardingSignUp',
    icon: '',
    path: '/instructor-onboarding-sign-up',
    isPublic: true,
}; */

export const journeysRoute = {
    title: 'Journeys',
    icon: TrendingUpIcon,
    path: '/journeys',
};

export const journeyDetailRoute = {
    title: 'Journey',
    path: '/journeys/:slug',
    isPublic: true,
};

export const learnPressRoute = {
    title: 'Work shops',
    icon: TrendingUpIcon,
    path: '/lp-courses/:name',
};

export const liveLabsRoute = {
    title: 'Channels',
    icon: ContactsIcon,
    path: '/live-labs',
    exact: true,
    isPublic: true,
};

export const landingPageRoute = {
    title: 'Starweaver - Learning | Doing | Connecting',
    path: '/',
    // path: '/landing',
    exact: true,
    isPublic: true,
};

export const liveLabsDetailRoute = {
    title: 'LiveLabs Detail',
    path: '/live-labs/:courseSlug',
    isPublic: true,
};

export const loginRoute = {
    title: 'Login',
    path: '/login',
    isPublic: true,
};

export const moonshotsRoute = {
    title: 'MoonShots',
    icon: Brightness4Icon,
    path: '/moonshots',
    releaseDate: 'Spring 2021',
};

export const myStandingRoute = {
    title: 'My Standing',
    icon: InsertChartIcon,
    path: '/my-standing',
};

export const onAirRoute = {
    title: 'On Air',
    icon: LiveTvIcon,
    path: '/on-air',
    exact: true,
    isPublic: true,
};

export const onAirDetailRoute = {
    title: 'On Air Detail',
    path: '/on-air/:courseSlug',
    isPublic: true,
};

export const onboardingRoute = {
    title: 'Onboarding Flow',
    icon: FlightTakeoffIcon,
    path: '/onboarding',
};

export const plansRoute = {
    title: 'Plans Pricing',
    icon: PaymentIcon,
    path: '/pricing',
    isPublic: true,
};

export const comparePlansRoute = {
    title: 'Compare Plans',
    icon: PaymentIcon,
    path: '/pricing/compare',
    isPublic: true,
};

export const podcastRoute = {
    title: 'Podcasts',
    icon: MicIcon,
    path: `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/meet-the-gurus-podcast`,
    isExternalLink: true,
};

export const productCheckoutRoute = {
    title: 'Checkout',
    icon: PaymentIcon,
    path: '/checkout',
};

export const profileRoute = {
    title: 'My Profile',
    icon: AccountCircleIcon,
    path: '/profile',
};

export const profileBillingRoute = {
    title: 'Account Billing',
    path: '/profile/billing',
};

export const profileDataPrivacyRoute = {
    title: 'Data Privacy',
    path: '/profile/userdata',
};

export const profileNotificationsRoute = {
    title: 'Account Billing',
    path: '/profile/notifications',
};

export const profilePersonalInfoRoute = {
    title: 'Personal Info',
    path: '/profile/information',
};

export const profileSecurityRoute = {
    title: 'Account Billing',
    path: '/profile/security',
};

export const resetPasswordRoute = {
    title: 'Reset Password',
    path: '/reset-password',
    isPublic: true,
    hideSideNav: true,
};

export const myTrainingRoute = {
    title: 'My Training',
    path: '/profile/training',
};

export const searchRoute = {
    title: 'Search',
    icon: '',
    path: '/search',
};

export const starLabsDetailsPage = {
    title: 'Star Labs Details Page',
    icon: '',
    path: '/star-labs:courseId',
};

export const sandboxesRoute = {
    title: 'Sandboxes',
    icon: InboxIcon,
    path: '/sandboxes',
    releaseDate: 'Spring 2021',
};

export const signupRoute = {
    title: 'Sign up',
    path: '/signup',
    isPublic: true,
    // hideSideNav: true,
};

export const starSystemsRoute = {
    title: 'Star Systems',
    icon: StarBorderIcon,
    path: 'https://forums.starweaver.com/category/1/star-systems',
    releaseDate: 'Spring 2021',
    isExternalLink: true,
};

export const checkoutSuccessRoute = {
    title: 'Checkout Success',
    path: '/checkout/success',
};

export const checkoutCancledRoute = {
    title: 'Checkout Cacncled',
    path: '/checkout/canceled',
};

export const techbytesArticleRoute = {
    title: 'Techbytes Article',
    path: '/techbytes-article/:title',
    isPublic: true,
    hideSideNav: true,
};

export const techbytesRoute = {
    // title: 'TechBytes',
    // icon: CodeIcon,
    // path: '/techbytes',
    // isPublic: true,
    // hideSideNav: true,
    title: 'Techbytes',
    icon: CodeIcon,
    path: `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/techbytes-online-technology-blog`,
    isExternalLink: true,
    isPublic: true,
};

export const trainingRoute = {
    title: 'Training',
    icon: TrendingUpIcon,
    path: '/training',
};
