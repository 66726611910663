import { useRecoilState } from 'recoil';
import { RadioSelect } from './RadioSelect';
import { designationLabelsAndValues, yesAndNoLabelsAndValues } from './data';
import {
    isInterestedInLearningWithGroupState,
    selectedDesignationState,
} from './recoil';
import { Box, Typography } from '../ui';

/* const useStyles = makeStyles()((theme) => ({
    root: {},
})); */

export const Step1 = () => {
    //const { classes } = useStyles();

    const [selectedDesignation, setSelectedDesignation] = useRecoilState(
        selectedDesignationState
    );
    const [
        isInterestedInLearningWithGroup,
        setIsInterestedInLearningWithGroup,
    ] = useRecoilState(isInterestedInLearningWithGroupState);

    const onDesignationChange = (e) => {
        setSelectedDesignation(e.target.value);
    };

    const onIsInterestedInLearningWithGroupChange = (e) => {
        setIsInterestedInLearningWithGroup(e.target.value);
    };

    return (
        <>
            <Box mt={1} mb={3}>
                <Typography
                    style={{
                        fontSize: 'min(2em, 6vw)',
                    }}
                    variant="h4"
                    gutterBottom
                >
                    This brief, 3-minute survey helps us build your personalized
                    learning roadmap (a "Journey")
                </Typography>
                <Typography variant="subtitle2">
                    All information is kept confidential and only used for your
                    education.
                </Typography>
            </Box>
            <RadioSelect
                heading="Where are you in your career?"
                labelsAndValues={designationLabelsAndValues}
                selectedValue={selectedDesignation}
                onChange={onDesignationChange}
            />
            <RadioSelect
                heading="I’m interested in learning with others in groups."
                labelsAndValues={yesAndNoLabelsAndValues}
                selectedValue={isInterestedInLearningWithGroup}
                onChange={onIsInterestedInLearningWithGroupChange}
            />
        </>
    );
};
