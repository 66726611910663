import { useNavigate } from 'react-router-dom';
import { Button, Container, Grid, Typography, Modal } from '../ui';
import { makeStyles } from 'tss-react/mui';
import { useUser } from './useUser';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
const useStyles = makeStyles()((theme) => ({
    modalContent: {
        position: 'absolute',
        width: '80%',
        backgroundColor: 'rgb(20, 48, 89)',
        /* border: '2px solid #000',
        boxShadow: theme.shadows[5], */
        padding: theme.spacing(2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',

        [theme.breakpoints.down('md')]: {
            width: 600,
        },
        [theme.breakpoints.down('sm')]: {
            width: 370,
        },
    },
    subHeading: {
        color: '#EEEEEE',
        fontSize: 15,
        padding: '15px 5px ',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    },
    headingText: {
        color: 'white',
        fontWeight: '800',
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    },
    clkIcon: {
        fontSize: 74,
        color: 'white',
    },
    paper: {
        /* marginTop: theme.spacing(4), */
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        /*  maxWidth: '600px', */
        margin: 'auto',
    },

    form: {
        width: '95%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },

    discoverBtn: {
        backgroundColor: '#228b22',
        color: 'white',
        height: 'auto',
        marginRight: 20,
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#228b22',
            textDecoration: 'none',
        },
    },
    linkLabel: {
        color: 'white',
        paddingTop: '8px',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    NoThanks: {
        border: '2px solid white',
        backgroundColor: 'transparent',
        color: 'white',
        height: 'auto',
        marginLeft: 10,
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));
export const FreeSignupPopup = ({ open, onRequestClose = () => {} }) => {
    const { classes } = useStyles();
    const { user } = useUser();
    let navigate = useNavigate();
    const redirectPage = (e, link) => {
        e.stopPropagation();
        onRequestClose();
        navigate(link);
    };

    return (
        <>
            <Modal open={open} onClose={onRequestClose}>
                <div className={classes.modalContent}>
                    <Container disableGutters component="main" maxWidth="md">
                        <div className={classes.paper}>
                            <WatchLaterIcon className={classes.clkIcon} />
                            <Typography
                                className={classes.headingText}
                                variant="h4"
                            >
                                {'Hang on a minute'}
                            </Typography>
                            <div className={classes.form}>
                                <Typography className={classes.subHeading}>
                                    Did you know premium plans connect you to
                                    live instructors and peers? Each have WAY
                                    more content and are the best ways to
                                    advance your career.
                                </Typography>
                                {/* <OtherAuth classes={classes} /> */}

                                <Grid
                                    container
                                    item
                                    xs={12}
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{
                                        paddingBottom: '50px',
                                        gap: '0.9rem',
                                    }}
                                >
                                    <Grid
                                        container
                                        item
                                        direction="row"
                                        justifyContent="center"
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Grid
                                            item
                                            xs={6}
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            style={{ marginTop: 18 }}
                                        >
                                            <Grid item xs={12} align="center">
                                                <Button
                                                    onClick={(e) =>
                                                        redirectPage(
                                                            e,
                                                            '/checkout?p_id=prod_ImbRJJkC1WyWV2&t=0&coupon=OCTOBER50'
                                                        )
                                                    }
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    className={
                                                        classes.discoverBtn
                                                    }
                                                >
                                                    Check-out our premium plans
                                                    (50% Coupon)
                                                </Button>
                                                <label
                                                    className={
                                                        classes.linkLabel
                                                    }
                                                >
                                                    Try it for free 5 days
                                                </label>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            direction="row"
                                            justifyContent="center"
                                            spacing={2}
                                            alignItems="center"
                                        >
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                className={classes.NoThanks}
                                                onClick={(e) =>
                                                    redirectPage(e, '/signup')
                                                }
                                            >
                                                No Thanks, I'll first try the
                                                limited, free plan
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Container>
                </div>
            </Modal>
        </>
    );
};
