import { makeStyles } from 'tss-react/mui';
import { Box, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles()((theme) => ({
    appbar: {
        boxShadow: 'none',
        backgroundColor: 'inherit',
    },
    tabLabel: {
        fontWeight: 'bold',
        // fontSize: '1.1em',
    },
    customTabLabel: {
        '& *': {
            textAlign: 'left !important',
            alignItems: 'flex-start !important',
        },
    },
}));
export const NavigationTab = ({
    collections,
    value,
    setValue,
    basePath,
    vertical,
    minWidth,
    fontSize,
    padding,
    color = 'secondary',
    disabled = false,
}) => {
    const { classes } = useStyles();
    let navigate = useNavigate();
    // STATIC DATA

    const handleChange = (event, newValue) => {
        if (basePath) {
            const section = collections[newValue].slug;
            navigate(`${basePath}/${section}`, {
                state: { noScrollToTop: true },
            });
        } else setValue(newValue);
    };

    return (
        <>
            <Box className={classes.appbar}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    // WE CAN USE THIS IN COLOR IN THE COURSES PAGE TOO
                    textColor={color}
                    variant="scrollable"
                    scrollButtons="auto"
                    orientation={vertical ? 'vertical' : null}
                    indicatorColor={color}
                >
                    {collections.map((collection, index) => {
                        return (
                            <Tab
                                disabled={disabled}
                                className={`${classes.tabLabel} ${
                                    vertical && classes.customTabLabel
                                }`}
                                key={index}
                                label={collection.name}
                                swid={'course-tabs-' + collection.name}
                                style={{
                                    display: collection.hideTab
                                        ? 'none'
                                        : 'initial',

                                    ...(minWidth ? { minWidth } : {}),
                                    ...(fontSize ? { fontSize } : {}),
                                    ...(padding ? { padding } : {}),
                                }}
                            />
                        );
                    })}
                </Tabs>
            </Box>
        </>
    );
};
