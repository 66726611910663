import { Link } from 'react-router-dom';
// import styled from 'styled-components';
import ProfilePicture from './ProfilePicture';
import { Box } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    ProfileContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1.5rem',
        gap: '0.5rem',
        '& p': {
            padding: '0',
            margin: '0',
            fontSize: '1rem',
            lineHeight: '1.5rem',
        },
        [theme.breakpoints.up('md')]: {
            '& p': {
                fontSize: '1.125rem',
                lineHeight: '1.75rem',
            },
        },
    },
}));

const ProfileWrapper = (props) => {
    const { classes } = useStyles();
    const { instructorSlug, instructorPic, instructorName } = props;
    return (
        <div className={classes.ProfileContainer}>
            <ProfilePicture
                alt="test"
                src={instructorPic}
                size="LARGE"
                name={instructorName}
            />
            <Link to={instructorSlug}>
                <p>{instructorName}</p>
            </Link>
        </div>
    );
};

// const ProfileContainer = styled.div`
//     display: flex;
//     align-items: center;
//     margin-bottom: 1.5rem;
//     gap: 0.5rem;

//     & p {
//         padding: 0;
//         margin: 0;
//         font-size: 1rem;
//         line-height: 1.5rem;

//         @media (min-width: 48em) {
//             font-size: 1.125rem; /* 18px */
//             line-height: 1.75rem;
//         }
//     }
// `;

export default ProfileWrapper;
