import React, { useState } from 'react';
import { Button, IconButton } from '@mui/material';
import { VisibilityRounded } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { CloseIcon } from '../icons';
import { useCurrentUser } from '../my-account/useCurrentUser';

const useStyles = makeStyles()((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    iframeContainer: {
        backgroundColor: 'transparent',
        width: '70vw',
        height: ' 80vh',
        position: 'relative',
        maxWidth: '65rem',
        maxHeight: '36.75rem',

        [theme.breakpoints.down('md')]: {
            height: '50vh',
            maxHeight: '13.7676875rem',
            width: '95vw',
        },
    },
    closeButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '0.25rem',
        position: 'absolute',
        top: 1,
        right: 15,
        [theme.breakpoints.down('md')]: {
            top: 1,
            right: 0.5,
        },
    },
}));

const NewCoursePreview = (props) => {
    const { wpCourseData } = props;
    const { user: userDetails } = useCurrentUser();
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleWatchPreview = () => {
        console.log('clicked', wpCourseData?.previewVideo);
        handleOpen();
    };

    const htmlDecode = (input) => {
        var e = document.createElement('textarea');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
    };

    return (
        <React.Fragment>
            <Button
                style={{
                    backgroundColor: '#ffff00',
                    color: '#000',
                    padding: !userDetails && '1rem',
                    borderRadius: !userDetails && '0.2rem',
                    minWidth: !userDetails && '15.5rem',
                }}
                variant="contained"
                color="secondary"
                startIcon={<VisibilityRounded />}
                onClick={handleWatchPreview}
            >
                WATCH PREVIEW
            </Button>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                // BackdropComponent={Backdrop}
                // BackdropProps={{
                //     timeout: 500,
                // }}
            >
                <Fade in={open}>
                    <div className={classes.iframeContainer}>
                        <div className={classes.closeButtonContainer}>
                            <IconButton onClick={handleClose} size="small">
                                <CloseIcon
                                    fontSize="small"
                                    style={{
                                        color: 'gray',
                                        fontWeight: 'bold',
                                    }}
                                />
                            </IconButton>
                        </div>
                        {wpCourseData?.previewVideo
                            .split(',')
                            .map((link, index) => {
                                return (
                                    <iframe
                                        src={
                                            htmlDecode(link) +
                                            '&autoplay=1&muted=0'
                                        }
                                        autoplay={true}
                                        frameborder="0"
                                        allow="autoplay; fullscreen; picture-in-picture"
                                        allowfullscreen
                                        title={`Preview -${index + 1}`}
                                        height="100%"
                                        width="100%"
                                    />
                                );
                            })}
                    </div>
                </Fade>
            </Modal>
        </React.Fragment>
    );
};

export default NewCoursePreview;
