import { atom } from 'recoil';

export const coursesSectionState = atom({
    key: 'coursesSectionState',
    default: {},
});

export const bundleCoursesSectionState = atom({
    key: 'bundleCoursesSectionState',
    default: {},
});

export const journeySectionState = atom({
    key: 'journeySectionState',
    default: {},
});

export const techbyteSectionTab = atom({
    key: 'techbyteSectionTab',
    default: {},
});
