import { useTheme } from '@mui/material/styles';
import { Box, Drawer, IconButton, Typography } from '../ui';
import { useStyles } from './styles';
import { CloseIcon } from '../icons';
import { CommentSection } from './CommentSection';

const drawerWidth = 360;

export const CommentsSideDrawer = ({
    showDrawer,
    onToggleDrawer,
    courseId,
    courseName,
    setCommentsLength = () => console.log('drawer-def'),
    courseSlug,
    mentionables,
    placeholder,
    tasks,
    teams,
    tasksOutputTemplate,
    visibilityOptions,
    defaultVisibility,
    clientId,
}) => {
    const container =
        window !== undefined ? () => window.document.body : undefined;
    const theme = useTheme();
    const { classes } = useStyles();

    return (
        <Drawer
            container={container}
            variant="persistent"
            anchor={theme.direction === 'rtl' ? 'left' : 'right'}
            open={showDrawer}
            onClose={onToggleDrawer}
            className={classes.commentsDrawerContainer}
            ModalProps={{
                keepMounted: true,
            }}
        >
            <Box p={2} mt={10} width={drawerWidth}>
                <Box
                    display="flex"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography variant="h6">Comments</Typography>
                    <IconButton onClick={onToggleDrawer} size="large">
                        <CloseIcon />
                    </IconButton>
                </Box>

                <CommentSection
                    courseId={courseId}
                    courseName={courseName}
                    setCommentsLength={setCommentsLength}
                    courseSlug={courseSlug}
                    mentionables={mentionables}
                    placeholder={placeholder}
                    tasks={tasks}
                    teams={teams}
                    tasksOutputTemplate={tasksOutputTemplate}
                    visibilityOptions={visibilityOptions}
                    defaultVisibility={defaultVisibility}
                    clientId={clientId}
                />
            </Box>
        </Drawer>
    );
};
