import { atom, selector } from 'recoil';

export const commentState = atom({
    key: 'commentState',
    default: null,
});

export const addReplySelector = selector({
    key: 'addReplySelector',
    set: ({ set, get }, { commentId, replyObject }) => {
        const comments = [...get(commentState)];
        const newComments = comments.map((comment) => {
            if (comment._id === commentId) {
                const { replies, ...rest } = comment;
                const newReplies = [replyObject, ...replies];
                return {
                    ...rest,
                    replies: newReplies,
                };
            } else {
                return comment;
            }
        });
        set(commentState, newComments);
    },
});

export const deleteReplySelector = selector({
    key: 'deleteReplySelector',
    set: ({ set, get }, { commentId, replyId }) => {
        const comments = [...get(commentState)];
        const newComments = comments.map((comment) => {
            if (comment._id === commentId) {
                const { replies, ...rest } = comment;
                const newReplies = replies.filter(
                    (reply) => reply._id !== replyId
                );
                return {
                    ...rest,
                    replies: newReplies,
                };
            } else {
                return comment;
            }
        });
        set(commentState, newComments);
    },
});

export const addCommentToSelector = selector({
    key: 'addCommentToSelector',
    set: ({ set, get }, comment) => {
        const comments = [...get(commentState)];
        const newComments = [comment, ...comments];
        set(commentState, newComments);
    },
});

export const deleteCommentSelector = selector({
    key: 'deleteCommentSelector',
    set: ({ set, get }, commentId) => {
        const comments = [...get(commentState)];
        const newComments = comments.filter(
            (comment) => comment._id !== commentId
        );
        set(commentState, newComments);
    },
});
