import { useMemo } from 'react';
// import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import {
    GridSectionLayout,
    SectionContainer,
    SectionHeading,
    SectionText,
} from '../custom-components/styled-components';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    CourseSummayBox: {
        order: '1',
        [theme.breakpoints.up('md')]: { order: '2' },
    },
    SummayHeader: {
        display: 'flex',
        justifyContent: 'space-around',
        borderTop: '0.05rem solid #9ba2af',
        borderBottom: '0.05rem solid #9ba2af',
    },
    StatsBox: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '30%',
        textAlign: 'center',
        paddingBlock: '0.5rem',
        '& > span': {
            fontSize: '1.25rem',
            lineHeight: '1.5rem',
        },
    },
    CourseIncludeBox: {
        '& p, & ul, & li': {
            fontWeight: '400',
            fontSize: '1.125rem',
            lineHeight: '1.75rem',
        },
        '& p': {
            marginBottom: '0',
        },
        '& ul': {
            marginTop: '0.8rem',
        },
        [theme.breakpoints.up('md')]: {
            '& p, & ul, & li': {
                fontSize: '1.25rem',
                lineHeight: '1.75rem',
                paddingLeft: '0.5rem',
            },
            '& li': {
                marginLeft: '1.5rem',
                padding: '0',
            },
        },
    },
    OverviewSectionContainer: {
        margin: '3rem 0rem 0rem 0rem',
        paddingInline: '0.5rem',
        [theme.breakpoints.up('md')]: {
            padding: '0',
        },
    },
    OverviewGridLayout: { padding: '0', margin: '0', gap: '2.5rem' },
    OrderedSectionText: {
        order: '2',
        margin: '0',
        fontWeight: '400',
        lineHeight: '2rem',
        [theme.breakpoints.up('md')]: { order: '1' },
    },
}));

const CourseOverview = (props) => {
    const { classes } = useStyles();

    const { wpCourseData, hideCourseCard = false } = props;

    const description = useMemo(() => {
        return ReactHtmlParser(wpCourseData?.description);
    }, [wpCourseData?.description]);

    const studentCount = useMemo(() => {
        return wpCourseData?.noOfStudents;
    }, [wpCourseData?.noOfStudents]);

    const rating = useMemo(() => {
        return wpCourseData?.rating;
    }, [wpCourseData?.rating]);

    const courseCard = useMemo(() => {
        return ReactHtmlParser(
            wpCourseData?.courseCard || wpCourseData?.journeyCard
        );
    }, [wpCourseData?.courseCard, wpCourseData?.journeyCard]);

    return (
        <SectionContainer className={classes.OverviewSectionContainer}>
            <SectionHeading>Overview</SectionHeading>

            <GridSectionLayout
                col={!hideCourseCard ? '2fr 1fr' : '1fr'}
                className={classes.OverviewGridLayout}
            >
                <SectionText className={classes.OrderedSectionText}>
                    {description}
                </SectionText>

                {!hideCourseCard && (
                    <div className={classes.CourseSummayBox}>
                        <div className={classes.SummayHeader}>
                            <div className={classes.StatsBox}>
                                <span>{studentCount}</span>
                                <span>
                                    STUDENTS<sup>&#x2a;</sup>
                                </span>
                            </div>
                            <div className={classes.StatsBox}>
                                <span>{rating}</span>
                                <span>
                                    RECOMMEND<sup>&#x2a;</sup>
                                </span>
                            </div>
                        </div>

                        <div className={classes.CourseIncludeBox}>
                            {courseCard}
                        </div>
                    </div>
                )}
            </GridSectionLayout>
        </SectionContainer>
    );
};

// const OverviewSectionContainer = styled(SectionContainer)`
//     margin: 3rem 0rem 0rem 0rem;
//     padding-inline: 0.5rem;

//     @media (min-width: 48em) {
//         padding: 0;
//     }
// `;

// const OverviewGridLayout = styled(GridSectionLayout)`
//     padding: 0;
//     margin: 0;
//     gap: 2.5rem;
// `;

// const OrderedSectionText = styled(SectionText)`
//     order: 2;
//     margin: 0;
//     font-weight: 400;
//     line-height: 2rem;
//     @media (min-width: 48em) {
//         order: 1;
//     }
// `;

// const CourseSummayBox = styled.div`
//     order: 1;

//     @media (min-width: 48em) {
//         order: 2;
//     }
// `;

// const SummayHeader = styled.div`
//     display: flex;
//     justify-content: space-around;
//     border-top: 0.05rem solid #9ba2af;
//     border-bottom: 0.05rem solid #9ba2af;
// `;

// const StatsBox = styled.div`
//     display: flex;
//     flex-direction: column;
//     flex-basis: 30%;
//     text-align: center;
//     padding-block: 0.5rem;
//     & > span {
//         font-size: 1.25rem;
//         line-height: 1.5rem;
//     }
// `;

// const CourseIncludeBox = styled.div`
//     & p,
//     & ul,
//     & li {
//         font-weight: 400;
//         font-size: 1.125rem;
//         line-height: 1.75rem;

//         @media (min-width: 48em) {
//             font-size: 1.25rem;
//             line-height: 1.75rem;
//             padding-left: 0.5rem;
//         }
//     }

//     & li {
//         @media (min-width: 48em) {
//             margin-left: 1.5rem;
//             padding: 0;
//         }
//     }

//     & p {
//         margin-bottom: 0;
//     }

//     & ul {
//         margin-top: 0.8rem;
//     }
// `;

export default CourseOverview;
