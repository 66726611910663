import MuiAlert from '@mui/material/Alert';
import { Snackbar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useRecoilState } from 'recoil';
import { alertState } from './recoil';

const useStyles = makeStyles()((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackbarAlert = () => {
    const { classes } = useStyles();
    const [alert, setAlert] = useRecoilState(alertState);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert((prev) => {
            return {
                show: false,
                severity: prev.severity,
                message: '',
            };
        });
    };

    return (
        <div className={classes.root}>
            <Snackbar
                open={alert.show}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <div>
                    <Alert onClose={handleClose} severity={alert.severity}>
                        {alert.message}
                    </Alert>
                </div>
            </Snackbar>
        </div>
    );
};

export default SnackbarAlert;
