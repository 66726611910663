import { useIsAdmin } from '../admin/useIsAdmin';
import { PrivateCourseTab } from '../category-landing-page/PrivateCourseTab';
import { useBundleCourses } from '../category-landing-page/useBundleCourses';
import { makeStyles } from 'tss-react/mui';
import PrivateCourseImg from '../img/private_course.svg';
import { Loading } from '../ui';

const useStyles = makeStyles()((theme) => ({
    noPrivateCourseContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'column',
        '& > p': {
            margin: 0,
            fontWeight: '400',
            fontSize: '1.5rem',
            color: 'gray',
        },
    },
    imgContainer: {
        width: '100%',
        maxWidth: '210px',
        '& > img': {
            width: '100%',
            height: 'auto',
            aspectRatio: '1/1',
        },
    },
}));

const DashboardPrivateCourses = ({ user }) => {
    const { client } = useIsAdmin();
    const { classes } = useStyles();

    const {
        courses: privateCourses,
        isLoading: isLoadingPrivateCourses,
    } = useBundleCourses(client?.bundleId);

    // if (isLoadingPrivateCourses) return <Loading />;

    if (isLoadingPrivateCourses || privateCourses.length === 0) {
        return (
            <div className={classes.noPrivateCourseContainer}>
                <div className={classes.imgContainer}>
                    <img
                        src={PrivateCourseImg}
                        alt="private course"
                        height={200}
                        width={200}
                    />
                </div>
                <p>No private courses available!!!</p>
            </div>
        );
    }

    return (
        <div>
            <PrivateCourseTab
                courses={privateCourses}
                isLoading={isLoadingPrivateCourses}
                user={user}
            />
        </div>
    );
};

export default DashboardPrivateCourses;
