import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CoursesListItem } from './CoursesListItem';
import { CoursesListItemSkeleton } from './CoursesListItemSkeleton';

const CoursesList = ({ courses, isLoading, hours, courseMin, courseHours }) => {
    let navigate = useNavigate();

    const onClickCourse = async (slug, title, productableId) => {
        navigate(`/courses/${slug}`);
    };

    const getDuration = (id) => {
        let hrs = courseHours.get(id);
        let min = courseMin.get(id);
        if (hrs != undefined) {
            let duration = '';
            if (parseInt(courseHours.get(id)) != 0) {
                duration = hrs > 1 ? hrs + ' hours ' : hrs + ' hour ';
            }
            if (parseInt(min) != 0) {
                duration += min + ' minutes';
            }
            return duration;
        }
    };

    return (
        <>
            {!isLoading
                ? courses.map((course, i) => (
                      <CoursesListItem
                          key={i}
                          xs={12}
                          md={4}
                          lg={3}
                          onClickCourse={onClickCourse}
                          slug={course.slug}
                          thumbnail={course.card_image_url}
                          title={course.name}
                          instructorName={course.instructor_names}
                          level={course.level}
                          description={course.description}
                          duration={
                              courseHours
                                  ? getDuration(String(course.productable_id))
                                  : ''
                          }
                          prodId={course.productable_id}
                      />
                  ))
                : Array(6)
                      .fill()
                      .map(() => (
                          <CoursesListItemSkeleton xs={12} md={4} lg={3} />
                      ))}
        </>
    );
};

export default CoursesList;
