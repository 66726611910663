import { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import {
    Avatar,
    Box,
    Button,
    CustomSnackbar,
    TextField,
    Typography,
    IconButton,
} from '../ui';
import { useStyles } from './styles';
import { AccountCircleIcon } from '../icons';
import { defaultColorArray } from '../util';
import { CKEditor } from 'ckeditor4-react';
import Fuse from 'fuse.js';
import {
    mentionsOutputTemplate,
    mentionsItemTemplateNameOnly,
    tasksItemTemplate,
    teamsItemTemplate,
    teamsOutputTemplate,
    requirementsOutputTemplate,
    requirementsItemTemplate,
} from './templates';
import { VisibilityButton } from '../ui';
import { visibilities } from '../util/visibility';
import { CommentAttachment } from './CommentAttachment';
import { useDispatch, useSelector } from 'react-redux';
import {
    setCommentId,
    reset,
    setType,
    COMMENT,
} from './slices/commentAttachmentSlice';
import { useFuse } from '../util';
import ReactHTMLParser from 'react-html-parser';
import { UserDisplay } from './UserDisplay';
import { CloseIcon } from '../icons';
import FeedCardHeader from '../admin/components/FeedsAndActivity/FeedCardHeader';
import UserImg from '../img/user.png';
// import { useRecoilState } from 'recoil';
// import { feedToReplyToState } from '../admin/recoil/state';

export const CommentsInput = ({
    // helps to fire in/out of the view of user to clean up things
    user,
    finalFunction = async () => {
        console.log('default-final-function');
    },
    blockAccess = () => console.log('block-access'),
    small = false,
    label = 'Your comment',
    placeholder = 'What are your thought?',
    buttonText = 'Comment',
    closeComment = () => console.log('default'),
    mentionables = [],
    tasks = [],
    teams = [],
    tasksOutputTemplate,
    // showCkEditor = false,
    showEditor,
    setShowEditor,
    visibilityOptions,
    defaultVisibility = visibilities.EVERYONE.visibility,
    clientId,
    handleCloseEdit = undefined,
    existingCommentText = '',
    loading = false,
    showCommentAttachments = false,
    commentToReply,
    setCommentToReply,
    feedToReplyTo,
    setFeedToReplyTo,
    hideCourseTagging = false,
}) => {
    const [open, setOpen] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [comment, setComment] = useState('');
    const { classes } = useStyles();
    const editorInstance = useRef({});
    const commentAttachments = useSelector((state) => state.commentAttachments);
    // const [showEditor, setShowEditor] = useState(showCkEditor);
    const dispatch = useDispatch();
    const [selectedVisbilityOption, setSelectedVisbilityOption] = useState(
        defaultVisibility
    );
    // const [feedToReplyTo, setFeedToReplyTo] = useRecoilState(
    //     feedToReplyToState
    // );

    useEffect(() => {
        if (commentAttachments.closeEditor) {
            setIsProcessing(false);
            dispatch(reset());
            setShowEditor(false);

            if (handleCloseEdit) {
                handleCloseEdit();
            }
        }
    }, [commentAttachments.closeEditor, dispatch, handleCloseEdit]);

    // console.log(buttonText, mentionables);
    const [selectedUsers, setSelectedUsers] = useState([]);
    // console.log(selectedUsers);

    const userName = useMemo(() => {
        if (user) {
            if (user.user_firstName || user.user_lastName) {
                return `${user.user_firstName} ${user.user_lastName}`;
            } else if (
                user.display_name &&
                user.display_name?.toLowerCase() !==
                    'Starweaver User'.toLowerCase()
            ) {
                return user.display_name;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }, [user]);

    const avatar = useMemo(() => {
        let stylesSmall = {};
        if (small) {
            stylesSmall = {
                width: '32px',
                height: '32px',
            };
        }
        const color = user
            ? defaultColorArray[
                  (user._id.charCodeAt(0) +
                      user._id.charCodeAt(user._id.length - 1)) %
                      defaultColorArray.length
              ]
            : defaultColorArray[1];
        if (user?.user_url) {
            return (
                <Avatar
                    style={{ ...stylesSmall }}
                    src={user.user_url || user.profilePicUrlWP}
                    alt="user_profile"
                />
            );
        }
        if (userName !== '') {
            return (
                <Avatar
                    style={{
                        backgroundColor: color,
                        ...stylesSmall,
                    }}
                    src={user.user_url || user.profilePicUrlWP}
                >
                    {userName.charAt(0).toUpperCase()}
                </Avatar>
            );
        }
        return (
            <Avatar
                style={{
                    backgroundColor: color,
                    ...stylesSmall,
                }}
                src={user?.user_url || user?.profilePicUrlWP || UserImg}
            >
                <AccountCircleIcon fontSize={small ? 'small' : 'default'} />
            </Avatar>
        );
    }, [user, userName, small]);

    useEffect(() => {
        if (!open) {
            setOpen(false);
            setIsProcessing(false);
            setComment('');
            setName('');
        }
    }, [open]);

    const disableCommentButton = useMemo(() => {
        return comment.length === 0 || isProcessing;
    }, [comment.length, isProcessing]);

    const { fuseSearch: searchMentionables } = useFuse(mentionables, [
        'name',
        'email',
    ]);

    const { fuseSearch: searchTasks } = useFuse(tasks, ['name']);

    const { fuseSearch: searchTeams } = useFuse(teams, ['name']);

    // functions

    /*const handleClickCapture = () => {
        if (user) {
            if (!open) {
                setOpen(true);
            }
        } else {
            blockAccess();
        }
    };*/

    /*const onCancel = () => {
        setOpen(false);
        closeComment();
    };*/

    const extractEmailsFromComment = (comment) => {
        let notesElm = document.createElement('div');
        notesElm.innerHTML = comment;

        let emails = [];
        let emailElms = notesElm.getElementsByClassName('sw-ckeditor-email');
        for (let i = 0; i < emailElms.length; i++) {
            let email = emailElms[i].innerText;
            if (email && email !== '') emails.push(email);
        }
        return [...new Set(emails)];
    };

    const extractTeamIdsFromComment = (comment) => {
        let notesElm = document.createElement('div');
        notesElm.innerHTML = comment;

        let teamIds = [];
        let teamElms = notesElm.getElementsByClassName('sw-ckeditor-team-id');
        for (let i = 0; i < teamElms.length; i++) {
            let teamId = teamElms[i].innerText;
            if (teamId && teamId !== '') teamIds.push(teamId);
        }
        return [...new Set(teamIds)];
    };

    const onComment = async () => {
        if (!isProcessing) {
            if (!user) {
                blockAccess();
                return;
            }
            setIsProcessing(true);
            const emails = extractEmailsFromComment(comment);

            const teamIds = extractTeamIdsFromComment(comment);
            const result = await finalFunction({
                userName,
                name,
                comment,
                emails,
                teamIds,
                visibility: selectedVisbilityOption,
                selectedUsers,
                clientId,
                hasFiles: commentAttachments.isFile,
                commentToReply,
                feedToReplyTo,
            });
            if (result?.error) {
                setError(result.message);
            }
            if (result?.success) {
                setComment('');
                if (editorInstance.current) {
                    editorInstance.current?.editor?.setData('');
                }
                console.log('final-function-executed');
                if (commentAttachments.isFile && result.commentId) {
                    dispatch(setCommentId(result.commentId));
                    dispatch(setType(COMMENT));
                }
            }

            if (commentAttachments.isFile === false) {
                setIsProcessing(false);
                handleToggleEditor();
            }
        }
    };

    const editorConfig = {
        filebrowserUploadMethod: 'form',
        extraPlugins: 'editorplaceholder,mentions,mentionAtomic',
        removeButtons: 'SpecialChar,Source,HorizontalRule,Table,Anchor,About',
        removeDialogTabs: 'image:advanced;image:Link',
        editorplaceholder: placeholder,
        toolbar: [
            {
                name: 'document',
                items: [
                    'Source',
                    '-',
                    'Save',
                    'NewPage',
                    'Preview',
                    'Print',
                    '-',
                    'Templates',
                ],
            },
            {
                name: 'clipboard',
                items: [
                    'Cut',
                    'Copy',
                    'Paste',
                    'PasteText',
                    'PasteFromWord',
                    '-',
                    'Undo',
                    'Redo',
                ],
            },
            {
                name: 'editing',
                items: ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'],
            },
            {
                name: 'forms',
                items: [
                    'Form',
                    'Checkbox',
                    'Radio',
                    'TextField',
                    'Textarea',
                    'Select',
                    'Button',
                    'ImageButton',
                    'HiddenField',
                ],
            },
            {
                name: 'basicstyles',
                items: [
                    'Bold',
                    'Italic',
                    'Underline',
                    'Strike',
                    'Subscript',
                    'Superscript',
                    '-',
                    'CopyFormatting',
                    'RemoveFormat',
                ],
            },
            {
                name: 'paragraph',
                items: [
                    'NumberedList',
                    'BulletedList',
                    '-',
                    'Outdent',
                    'Indent',
                    '-',
                    'Blockquote',
                    'CreateDiv',
                    '-',
                    'JustifyLeft',
                    'JustifyCenter',
                    'JustifyRight',
                    'JustifyBlock',
                    '-',
                    'BidiLtr',
                    'BidiRtl',
                    'Language',
                ],
            },
            { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
            {
                name: 'insert',
                items: [
                    'Image',
                    'Flash',
                    'Table',
                    'HorizontalRule',
                    'Smiley',
                    'SpecialChar',
                    'PageBreak',
                    'Iframe',
                ],
            },
            { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
            { name: 'colors', items: ['TextColor', 'BGColor'] },
            { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
            { name: 'about', items: ['About'] },
        ],
        filebrowserImageUploadUrl: '/api/content/upload-comment-image',
        mentions: [
            {
                feed: function (options, callback) {
                    let query = options.query;
                    let result = searchMentionables(query);
                    callback(result);
                },
                marker: '@',
                minChars: 0,
                // outputTemplate: `<span id="{id}"><a href="#">{name}</a></span>`,
                // outputTemplate: `<span style="display:inline">{name}<span style="display:none">{email}</span></span><p style="display:inline">.</p>`,
                outputTemplate: mentionsOutputTemplate,

                itemTemplate: mentionsItemTemplateNameOnly, // mentionsItemTemplate,

                pattern: /@[\w\s]*/,
                followingSpace: true,
            },
            {
                feed: function (options, callback) {
                    let query = options.query;
                    let result = searchTasks(query);
                    callback(result);
                },
                marker: '/',
                minChars: 0,
                outputTemplate:
                    tasksOutputTemplate ||
                    `<span style="display:inline;font-weight:bold;color:blue">{_name}</span>&nbsp`, // Output only task name without '/'
                // outputTemplate: `<a href="/admin/opportunity-management/details/652705f98a89650678fdf602/logistics" target="_blank" style="display:inline;font-weight:bold;color:blue">{_name}</a>&nbsp`,
                itemTemplate: tasksItemTemplate,

                // outputTemplate: mentionsOutputTemplate,

                // itemTemplate: mentionsItemTemplateNameOnly,

                pattern: /\/[\w\s]*/,
                followingSpace: true,
            },
            {
                feed: function (options, callback) {
                    let query = options.query;
                    let result = searchTeams(query);
                    callback(result);
                },
                marker: '#',
                minChars: 0,
                outputTemplate: teamsOutputTemplate,

                itemTemplate: teamsItemTemplate,
                pattern: /#[\w\s]*/,
                followingSpace: true,
            },
            ...(!hideCourseTagging
                ? [
                      {
                          feed: function (options, callback) {
                              let query = options.query;

                              axios
                                  .get(
                                      `/api/client-requirements/search?q=${query}`
                                  )
                                  .then(function (response) {
                                      if (response.status === 200) {
                                          callback(response.data || []);
                                      }
                                  })
                                  .catch(function (error) {
                                      console.error(
                                          'Error fetching data:',
                                          error
                                      );
                                      callback([]);
                                  });
                          },
                          marker: '~',
                          minChars: 1,
                          outputTemplate: requirementsOutputTemplate,

                          itemTemplate: requirementsItemTemplate,

                          pattern: /~[\w\s]*/,
                          followingSpace: true,
                      },
                  ]
                : []),
            // {
            //     feed: '/tags?name={encodedQuery}',
            //     marker: '#',
            // },
        ],
        // extraAllowedContent: 'p[style] span[style] small[style]',
        allowedContent: true,
    };

    const handleEditorChange = (event) => {
        const currData = event.editor.getData();
        setComment(currData);
    };

    const handleToggleEditor = () => {
        dispatch(reset());
        setShowEditor((prev) => {
            return !prev;
        });

        if (handleCloseEdit) {
            handleCloseEdit();
        }
    };

    const onFeedToReplyClose = () => {
        setFeedToReplyTo(null);
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            className={classes.container}
        >
            {!small && userName && (
                <Box className={classes.name}>
                    <Box mr={1}>{avatar}</Box>
                    {userName ? (
                        <Typography variant="subtitle2">{userName}</Typography>
                    ) : (
                        <TextField
                            margin={small ? 'dense' : 'normal'}
                            fullWidth
                            variant="standard"
                            label="Display Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            color="secondary"
                        />
                    )}

                    <VisibilityButton
                        selectedVisbilityOption={selectedVisbilityOption}
                        setSelectedVisbilityOption={setSelectedVisbilityOption}
                        selectedUsers={selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                        visibilityOptions={visibilityOptions}
                        // clientId={clientId}
                    />
                </Box>
            )}
            {/* <TextField
                className={classes.textField}
                placeholder={placeholder}
                fullWidth
                margin={small ? 'dense' : 'normal'}
                multiline={open ? true : false}
                rows={open ? 4 : 1}
                variant="outlined"
                label={label}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                color="secondary"
                onClickCapture={handleClickCapture}
            /> */}

            {feedToReplyTo && (
                <Box mb={1} className={classes.clientRequirementsActivity}>
                    <FeedCardHeader
                        isInstructorView={!user.isAdmin}
                        row={feedToReplyTo}
                        // selectedRole={selectedRole}
                        // showEditor={showEditor}
                        // handleToggleEditor={handleToggleEditor}
                        // handleHideCkEditor={handleHideCkEditor}
                        hideCollapseBtn
                        hideCommentBtn
                        hideCopyBtn
                        onClose={onFeedToReplyClose}
                    />
                </Box>
            )}
            {commentToReply && (
                <Box mb={1} className={classes.replyToBox}>
                    <Box
                        display="flex"
                        alignItems="flex-start"
                        flexDirection="column"
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            width="100%"
                        >
                            <UserDisplay
                                response={commentToReply}
                                userId={commentToReply.created_by}
                            />
                            <IconButton
                                size="small"
                                onClick={() => setCommentToReply(null)}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Box>
                        <Box
                            ml={2}
                            style={{
                                maxHeight: '120px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 3,
                            }}
                        >
                            <Typography variant="body2" color="textSecondary">
                                {ReactHTMLParser(commentToReply.comment_text)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )}

            {showEditor ? (
                <CKEditor
                    onInstanceReady={({ editor }) => {
                        editorInstance.current = editor;
                        if (existingCommentText)
                            editor.setData(existingCommentText);
                    }}
                    config={editorConfig}
                    data={comment}
                    onChange={handleEditorChange}
                    type="classic"
                />
            ) : (
                <div
                    className={classes.pseduoTextBox}
                    onClick={handleToggleEditor}
                >
                    {placeholder}
                </div>
            )}
            {showCommentAttachments && showEditor && <CommentAttachment />}
            <Box
                className={`${classes.inputActions} ${
                    // open ? classes.animateActionsIn : classes.animateActionsOut
                    true ? classes.animateActionsIn : classes.animateActionsOut
                }`}
            >
                {/* <Box mr={1}>
                    <Button
                        size={small ? 'small' : 'medium'}
                        onClick={onCancel}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                </Box> */}
                {/* <Box mr={1}> */}
                <Box mt={2}>
                    {showEditor && (
                        <Button
                            size={small ? 'small' : 'medium'}
                            onClick={handleToggleEditor}
                            variant="contained"
                            style={{ marginRight: '0.5rem' }}
                            disabled={isProcessing || loading}
                        >
                            Cancel
                        </Button>
                    )}
                    <Button
                        size={small ? 'small' : 'medium'}
                        onClick={onComment}
                        variant="contained"
                        color="secondary"
                        disabled={disableCommentButton || loading}
                    >
                        {loading ? 'Please wait...' : buttonText}
                    </Button>
                </Box>
            </Box>
            <CustomSnackbar
                type="error"
                message={error}
                setMessage={setError}
            />
        </Box>
    );
};
