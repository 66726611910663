import { IconButton, Tooltip } from '../ui';
import { BookmarkBorderIcon, BookmarkIcon } from '../icons';
import { useEffect, useState } from 'react';
import { LoginModal } from '../auth/LoginModal';
import { useSetRecoilState } from 'recoil';
import { addCourseToUser } from '../my-account/Recoil/Selector';
import useMediaQuery from '@mui/material/useMediaQuery';

import axios from 'axios';

export const SaveCourse = ({ user, course }) => {
    const [saved, setSaved] = useState(false);
    const [openLoginModal, setOpenLoginModal] = useState(false);
    const addOrRemoveCourse = useSetRecoilState(addCourseToUser);
    const isSmall = useMediaQuery('(max-width:600px)');

    const saveCourse = async () => {
        if (user) {
            try {
                if (saved) {
                    const {
                        data,
                    } = await axios.put(
                        `/api/users/${user._id}/save-and-remove`,
                        { id: course.id, action: 'unsave' }
                    );
                    // remove courseId to the user state
                    addOrRemoveCourse({ action: 'REMOVE', payload: course.id });
                    console.log('removed');
                } else {
                    const { data } = await axios.put(
                        `/api/users/${user._id}/save-and-remove`,
                        {
                            id: course.id,
                            action: 'save',
                        }
                    );
                    // saved
                    // add courseId to the user state
                    addOrRemoveCourse({ action: 'ADD', payload: course.id });
                    console.log('added');
                }
                setSaved(!saved);
            } catch (err) {
                console.log(err.message);
            }
        } else {
            setOpenLoginModal(true);
        }
    };

    const onLoginModalClose = () => {
        setOpenLoginModal(false);
    };

    useEffect(() => {
        if (user) {
            if (user.saved_courses && user.saved_courses.includes(course.id)) {
                setSaved(true);
            }
        }
    }, [user]);
    return (
        <>
            <Tooltip title={saved ? 'Remove' : 'Save'}>
                <IconButton
                    size={isSmall ? 'small' : 'default'}
                    onClick={saveCourse}
                >
                    {saved ? (
                        <BookmarkIcon
                            fontSize={isSmall ? 'small' : 'default'}
                            color="secondary"
                        />
                    ) : (
                        <BookmarkBorderIcon
                            fontSize={isSmall ? 'small' : 'default'}
                        />
                    )}
                </IconButton>
            </Tooltip>
            <LoginModal
                open={openLoginModal}
                onRequestClose={onLoginModalClose}
            />
        </>
    );
};
