import { useParams } from 'react-router-dom';
import { useIsAdmin } from '../admin/useIsAdmin';
import { useGetCustomJourneys } from '../admin/content-curation/useGetCustomJourneys';
import { useCategoryDetails } from '../category-landing-page/useCategoryDetails';
import { PriavteJourneysTab } from '../category-landing-page/PriavteJourneysTab';

const DashboardPrivateJourneys = () => {
    const { subSection } = useParams();
    const { categoryDetails, isLoading } = useCategoryDetails(subSection);
    const { client, starweaverAdmin } = useIsAdmin();
    const {
        journeys: customJourneys,
        isLoading: isLoadingCustomJourneys,
    } = useGetCustomJourneys(client?._id, starweaverAdmin);

    return (
        <div>
            <PriavteJourneysTab
                domain={categoryDetails?.domain}
                journeys={customJourneys}
                isLoading={isLoading || isLoadingCustomJourneys}
            />
        </div>
    );
};

export default DashboardPrivateJourneys;
