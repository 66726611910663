import {
    SectionContainer,
    SectionHeading,
} from '../custom-components/styled-components';

import { makeStyles } from 'tss-react/mui';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Paper } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightMedium,
        color: '#3a3a3a',
    },
    summaryText: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const faqList = [
    {
        id: 1,
        question: 'How much do the courses at Starweaver cost?',
        ansArr: [
            'We offer flexible payment options to make learning accessible for everyone. With our Pay-As-You-Go plan, you can pay for each course individually. Alternatively, our Subscription-Based plan provides you with unlimited access to all courses for a monthly or yearly fee. ',
        ],
    },
    {
        id: 2,
        question:
            'Do you offer any certifications upon completion of a course at Starweaver?',
        ansArr: [
            'Yes, we do offer a certification upon completion of our course to showcase your newly acquired skills and expertise.',
        ],
    },
    {
        id: 3,
        question: 'Does Starweaver offer any free courses or trials?',
        ansArr: [
            "No, we don't offer any free courses, but we do offer 5-day trial only on our subscriptions-based plans.",
        ],
    },
    {
        id: 4,
        question:
            "Are Starweaver's courses designed for beginners or advanced students?",
        ansArr: [
            'Our course is designed with three levels to cater to your learning needs - Core, Intermediate, and Advanced. You can choose the level that best suits your knowledge and skillset to enhance your learning experience.',
        ],
    },
    {
        id: 5,
        question: 'What payment options are available for Starweaver courses?',
        ansArr: [
            'We accept various payment methods such as major credit cards, PayPal, wire transfer, and company purchase orders. For more information related to payments contact customer support.',
        ],
    },
    {
        id: 6,
        question: 'Do you offer refunds?',
        ansArr: [
            'Yes, we do offer a 100% refund guarantee for our courses within a specified time frame. If you are not satisfied with the course, contact our customer support team to request a refund with your order details. Some restrictions may apply.',
        ],
    },
];

const CourseFAQ = () => {
    const { classes } = useStyles();

    return (
        <SectionContainer id="faq">
            <SectionHeading>Frequently Asked Questions</SectionHeading>

            <Paper elevation={2}>
                {/* ACCORDION */}
                {faqList.map((faq) => {
                    return (
                        <Accordion
                            key={'faq' + faq.id}
                            defaultExpanded={faq.id === 1}
                            slotProps={{
                                transition: {unmountOnExit: true}
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${faq.id}a-content`}
                                id={`panel${faq.id}a-content`}
                            >
                                <Typography className={classes.heading}>
                                    {faq.question}
                                </Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                                {faq.ansArr.map((ans, idx) => {
                                    return (
                                        <Typography
                                            className={classes.summaryText}
                                            key={'faqAns' + idx}
                                        >
                                            {ans}
                                        </Typography>
                                    );
                                })}
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Paper>
        </SectionContainer>
    );
};

export default CourseFAQ;
