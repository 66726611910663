import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Card, List, ListItem, ListItemText, Radio, Typography } from '../ui';

const useStyles = makeStyles()((theme) => ({
    root: { padding: 20, marginBottom: 20, marginTop: 20 },

    radioListItem: {
        padding: 0,
    },
}));

export const RadioSelect = ({
    heading,
    labelsAndValues,
    selectedValue,
    onChange,
}) => {
    const { classes } = useStyles();

    return (
        <Card className={classes.root}>
            <Typography variant="h6">{heading}</Typography>
            {labelsAndValues.map(({ label, value }, index) => {
                return (
                    <List component="div" disablePadding key={index}>
                        <ListItem className={classes.radioListItem}>
                            <Radio
                                checked={selectedValue === value}
                                value={value}
                                onChange={onChange}
                            />
                            <ListItemText primary={label} />
                        </ListItem>
                    </List>
                );
            })}
        </Card>
    );
};
