import { coursesSlice } from './coursesSlice';
const { getCourses } = coursesSlice;

export const getCoursesForCollection = (collectionId) => (state) => {
    const courses = getCourses(state);
    if (Number(collectionId) !== 999999) {
        return courses.filter((course) =>
            course.collection_ids.includes(Number(collectionId))
        );
    } else {
        return courses;
    }
};
