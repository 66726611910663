export const PLAN_TYPE = [
    {
        name: 'Monthly',
    },
    {
        name: 'Yearly',
    },
];

// PRODUCT_ID = PHASE OF THE APP DEV/PROD ? TEST_ID OF PRODUCT : PRODUCTION_ID OF PRODUCT
// WE ARE STILL USING THE TEST KEY ON THE BETA SITE
export const astronaut_id =
    !process.env.REACT_APP_ENVIRONMENT ||
    process.env.REACT_APP_ENVIRONMENT === 'development'
        ? 'prod_IjuO8MmV29UNDl'
        : 'prod_ImbRJJkC1WyWV2';
export const commander_id =
    !process.env.REACT_APP_ENVIRONMENT ||
    process.env.REACT_APP_ENVIRONMENT === 'development'
        ? 'prod_In9gOGJdI182Lb'
        : 'prod_In5L8V2chA3ouL';

export const voyager_id = 'prod_IjuL3e1YMNoaH1';

export const COMMANDER = 'Commander';
export const ASTRONAUT = 'Astronaut';
export const VOYAGER_PLUS = 'Voyager+';
// all test ids
// change those to according to the environment
// making those static so laoding time is less
// mongo_ids
export const voyager_credit_id =
    process.env.REACT_APP_ENVIRONMENT ||
    process.env.REACT_APP_ENVIRONMENT === 'development'
        ? '60b68f3d94d64a0b042a0b02'
        : '60bf9bdac4f2983a58b6cdc4';
export const astronaut_credit_id =
    process.env.REACT_APP_ENVIRONMENT ||
    process.env.REACT_APP_ENVIRONMENT === 'development'
        ? '60b68f3d94d64a0b042a0b04'
        : '60bf9bdac4f2983a58b6cdc6';
export const commander_credit_id =
    process.env.REACT_APP_ENVIRONMENT ||
    process.env.REACT_APP_ENVIRONMENT === 'development'
        ? '60b68f3d94d64a0b042a0b03'
        : '60bf9bdac4f2983a58b6cdc5';

export const PLANS_CARD_DETAILS = [
    {
        p_id: 'prod_Iju1YMNoaH1',
        creditId: voyager_credit_id,
        name: VOYAGER_PLUS,
        creditTooltip: 'Get lifetime access to great content at a low cost.',
        originalPrice: [0, 0],
        price: [0, 0],
        caption: (
            <>
                <div>100% access to On Air streaming classes.</div>
                <div>No credit card required</div>
            </>
        ),
        freeTrial: null,
        discription: (
            <>
                <div>Lifetime access to content you purchase.</div>
                <div>Credits used for purchasing content.</div>
            </>
        ),
        features: [
            'Access to 4 hours / month of On Air streaming classes.',
            'Access to Journeys -  Learning Paths.',
            'No limitation on course completion certifications.',
            'Instant access to credits purchased.',
            '100% access to all Techbytes blogs and articles.',
        ],
        active: false,
    },
    {
        p_id: astronaut_id,
        name: ASTRONAUT,
        creditId: astronaut_credit_id,
        creditTooltip:
            'Access top instructors and courses, get discounted credits and earn free credits monthly.​',
        caption: (
            <>
                <div>All Voyager+ privileges plus much more.</div>
                <div>Access premium courses, instructors, challenges…</div>
            </>
        ),
        originalPrice: [],
        price: ['24.95', '239.95'],
        freeTrial: 5,
        features: [
            'Unlimited access to our extensive library of on demand courses.',
            'Unlimited access to On Air streaming classes.',
            'Unlimited access to Starweaver certifications.(Only annual plans)',
            'Unlimited access to LiveLabs.',
            'Unlimited participation in online communities.',
        ],
        discription: (
            <>
                <div>100% access to our extensive content,</div>
                <div>hands-on training, Journeys and much more.</div>
            </>
        ),
        active: true,
    },
    {
        p_id: commander_id,
        name: COMMANDER,
        creditId: commander_credit_id,
        creditTooltip:
            'Get steeply discounted credits and earn even more free credits monthly.​​',
        caption: (
            <>
                <div>All Astronaut privileges plus much more.</div>
                <div>Access coding and business case bootcamps</div>
                <div>Access comprehensive certifications</div>
            </>
        ),
        originalPrice: [],
        price: ['39.95', '359.95'],
        discription: (
            <>
                <div>Everything for Astronauts, plus access to</div>
                <div>premium content, bootcamps and certifications.</div>
            </>
        ),
        freeTrial: 5,
        features: [
            'Unlimited access to live instructor and teaching assistant courses monthly.',
            'Unlimited access to premium content and certifications.(Only annual plans)',
            'Free access to five (5) Guided Labs per month',
            'Substantial discounts on credit purchases.',
            'Free monthly credits.',
        ],
        active: false,
    },
];

export const selectedCreditPlanDetails = {
    [voyager_credit_id]: {
        id: voyager_credit_id,
        name: 'Voyager+ Credits',
        planRequired: 'Voyager',
        planRequiredId: null,
        discount: null,
        features: [
            'Great way to test out the Starweaver platform.​',
            'Get lifetime access to specific content you want at a low cost.​',
            'Buy as many or few credits/courses as you need, just-in-time. ​',
        ],
    },
    [astronaut_credit_id]: {
        id: astronaut_credit_id,
        name: 'Astronaut Credits',
        planRequired: 'Astronaut',
        planRequiredId: astronaut_id,
        discount: 10,
        features: [
            'Get discounted credits and earn free credits monthly.​',
            'Access more live classes​',
            'Top-up your on-demand learning with​',
        ],
    },
    [commander_credit_id]: {
        discount: 20,
        id: commander_credit_id,
        name: 'Commander Credits',
        planRequired: 'Commander',
        planRequiredId: commander_id,
        features: [
            'Get discounted credits and earn free credits monthly.​',
            'Access more live classes​',
            'Top-up your on-demand learning with​',
        ],
    },
};

export const FEATURE_VALUE = [null, true, 'Pay-as-you-go', 'Upon request'];

export const otherPlansDetails = [
    {
        id: astronaut_credit_id,
        name: 'Astronaut',
        discount: 10,
        features: [
            'Access live courses, instructors and teaching assistants.​',
            'Get discounted credits and earn free credits monthly.​',
            'Access all on-demand content. ​',
        ],
    },
    {
        id: commander_credit_id,
        name: 'Commander',
        discount: 20,
        features: [
            'Participate in live events, hackathons and other live activities.​',
            'Get steeply discounted credits and earn even more free credits monthly.​',
            'Receive live career coaching advice and inputs.​',
        ],
    },
    {
        id: voyager_credit_id,
        name: 'Voyager',
        discount: null,
        features: [
            'Get to explore our platform',
            'Basic price on credits',
            'Upcoming objectives to earn more credits',
        ],
    },
];

export const PLANS_COMPARISON_DATA = [
    {
        title: 'Learning',
        features: [
            {
                name: ' On Air',
                value: [1, 1, 1],
            },
            {
                name: 'On Demand',
                value: [2, 1, 1],
            },
        ],
    },
    {
        title: 'Certifications',
        features: [
            {
                name: ' Starweaver',
                value: [2, 1, 1],
            },
            {
                name: 'Industry',
                value: [2, 0, 1],
            },
            {
                name: 'Certificate of Completion',
                value: [2, 1, 1],
            },
            {
                name: 'Shareable Digital Badges',
                value: [2, 1, 1],
            },
        ],
    },
    {
        title: 'Journeys',
        features: [
            {
                name: 'Standard',
                value: [2, 1, 1],
            },
            {
                name: 'Job Specific',
                value: [2, 1, 1],
            },
            {
                name: 'Customizable',
                value: [2, 0, 1],
            },
        ],
    },
    {
        title: 'Channels',
        features: [
            {
                name: 'Live',
                value: [2, 1, 1],
            },
            {
                name: 'Recorded',
                value: [2, 1, 1],
            },
        ],
    },
    // guided gurus
    {
        title: 'Guided Gurus',
        features: [
            {
                name: 'Live',
                value: [2, 1, 1],
            },
            {
                name: 'Recorded',
                value: [2, 0, 1],
            },
        ],
    },
    {
        title: 'Challenges',
        features: [
            {
                name: 'Competitions & Hackathons',
                value: [2, 0, 1],
            },
            {
                name: 'Quizzes, Exercises & Assessments',
                value: [2, 1, 1],
            },
            {
                name: 'Practice Exams',
                value: [2, 1, 1],
            },
        ],
    },
    {
        title: 'Meet the Gurus',
        features: [
            {
                name: 'Live',
                value: [1, 1, 1],
            },
            {
                name: 'Recorded',
                value: [0, 1, 1],
            },
        ],
    },
    {
        title: 'Support',
        features: [
            {
                name: 'Support & FAQs',
                value: [1, 1, 1],
            },
            {
                name: 'Email Support',
                value: [0, 1, 1],
            },
            {
                name: 'Community',
                value: [0, 1, 1],
            },
            {
                name: 'Chat',
                value: [0, 1, 1],
            },
        ],
    },
];

export const TEAM_PLANS = [
    {
        p_id: 123,
        name: ASTRONAUT,
        price: ['Contact us'],
        description: (
            <>
                <div>
                    100% access to our extensive content, hands-on training,
                    Journeys and much more.
                </div>
            </>
        ),
        forTeams: true,
    },
    {
        p_id: 456,
        name: COMMANDER,
        price: ['Contact us'],
        description: (
            <>
                <div>
                    Everything for Astronauts, plus access to premium content,
                    bootcamps and certifications.
                </div>
            </>
        ),
        forTeams: true,
    },
];

export const PLAN_COMPARISON_FOR_TEAMS = [
    {
        title: 'User & Team Management',
        features: [
            {
                name: 'Create & Manage',
                value: [1, 1],
            },
            {
                name: 'License Management (L1, L3, L6, L12)',
                info: (
                    <div>
                        <p>L1 = Month-to-Month Access</p>
                        <p>L3 = 3 Months Access</p>
                        <p>L6 = 6 Months Access</p>
                        <p>L12 = 12 Months Access</p>
                    </div>
                ),
                value: [1, 1],
            },
        ],
    },
    {
        title: 'Content Management',
        features: [
            {
                name: 'Custom Journey Builder',
                value: [1, 1],
            },
            {
                name: 'Custom Persona Builder',
                value: [1, 1],
            },
        ],
    },
    {
        title: 'Messaging Suite',
        features: [
            {
                name: 'Create & Manage',
                value: [1, 1],
            },
            {
                name: 'Message Library',
                value: [1, 1],
            },
            {
                name: 'Message Scheduling & Automation',
                value: [1, 1],
            },
        ],
    },
    {
        title: 'Reporting',
        features: [
            {
                name: 'Activity Reports',
                value: [1, 1],
            },
            {
                name: 'Engagement Reports',
                value: [1, 1],
            },
            {
                name: 'Registration Reports',
                value: [1, 1],
            },
            {
                name: 'Profiler Reports',
                value: [1, 1],
            },
            {
                name: 'Leaderboards',
                value: [1, 1],
            },
        ],
    },
    {
        title: 'Instructor Boostr Access',
        features: [
            {
                name: 'Instructor Management',
                value: [0, 1],
            },
            {
                name: 'Own Instructor Streaming',
                value: [0, 1],
            },
            {
                name: 'Custom RTMP Streaming',
                value: [0, 1],
            },
            {
                name: 'Streaming (Teams, Zoom, IBM Watson)',
                value: [0, 1],
            },
        ],
    },
    {
        title: 'Integrations',
        features: [
            {
                name: 'Custom Community Integrations',
                value: [3, 3],
            },
            {
                name: 'LMS Integration',
                value: [3, 1],
            },
            {
                name: 'Single Sign On (SSO)',
                value: [3, 1],
            },
        ],
    },
    {
        title: 'Additional Support',
        features: [
            {
                name: 'Dedicated Relationship Manager(s)',
                value: [1, 1],
            },
            {
                name: 'Dedicated Program Manager(s)',
                value: [1, 1],
            },
            {
                name: 'Onboard Training',
                value: [1, 1],
            },
            {
                name: 'Customized User Onboarding',
                value: [1, 1],
            },
            {
                name: 'Enhanced Service Level Agreement',
                value: [1, 1],
            },
            {
                name: 'Customized Course Development',
                value: [3, 3],
            },
            {
                name: 'Customized Persona Development',
                value: [3, 3],
            },
            {
                name: 'Customized Journey Development',
                value: [3, 3],
            },
            {
                name: 'Private Course Delivery',
                value: [3, 3],
            },
        ],
    },
];
