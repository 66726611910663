import React, { useEffect } from 'react';
import { Loading } from '../ui';

export const SwitchDomain = ({ domain }) => {
    useEffect(() => {
        window.open(
            (domain || process.env.REACT_APP_DOMAIN) + window.location.pathname,
            '_self'
        );
    }, []);

    return <Loading />;
};
