import { atom } from 'recoil';

/* export const studentOnboardingAnswerState = atom({
  key: 'studentOnboardingAnswerState',
  default: {
    userId:null,
    designation:null,
    interestedInLearningWithGroups:null,
    jobTitleIds:null,
    professionalGoalsIds:null,
    topicsInterested:null,
  },
}); */

export const jobTitlesState = atom({
    key: 'jobTitlesState',
    default: [],
});

export const selectedJobTitlesState = atom({
    key: 'selectedJobTitlesState',
    default: [],
});

export const professionalGoalsState = atom({
    key: 'professionalGoalsState',
    default: {},
});

export const selectedProfessionalGoalsState = atom({
    key: 'selectedProfessionalGoalsState',
    default: [],
});

export const selectedCoursesState = atom({
    key: 'selectedCoursesState',
    default: [],
});

export const topicsState = atom({
    key: 'topicsState',
    default: [],
});

export const selectedTopicsState = atom({
    key: 'selectedTopicsState',
    default: [],
});

export const selectedDesignationState = atom({
    key: 'selectedDesignationState',
    default: null,
});

export const isInterestedInLearningWithGroupState = atom({
    key: 'isInterestedInLearningWithGroupState',
    default: null,
});
