import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
} from '../ui';

export const EnrollDialogWithSubscription = ({
    onClose,
    handleEnroll,
    open,
    course,
    title = '',
    isLoading = false,
}) => {
    return (
        <Dialog disableScrollLock open={open} onClose={onClose}>
            <DialogTitle>{`Enrollment confirmation in ${title}`}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You may always un-enroll at any time. Note, we retain your
                    progress even if you un-enroll.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isLoading}
                    onClick={onClose}
                    color="secondary"
                    variant="outlined"
                >
                    Close
                </Button>
                <Button
                    onClick={async () => {
                        await handleEnroll(course);
                        onClose();
                    }}
                    disabled={isLoading}
                    color="secondary"
                    variant="contained"
                    autoFocus
                >
                    {isLoading ? (
                        <CircularProgress color="secondary" />
                    ) : (
                        'Enroll'
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
