import { Avatar, Box, Button, Link as MuiLink, Typography } from '../ui';
import { useState, useEffect, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { brown } from '@mui/material/colors';
import { StepperImages } from '../ui/CustomUI/StepperImages';
import { useSelector } from 'react-redux';
import { coursesSlice } from '../courses/coursesSlice';

const { getCourses } = coursesSlice;

export const JourneyCard = ({
    journey,
    setOpenUnEnrollDialog = () => console.log('open-dialog'),
    setJourneyIdToUnEnroll = () => console.log('journeyId'),
    showActions = true,
    actionButtonText = 'Un-enroll',
}) => {
    const { classes } = useStyles();
    const courses = useSelector(getCourses);

    const [instructors, setInstructors] = useState('');
    const [domains, setDomains] = useState('');
    const [duration, setDuration] = useState('');

    useEffect(() => {
        if (journey) {
            const instructorList = [];
            const domainList = [];
            let hours = 0;
            let minutes = 0;
            journey.coursesIncluded.forEach((course) => {
                instructorList.push(course.instructor);
                domainList.push(course.domain);
                const currentHour = parseInt(course.hours);
                const currentMinutes = parseInt(course.minutes);
                hours = hours + currentHour;
                minutes = minutes + currentMinutes;
            });
            const hoursFromMinutes = Math.trunc(minutes / 60);
            minutes = minutes % 60;
            hours = hours + hoursFromMinutes;
            const duration = `${timeDurationString(
                hours,
                'hr'
            )} ${timeDurationString(minutes, 'min')}`;
            setDuration(duration);
            setInstructors([...new Set(instructorList)].join(', '));
            setDomains([...new Set(domainList)].join(', '));
        }
    }, [journey]);

    const steps = useMemo(() => {
        if (journey) {
            return journey.coursesIncluded.map((course) => {
                let link = '';
                if (course.type === 'ON-DEMAND') {
                    let currentCourse = courses.find(
                        ({ productable_id }) =>
                            // eslint-disable-next-line
                            course.thinkificCourseId == productable_id
                    );
                    link = `/courses/${currentCourse?.slug}`;
                } else if (course.type === 'ON-AIR') {
                    link = `/on-air/${course.slug}`;
                } else {
                    link = `/retro-course/${course.slug}`;
                }
                return {
                    label: course.title.replace('(Thinkific)', ''),
                    imgUrl: course.badgeImg?.url || course.thumbnail,
                    link,
                };
            });
        } else {
            return [];
        }
    }, [journey, courses]);

    const handleUnEnroll = () => {
        setJourneyIdToUnEnroll(journey.id);
        setOpenUnEnrollDialog(true);
    };

    return (
        <Box className={classes.journeyBox} mb={4}>
            <Box className={classes.headBar}>
                <Avatar
                    src={journey.thumbnail}
                    className={classes.journeyImage}
                />
            </Box>
            <Box mt={4} />
            <Box className={classes.headingJourney}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <MuiLink href={`/journeys/${journey.slug}`}>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                            gutterBottom
                        >
                            {journey.title}
                        </Typography>
                    </MuiLink>
                    {showActions && (
                        <Box>
                            <Button
                                onClick={handleUnEnroll}
                                variant="contained"
                                color="secondary"
                            >
                                {actionButtonText}
                            </Button>
                        </Box>
                    )}
                </Box>
                <Typography variant="subtitle2">
                    {instructors} | {domains} | {duration}
                </Typography>
            </Box>
            <Box>
                {steps.length && (
                    <StepperImages steps={steps} step={steps.length} />
                )}
            </Box>
        </Box>
    );
};

const journeyImageDimension = 60;

const useStyles = makeStyles()((theme) => ({
    journeyBox: {
        boxShadow: theme.shadows[4],
        borderRadius: '3px 3px 0 0',
        overflow: 'hidden',
    },
    headBar: {
        backgroundColor: brown[700],
        position: 'relative',
        width: '100%',
        height: journeyImageDimension,
    },
    journeyImage: {
        position: 'absolute',
        // clipPath: 'polygon(50% 0, 100% 25%, 100% 70%, 50% 100%, 0 70%, 0 25%)',
        top: '40%',
        left: '30px',
        width: journeyImageDimension,
        height: journeyImageDimension,
        boxShadow: '0px 0px 0px 4px rgba(250,250,250,0.8)',
    },
    headingJourney: {
        width: '90%',
        margin: '0 auto',
    },
}));

const timeDurationString = (number, type = '') => {
    return number ? `${number} ${number > 1 ? type + 's' : type}` : '';
};
