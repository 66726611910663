import { useRef } from 'react';
import { Box, Button, Grid, Loading, Typography } from '../ui';
import { CommentItem } from './CommentItem';
import { useEffect, useState } from 'react';
import { LoginModal } from '../auth/LoginModal';
import { getScrollableParent } from '../util';

const MAX_COMMENT_LIMIT = 2;

export const CommentsDisplay = ({
    courseId,
    user,
    starweaverAdmin = false,
    setCommentsLength = () => console.log('def'),
    newComment = {},
    mentionables = [],
    tasks = [],
    teams = [],
    tasksOutputTemplate,
    showAllComments = true,
    comments,
    setComments,
    isLoading,
    afterCommentAdded = undefined,
    visibilityOptions,
    clientId,
    showAttachments = false,
    showCommentAttachments = false,
    replyToComment,
}) => {
    const [showMore, setShowMore] = useState(false);
    const [maxSize, setMaxSize] = useState(MAX_COMMENT_LIMIT);

    const commentRefs = useRef({});

    useEffect(() => {
        if (comments) {
            setCommentsLength(comments.length);
        }
    }, [comments, setCommentsLength]);

    useEffect(() => {
        /* IF THERE IS NO NEW COMMENT */
        if (Object.keys(newComment).length === 0) return;

        /* STORE THE NEW COMMENT ALONG WITH THE OTHERS */
        setComments((prev) => {
            return [{ ...newComment }, ...prev];
        });
    }, [newComment, setComments]);

    const [openAccessBlock, setOpenAccessBlock] = useState(false);
    const onClose = () => {
        setOpenAccessBlock(false);
    };
    const handleLoadMore = () => {
        setShowMore((prev) => {
            if (prev === false) {
                setMaxSize(comments.length);
                return true;
            }
            setMaxSize(MAX_COMMENT_LIMIT);
            return false;
        });
    };

    const navigateToComment = (commentId) => {
        const commentElement = commentRefs.current[commentId];
        if (commentElement) {
            const offset = 200; // Adjust this value as needed
            const scrollableParent = getScrollableParent(commentElement);

            const elementPosition = commentElement.getBoundingClientRect().top;
            const parentScrollTop =
                scrollableParent === window
                    ? window.scrollY
                    : scrollableParent.scrollTop;
            const offsetPosition = elementPosition + parentScrollTop - offset;

            scrollableParent.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });

            commentElement.style.backgroundColor = 'lightyellow'; // Highlight the comment
            setTimeout(() => {
                commentElement.style.backgroundColor = ''; // Remove highlight after a short delay
            }, 2000);
        }
    };

    return (
        <Box>
            {isLoading ? (
                <Loading height="200px" />
            ) : (
                <Grid container>
                    {comments?.length > 0 ? (
                        <>
                            {showAllComments === false ? (
                                <SlicedComments
                                    comments={comments}
                                    maxSize={maxSize}
                                    setOpenAccessBlock={setOpenAccessBlock}
                                    user={user}
                                    starweaverAdmin={starweaverAdmin}
                                    setComments={setComments}
                                    mentionables={mentionables}
                                    tasks={tasks}
                                    teams={teams}
                                    tasksOutputTemplate={tasksOutputTemplate}
                                    handleLoadMore={handleLoadMore}
                                    showMore={showMore}
                                    afterCommentAdded={afterCommentAdded}
                                    visibilityOptions={visibilityOptions}
                                    clientId={clientId}
                                    showAttachments={showAttachments}
                                    showCommentAttachments={
                                        showCommentAttachments
                                    }
                                    replyToComment={replyToComment}
                                />
                            ) : (
                                comments.map((comment, index) => (
                                    <Grid
                                        item
                                        xs={12}
                                        key={`${comment._id}-comment`}
                                        ref={(el) =>
                                            (commentRefs.current[
                                                comment._id
                                            ] = el)
                                        }
                                    >
                                        <CommentItem
                                            defaultOpenReplies={index === 0}
                                            setOpenAccessBlock={
                                                setOpenAccessBlock
                                            }
                                            user={user}
                                            starweaverAdmin={starweaverAdmin}
                                            comment={comment}
                                            setComments={setComments}
                                            mentionables={mentionables}
                                            tasks={tasks}
                                            teams={teams}
                                            tasksOutputTemplate={
                                                tasksOutputTemplate
                                            }
                                            afterCommentAdded={
                                                afterCommentAdded
                                            }
                                            visibilityOptions={
                                                visibilityOptions
                                            }
                                            clientId={clientId}
                                            showAttachments={showAttachments}
                                            showCommentAttachments={
                                                showCommentAttachments
                                            }
                                            navigateToComment={
                                                navigateToComment
                                            }
                                            replyToComment={replyToComment}
                                        />
                                    </Grid>
                                ))
                            )}
                        </>
                    ) : (
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                No comments...
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            )}
            <LoginModal
                open={openAccessBlock}
                onRequestClose={onClose}
                signUpLink="/pricing"
            />
        </Box>
    );
};

const SlicedComments = ({
    comments,
    maxSize,
    setOpenAccessBlock,
    user,
    starweaverAdmin,
    setComments,
    mentionables,
    tasks = [],
    teams = [],
    tasksOutputTemplate,
    handleLoadMore,
    showMore,
    afterCommentAdded = undefined,
    visibilityOptions,
    clientId,
    showAttachments = false,
    showCommentAttachments = false,
    replyToComment,
}) => {
    return (
        <>
            {comments.slice(0, maxSize).map((comment, index) => (
                <Grid item xs={12} key={`${comment._id}-comment`}>
                    <CommentItem
                        defaultOpenReplies={index === 0}
                        setOpenAccessBlock={setOpenAccessBlock}
                        user={user}
                        starweaverAdmin={starweaverAdmin}
                        comment={comment}
                        setComments={setComments}
                        mentionables={mentionables}
                        tasks={tasks}
                        teams={teams}
                        tasksOutputTemplate={tasksOutputTemplate}
                        afterCommentAdded={afterCommentAdded}
                        visibilityOptions={visibilityOptions}
                        clientId={clientId}
                        showAttachments={showAttachments}
                        showCommentAttachments={showCommentAttachments}
                        replyToComment={replyToComment}
                    />
                </Grid>
            ))}
            {comments.length > MAX_COMMENT_LIMIT && (
                <Box display={'flex'} justifyContent={'center'} width={'100%'}>
                    <Button color="secondary" onClick={handleLoadMore}>
                        {showMore ? 'SHOW LESS' : 'LOAD MORE'}
                    </Button>
                </Box>
            )}
        </>
    );
};
