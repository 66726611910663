import { useCallback, useEffect, useState } from 'react';
import { Box, IconButton, Typography, Tooltip } from '../ui';
import { useCourseLike } from './useCourseLike';
import { useCurrentUser } from '../my-account/useCurrentUser';
import { useStyles } from './styles';
import { ClapsIcons } from '../icons';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';

import { LoginModal } from '../auth/LoginModal';

export const CourseLikeButton = ({
    courseId,
    courseSlug,
    courseName,
    defaultLikes = 0,
}) => {
    const { likes, updateLikes } = useCourseLike(courseId);
    const [liked, setLiked] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [openAccessBlock, setOpenAccessBlock] = useState(false);
    const isSmall = useMediaQuery('(max-width:600px)');

    const { user } = useCurrentUser();
    const { classes } = useStyles();
    useEffect(() => {
        if (user && likes?.length) {
            const hasLiked = likes?.some((like) => like.user_id === user._id);
            setLiked(hasLiked);
        }
        // eslint-disable-next-line
    }, [user, likes]);

    const onClose = () => {
        setOpenAccessBlock(false);
    };

    const blockAccess = () => {
        setOpenAccessBlock(true);
    };

    const handleLike = useCallback(async () => {
        if (user) {
            if (!isProcessing) {
                setIsProcessing(true);
                try {
                    const updateObject = {
                        courseName,
                        courseSlug,
                    };
                    if (liked) {
                        const {
                            data: { disliked },
                        } = await axios.post(
                            `/api/content/${courseId}/course/dislike`,
                            updateObject
                        );
                        console.log('dislike');
                        console.log(disliked);
                        updateLikes();
                    } else {
                        const {
                            data: { likedCourse },
                        } = await axios.post(
                            `/api/content/${courseId}/course/like`,
                            updateObject
                        );
                        console.log(likedCourse);
                        console.log('like');
                        updateLikes();
                    }
                } catch (error) {
                    console.log(error.message);
                }
                setIsProcessing(false);
            }
        } else {
            blockAccess();
        }
    }, [
        isProcessing,
        liked,
        updateLikes,
        user,
        courseSlug,
        courseId,
        courseName,
    ]);

    return (
        <>
            <Box display="flex" alignItems="center">
                <Tooltip title="Like">
                    <IconButton
                        size={isSmall ? 'small' : 'default'}
                        onClick={handleLike}
                        className={classes.action}
                    >
                        {liked ? (
                            <ClapsIcons
                                fontSize={isSmall ? 'small' : 'default'}
                                color="secondary"
                            />
                        ) : (
                            <ClapsIcons
                                fontSize={isSmall ? 'small' : 'default'}
                            />
                        )}
                    </IconButton>
                </Tooltip>
                {(defaultLikes || likes?.length) && (
                    <Typography variant="body2">
                        {likes ? likes.length + defaultLikes : defaultLikes}
                    </Typography>
                )}
            </Box>
            <LoginModal
                open={openAccessBlock}
                onRequestClose={onClose}
                signUpLink="/pricing"
            />
        </>
    );
};
