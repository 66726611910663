import {
    Box,
    Button,
    CountrySelect,
    CircularProgress,
    CustomSnackbar,
    Grid,
    MenuItem,
    TextField,
    Typography,
    Link,
} from '../ui';
import { useState } from 'react';
import axios from 'axios';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
export const SignUpInputs = ({
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    country,
    setCountry,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    survey,
    setSurvey,
    setClientCode,
    createAccount,
    formRef,
    loading,
    clientRefCode = '',
    clientCode,
    universityEmail,
}) => {
    const { classes } = useStyles();

    // these state are not needed outside of this component
    const [hasCompanyCode, setHasCompanyCode] = useState(
        clientRefCode ? true : false
    );
    const [companyCode, setCompanyCode] = useState(clientRefCode);
    const [userCode, setUserCode] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const toggleCompanyCode = () => {
        setHasCompanyCode(!hasCompanyCode);
    };
    const verifyCode = async () => {
        try {
            const { data } = await axios.post(
                '/api/users/verify-company-code',
                {
                    companyCode,
                    userCode,
                }
            );
            console.log(data);
            if (data.success) {
                setClientCode(`${companyCode}-${userCode}`);
                setMessage(data.message);
            } else {
                setError('Redeemed Code is invalid!');
                setClientCode('');
            }
        } catch (error) {
            setClientCode('');
            console.log(error.message);
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <TextField
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="First Name"
                    name="firstName"
                    autoComplete="firstName"
                    color="secondary"
                    inputRef={formRef}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    autoComplete="lastName"
                    color="secondary"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    color="secondary"
                    disabled={universityEmail}
                />
            </Grid>
            <Grid item xs={12}>
                <CountrySelect
                    variant="outlined"
                    fullWidth={true}
                    selected={country}
                    required={true}
                    setSelected={setCountry}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    select
                    fullWidth
                    name="Survey"
                    label="How did you hear about us?"
                    color="secondary"
                    value={survey}
                    onChange={(e) => setSurvey(e.target.value)}
                >
                    {surveyOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    color="secondary"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="confirmpassword"
                    label="Confirm Password"
                    type="password"
                    id="confirmpassword"
                    autoComplete="current-password"
                    color="secondary"
                />
            </Grid>

            {!clientRefCode && !universityEmail && (
                <Grid item xs={12}>
                    <Box
                        ml={1}
                        className={classes.link}
                        onClick={toggleCompanyCode}
                    >
                        {!hasCompanyCode ? 'Company code?' : 'Hide?'}
                    </Box>
                </Grid>
            )}
            {hasCompanyCode && (
                <Grid item xs={12} container alignItems="flex-end" spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Box ml={1}>
                            <TextField
                                value={companyCode}
                                onChange={(e) =>
                                    setCompanyCode(e.target.value.toUpperCase())
                                }
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="companyCode"
                                label="Company Code"
                                type="text"
                                id="companyCode"
                                color="secondary"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box ml={1}>
                            <TextField
                                value={userCode}
                                onChange={(e) =>
                                    setUserCode(e.target.value.toUpperCase())
                                }
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="userCode"
                                label="User Code"
                                type="text"
                                id="userCode"
                                color="secondary"
                            />
                        </Box>
                    </Grid>
                    {!clientCode && (
                        <Grid item xs={12} md={4}>
                            <Box ml={1} mb={1}>
                                <Button
                                    onClick={verifyCode}
                                    color="secondary"
                                    variant="outlined"
                                >
                                    Verify
                                </Button>
                            </Box>
                        </Grid>
                    )}
                    <CustomSnackbar
                        message={message}
                        setMessage={setMessage}
                        type="success"
                    />
                    <CustomSnackbar
                        message={error}
                        setMessage={setError}
                        type="error"
                    />
                </Grid>
            )}

            <Grid item xs={12}>
                <Button
                    onClick={createAccount}
                    fullWidth
                    disabled={loading}
                    variant="contained"
                    color="secondary"
                >
                    {loading ? (
                        <CircularProgress size="30px" color="secondary" />
                    ) : (
                        'START ABSOLUTELY FREE'
                    )}
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Box mt={2}>
                    <Typography variant="subtitle2">
                        By clicking "START ABSOLUTELY FREE​ " you agree to
                        Starweaver’s{' '}
                        <Link
                            color="secondary"
                            href={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/policies/`}
                        >
                            Privacy Policy
                        </Link>{' '}
                        and{' '}
                        <Link
                            color="secondary"
                            href={`${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/policies/`}
                        >
                            Terms and Conditions.
                        </Link>{' '}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

const surveyOptions = [
    'Udemy',
    'Google search',
    'Bing search',
    'Other search',
    'Blog/Techybtes article',
    'Meet the Gurus (live event)',
    'Meet the Gurus (recording)',
    'Starweaver LinkedIn page',
    'Starweaver Youtube channel',
    'Starweaver Email newsletter',
    'Meetups',
    'Eventbrite',
    'Advertisement',
    'None of the above',
];

const useStyles = makeStyles()((theme) => ({
    radio: {
        display: 'flex',
        flexFlow: 'row',
        width: '100%',
    },
    item: {
        flexBasis: '33%',
    },
    dob: {
        width: 'fit-content',
    },
    link: {
        cursor: 'pointer',
        '&:hover': {
            color: '#4267B2',
            textDecoration: 'underline',
        },
    },
}));
