import { Autocomplete, Typography, TextField } from '../ui';

export const CourseAutocompleteSection = ({
    selectedOption,
    setSelectedOption = () => {},
    courseOptions,
}) => {
    return (
        <Autocomplete
            value={selectedOption}
            onChange={(event, newValue) => {
                console.log(newValue);
                setSelectedOption(newValue);
            }}
            disableClearable
            getOptionLabel={(option) => option}
            options={courseOptions}
            fullWidth
            renderInput={(params) => (
                <TextField
                    {...params}
                    color="secondary"
                    label="Courses"
                    variant="outlined"
                />
            )}
        />
    );
};
