import { useRecoilState, useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { ListSearchAndSelect } from '../ui';
//import { jobTitles, professionalGoals } from './data';
import {
    jobTitlesState,
    selectedJobTitlesState,
    professionalGoalsState,
    selectedProfessionalGoalsState,
} from './recoil';

const useStyles = makeStyles()((theme) => ({
    root: {},
}));

export const Step2 = () => {
    const { classes } = useStyles();

    const jobTitles = useRecoilValue(jobTitlesState);
    const professionalGoals = useRecoilValue(professionalGoalsState);

    const [selectedJobTitles, setSelectedJobTitles] = useRecoilState(
        selectedJobTitlesState
    );

    const handleJobTitleSelect = (item) => {
        if (selectedJobTitles.length < 3)
            setSelectedJobTitles((prevList) => {
                if (prevList.findIndex((elem) => elem.id === item.id) === -1) {
                    return [item, ...prevList];
                } else {
                    return prevList;
                }
            });
        else alert('You can select up to 3 choices');
    };

    const handleJobTitleDelete = (itemToDelete) => {
        setSelectedJobTitles((prevSelectedJobTitles) => {
            const nextSelectedJobTitles = prevSelectedJobTitles.filter(
                (prevSelectedJobTitle) =>
                    prevSelectedJobTitle.id !== itemToDelete.id
            );

            return nextSelectedJobTitles;
        });

        /* setSelectedProfessionalGoals((prevList) =>
            prevList.filter((item) => item.jobTitleId !== itemToDelete.id)
        ); */

        setSelectedProfessionalGoals((prevList) =>
            prevList.filter((selectedProfessionalGoal) =>
                selectedProfessionalGoal.jobTitleIds.some(
                    ({ job_title }) =>
                        selectedJobTitles
                            .filter(
                                (selectedJobTitle) =>
                                    selectedJobTitle.id !== itemToDelete.id
                            )
                            .findIndex(({ id }) => id === job_title) !== -1
                )
            )
        );
    };

    const [
        selectedProfessionalGoals,
        setSelectedProfessionalGoals,
    ] = useRecoilState(selectedProfessionalGoalsState);

    const handleProfessionalGoalSelect = (item) => {
        if (selectedProfessionalGoals.length < 4)
            setSelectedProfessionalGoals((prevList) => {
                if (prevList.findIndex((elem) => elem.id === item.id) === -1) {
                    return [item, ...prevList];
                } else {
                    return prevList;
                }
            });
        else alert('You can select up to 4 choices');
    };

    const handleProfessionalGoalDelete = (itemToDelete) => {
        setSelectedProfessionalGoals((items) =>
            items.filter((item) => item.id !== itemToDelete.id)
        );
    };

    return (
        <>
            <ListSearchAndSelect
                heading="I'm involved in (or want to be involved in)..."
                list={jobTitles}
                selectedList={selectedJobTitles}
                setSelectedList={setSelectedJobTitles}
                handleItemSelect={handleJobTitleSelect}
                handleItemDelete={handleJobTitleDelete}
            />
            {selectedJobTitles.length !== 0 && (
                <ListSearchAndSelect
                    heading="I want to ..."
                    /* list={professionalGoals.filter(
                        (professionalGoal) =>
                            selectedJobTitles.findIndex(
                                ({ id }) => id === professionalGoal.jobTitleId
                            ) !== -1
                            
                    )} */
                    list={professionalGoals.filter((professionalGoal) =>
                        professionalGoal.jobTitleIds.some(
                            ({ job_title }) =>
                                selectedJobTitles.findIndex(
                                    ({ id }) => id === job_title
                                ) !== -1
                        )
                    )}
                    selectedList={selectedProfessionalGoals}
                    setSelectedList={setSelectedProfessionalGoals}
                    handleItemSelect={handleProfessionalGoalSelect}
                    handleItemDelete={handleProfessionalGoalDelete}
                />
            )}
        </>
    );
};
