import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Checkbox,
    FormControlLabel,
} from '../ui';
import { useState } from 'react';

export const DismissRecommendationDialog = ({
    onClose,
    handleRemove,
    open,
}) => {
    const [check, setCheck] = useState(false);

    return (
        <Dialog disableScrollLock open={open} onClose={onClose}>
            <DialogTitle>
                {'Do you really want to dismiss the recommendation?'}
            </DialogTitle>

            <DialogActions>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={check}
                            onChange={() => setCheck(!check)}
                        />
                    }
                    label="Agree to continue"
                />
                <Button onClick={onClose} color="secondary" variant="outlined">
                    Close
                </Button>
                <Button
                    onClick={() => {
                        handleRemove();
                        setCheck(false);
                        onClose();
                    }}
                    color="secondary"
                    variant="contained"
                    autoFocus
                    disabled={!check}
                >
                    Remove
                </Button>
            </DialogActions>
        </Dialog>
    );
};
