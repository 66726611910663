import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Avatar, Box, IconButton, InputBase, Typography } from '../ui';
import { useFuse } from '../util';
import Dialog from '@mui/material/Dialog';
import { DialogContent, TextField } from '@mui/material';
import { UsersSearchAndSelect } from '../admin/UsersSearchAndSelect';
import { SearchIcon, CloseIcon } from '../icons';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { AvatarBadge } from '../ui/CustomUI';

const useStyles = makeStyles()((theme) => ({
    usersContainer: {
        // backgroundColor: theme.palette.primary.light,
        backgroundColor: theme.palette.background.default,
        // padding: '8px',
        borderRadius: '8px 0 0 8px',
        boxShadow: '5px 0 10px rgba(0,0,0,0.1)',
        [theme.breakpoints.down('lg')]: {
            borderRadius: '8px 8px 0 0',
            // backgroundColor: theme.palette.primary.light,
        },
        // height: '75vh',
    },
    dockedContainer: {
        position: 'fixed',
        bottom: 0,
        right: 30,
        width: '300px',

        zIndex: 1000,
        // backgroundColor: theme.palette.primary.light,
        borderRadius: '8px 8px 0 0',
        boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.2)',
    },
    headingContainer: {
        // padding: '4px',
        padding: theme.spacing(1, 0.5, 1, 1.5),
        marginBottom: 8,
        // display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // backgroundColor: theme.palette.primary.light,
        backgroundColor: '#fff',
        borderRadius: '8px 8px 0 0',
        color: '#fff',
        cursor: 'pointer',
        borderBottom: '1px solid #d1d5db',
    },
    userSearchContainer: {
        // borderRadius: '10px',
        marginBottom: '15px',
        display: 'flex',
        alignItems: 'center',
        // backgroundColor: '#fff',
        backgroundColor: '#edf3f8',
        // boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
        marginInline: theme.spacing(1.5),
        borderRadius: theme.spacing(0.5),
        paddingInline: theme.spacing(1),
    },
    searchInput: {
        // padding: 10,
        padding: theme.spacing(0.5, 1),
        flex: 1,
    },
    iconButton: {
        // padding: 10,
        // padding: theme.spacing(1),
        // borderRadius: 0,
        // color: theme.palette.text.primary,
    },
    users: {
        height: '100%',
        overflow: 'auto',
        // padding: '8px',
        '&::-webkit-scrollbar': {
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#c4bfbf',
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 5px #d6d6d6',
            borderRadius: '10px',
        },
    },
    user: {
        // color: '#fff',
        // borderRadius: '10px',
        // padding: '10px',
        padding: theme.spacing(1, 1.5),
        margin: '5px 0',
        cursor: 'pointer',
        // backgroundColor: theme.palette.primary.light,
        '&:hover': {
            // backgroundColor: theme.palette.primary.light,
            backgroundColor: '#f3f4f6',
            // color: '#fafafa',
        },
    },
    userAvatar: {
        marginRight: 10,
    },
    userName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    userLastMessagePreview: {
        maxWidth: 200,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: '#adb5bd',
    },
    unreadCount: {
        backgroundColor: '#f44336', // Red background for unread count
        color: 'white',
        borderRadius: '50%',
        // fontSize: '0.75rem',
        fontSize: '0.7rem',
        fontWeight: 'bold',
        display: 'inline-block',
        // minWidth: '20px',
        width: '20px',
        height: '20px',
        textAlign: 'center',
    },
    dialog: {
        minWidth: '90vw',
        padding: 40,
        [theme.breakpoints.up('md')]: {
            minWidth: 1000,
        },
    },
}));

export const ChatUsers = ({
    chats = [],
    selectedChatId,
    user,
    selectChat,
    openChat,
    userByIdUpdated,
    getUserChat,
    totalUnreadMessages,
    numOfUnReadMessagesByChat,
    docked,
    minimized,
    toggleMinimized,
    height = '75vh',
}) => {
    const { classes, cx } = useStyles();

    const [searchQuery, setSearchQuery] = useState('');

    const onSearchQueryChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const chatsWithNames = chats.map((chat) => ({
        ...chat,
        chatName:
            chat.groupName ||
            userByIdUpdated[
                chat.users
                    .map(({ user }) => user)
                    .filter((id) => id != user._id)[0]
            ]?.name, // User name of the other person.
    }));

    const { fuseSearch: searchChats } = useFuse(chatsWithNames, ['chatName']);

    const [showUsersDialog, setShowUsersDialog] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const handleDialogClose = () => {
        setShowUsersDialog(false);
    };

    useEffect(() => {
        if (selectedUsers.length) {
            console.log('User selected');
            console.log(selectedUsers[0]);
            handleDialogClose();
            setSelectedUsers([]);
            getUserChat(selectedUsers[0]._id, docked);
        }
    }, [getUserChat, selectedUsers, selectedUsers.length]);

    return (
        <Box
            height={height}
            className={cx(classes.usersContainer, {
                [classes.dockedContainer]: docked,
            })}
            style={{
                // ...(minimized ? { height: 'fit-content', width: 300 } : {}),
                ...(minimized ? { height: 'fit-content' } : {}),
            }}
        >
            <Box
                className={classes.headingContainer}
                style={{
                    ...(minimized ? { margin: 0 } : {}),
                    ...(!docked ? { borderRadius: '8px 0 0 0' } : {}),
                }}
                onClick={() => {
                    if (toggleMinimized) toggleMinimized();
                }}
            >
                <Box display="flex" alignItems={'center'}>
                    {/* <Avatar
                        style={{
                            backgroundColor: userByIdUpdated[user._id]?.color,
                            marginRight: 5,
                        }}
                        src={
                            userByIdUpdated[user._id]?.user_url ||
                            userByIdUpdated[user._id]?.profilePicUrlWP
                        }
                        className={classes.userAvatar}
                        sx={{ width: 32, height: 32 }}
                    >
                        {userByIdUpdated[user._id]?.name
                            ?.charAt(0)
                            ?.toUpperCase()}
                    </Avatar> */}
                    <AvatarBadge
                        style={{
                            backgroundColor: userByIdUpdated[user._id]?.color,
                            marginRight: 5,
                        }}
                        src={
                            userByIdUpdated[user._id]?.user_url ||
                            userByIdUpdated[user._id]?.profilePicUrlWP
                        }
                        className={classes.userAvatar}
                        sx={{ width: 32, height: 32 }}
                        avatarTxt={userByIdUpdated[user._id]?.name
                            ?.charAt(0)
                            ?.toUpperCase()}
                        isOnline
                    />
                    <Typography
                        style={{
                            color: '#000',
                            fontSize: '16px',
                        }}
                        variant="h6"
                        component="h1"
                    >
                        Messaging
                    </Typography>
                    {totalUnreadMessages > 0 && (
                        <Box ml={1}>
                            <Typography
                                variant="caption"
                                className={classes.unreadCount}
                            >
                                {totalUnreadMessages}
                            </Typography>
                        </Box>
                    )}
                    {/* {docked && !minimized && ( */}
                    {docked && (
                        <IconButton
                            className={classes.iconButton}
                            aria-label="search"
                            size="small"
                            style={{
                                // color: '#fafafa',
                                marginLeft: 'auto',
                                borderRadius: '50%',
                            }}
                        >
                            {/* <HorizontalRuleIcon /> */}
                            {!minimized ? (
                                <ExpandMoreIcon />
                            ) : (
                                <ExpandLessIcon />
                            )}
                        </IconButton>
                    )}
                </Box>
            </Box>

            {!minimized && (
                <>
                    <Box className={classes.userSearchContainer}>
                        <InputBase
                            fullWidth
                            className={classes.searchInput}
                            placeholder="Search users"
                            onChange={onSearchQueryChange}
                            value={searchQuery}
                        />
                        {searchQuery !== '' && (
                            <IconButton
                                className={classes.iconButton}
                                aria-label="search"
                                // size="medium"
                                size="small"
                                onClick={() => {
                                    setSearchQuery('');
                                }}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        )}
                        <IconButton
                            className={classes.iconButton}
                            aria-label="search"
                            // size="medium"
                            size="small"
                        >
                            <SearchIcon fontSize="small" />
                        </IconButton>
                        {user.isAdmin && (
                            <IconButton
                                className={classes.iconButton}
                                aria-label="add user"
                                // size="medium"
                                size="small"
                                onClick={() => {
                                    setShowUsersDialog(true);
                                }}
                            >
                                <PersonAddIcon fontSize="small" />
                            </IconButton>
                        )}
                    </Box>
                    <Box
                        className={classes.users}
                        style={{ height: `calc(${height} - 120px)` }}
                    >
                        {searchChats(searchQuery).map((chat, index) => {
                            const chatName = chat.chatName;

                            const otherUserId = chat.users
                                .map(({ user }) => user)
                                .filter((id) => id !== user._id)[0];

                            const isOnline =
                                chat.groupName ||
                                userByIdUpdated[otherUserId]?.socket_ids
                                    ?.length;

                            const lastMessage = chat.messages.length
                                ? chat.messages[chat.messages.length - 1]
                                : null;

                            const numUnreadMessages =
                                numOfUnReadMessagesByChat[chat._id] || 0;

                            return (
                                <Box
                                    display="flex"
                                    className={classes.user}
                                    // style={{
                                    //     color:
                                    //         selectedChatId == chat._id
                                    //             ? 'black'
                                    //             : '#fafafa',
                                    //     backgroundColor:
                                    //         selectedChatId == chat._id
                                    //             ? '#fafafa'
                                    //             : 'inherit',
                                    // }}
                                    onClick={() => {
                                        if (docked) openChat(chat._id);
                                        else selectChat(chat._id);
                                    }}
                                    key={chat._id}
                                >
                                    <Box>
                                        <AvatarBadge
                                            src={
                                                userByIdUpdated[otherUserId]
                                                    ?.user_url ||
                                                userByIdUpdated[otherUserId]
                                                    ?.profilePicUrlWP
                                            }
                                            avatarTxt={chatName
                                                ?.charAt(0)
                                                ?.toUpperCase()}
                                            style={{
                                                backgroundColor:
                                                    userByIdUpdated[otherUserId]
                                                        ?.color,
                                            }}
                                            isOnline={isOnline}
                                        />
                                    </Box>

                                    <Box
                                        flexGrow={1}
                                        display={'flex'}
                                        justifyContent={'center'}
                                        flexDirection={'column'}
                                        ml={1}
                                    >
                                        <Box
                                            // display="flex"
                                            justifyContent="space-between"
                                        >
                                            <Box display="flex">
                                                <Typography
                                                    // variant="subtitle2"
                                                    variant="body1"
                                                    className={classes.userName}
                                                >
                                                    {chatName}
                                                </Typography>
                                                {/* <Box mt="0.1rem" ml="0.1rem">
                                                    {isOnline ? (
                                                        <CheckCircleIcon fontSize="5px" />
                                                    ) : (
                                                        <UnpublishedIcon fontSize="5px" />
                                                    )}
                                                </Box> */}
                                            </Box>

                                            <Typography variant="caption">
                                                {chat.messages.length > 0 &&
                                                    moment(
                                                        chat.messages[
                                                            chat.messages
                                                                .length - 1
                                                        ]?.sentAt
                                                    ).fromNow()}
                                            </Typography>
                                        </Box>

                                        {lastMessage && (
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Typography
                                                    variant="body2"
                                                    className={
                                                        classes.userLastMessagePreview
                                                    }
                                                >
                                                    {`${
                                                        lastMessage.from ===
                                                        user._id
                                                            ? 'You: '
                                                            : ''
                                                    }${lastMessage.body}`}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                    {numUnreadMessages > 0 && (
                                        <Box ml="auto">
                                            <Typography
                                                variant="caption"
                                                className={classes.unreadCount}
                                            >
                                                {numUnreadMessages}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            );
                        })}
                        {searchChats(searchQuery).length === 0 && (
                            <Box className={classes.user}>
                                <Typography
                                    className={classes.userName}
                                    align="center"
                                >
                                    No chats found.
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </>
            )}

            <Dialog
                open={showUsersDialog}
                onClose={handleDialogClose}
                classes={{ paper: classes.dialog }}
                fullWidth
            >
                <div className={classes.dialogContent}>
                    <Box display="flex">
                        <Typography
                            variant="h6"
                            style={{ marginRight: 'auto' }}
                        >
                            Select user
                        </Typography>

                        <IconButton
                            aria-label="close"
                            onClick={handleDialogClose}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <DialogContent>
                        <UsersSearchAndSelect
                            selected={selectedUsers}
                            setSelected={setSelectedUsers}
                            admin
                            instructor
                            clientId={user.client_id}
                            searchInputSize="small"
                        />
                    </DialogContent>
                </div>
            </Dialog>
        </Box>
    );
};
