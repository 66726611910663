import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useNavigate } from 'react-router-dom';
import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    root: {
        color: '#666',
        fontSize: '1em',
        '&:hover': {
            color: '#337ab7',
            textDecoration: 'none',
            cursor: 'pointer',
        },
    },
    Text: {
        color: '#666',
        fontSize: '1em',
    },
}));

function MyBreadcrumbs({ current, collectionPageName, collectionPageSlug }) {
    const { classes } = useStyles();
    let navigate = useNavigate();
    const goToHome = () => {
        navigate({ pathname: '/' });
    };
    const goToCollection = () => {
        navigate({
            pathname: `/${collectionPageSlug}`,
        });
    };
    return (
        <>
            <Box style={{ padding: '0.675rem 0 0 0' }}>
                <Breadcrumbs separator="›">
                    <Link onClick={goToHome} className={classes.root}>
                        Home
                    </Link>
                    <Link onClick={goToCollection} className={classes.root}>
                        {collectionPageName}
                    </Link>
                    <Typography className={classes.root}>
                        {ReactHtmlParser(current)}
                    </Typography>
                </Breadcrumbs>
            </Box>
        </>
    );
}

export default MyBreadcrumbs;
