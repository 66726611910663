import { useState, useEffect } from 'react';
import { useCurrentUser } from '../my-account/useCurrentUser';
import axios from 'axios';

export const useOnboardingAnswers = () => {
    const [recommendations, setRecommendations] = useState([]);
    const [isLoading, setIsLoading] = useState();

    const { user } = useCurrentUser();

    useEffect(() => {
        (async () => {
            if (user) {
                try {
                    setIsLoading(true);
                    const res = await axios.get(
                        `/api/onboarding/recommendations/${user?._id}`
                    );
                    const journeys = res.data.recommendedJourneyIds;
                    const onDemand = res.data.recommendedThinkificCourses;
                    setRecommendations([...journeys, ...onDemand]);
                    setIsLoading(false);
                } catch (err) {
                    setIsLoading(false);
                    console.log('Error In StudentOnboardingPage.js');
                    console.log(err);
                }
            }
        })();
    }, [user]);

    return { recommendations, isLoading };
};
