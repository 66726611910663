import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import axios from 'axios';
import {
    DescriptionOutlinedIcon,
    ExpandMoreIcon,
    VideocamOutlinedIcon,
} from '../icons';
import { EnrollDialog } from './EnrollDialog';
import { userTrack } from '../util/tracking';

import {
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Button,
    Typography,
    Loading,
} from '../ui';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Checkbox from '@mui/material/Checkbox';
import { useCurrentUser } from '../my-account/useCurrentUser';
import { LoginModal } from '../../src/auth/LoginModal';
import { useGetPurchasedCourse } from '../dashboard';
import { useUser } from '../auth/useUser';
import { useSetRecoilState } from 'recoil';
import { saveRedirectDestination } from '../billing/recoil';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles()((theme) => ({
    chapterTitle: {
        fontSize: '1.1rem',
        fontWeight: 'bold',
        marginLeft: 50,
        color: theme.palette.grey[700],
    },
    chapterNumber: {
        fontSize: 'min(2.2rem, 9vw)',
        fontWeight: 'bold',
        color: theme.palette.grey[700],
        marginLeft: 20,
    },
    lessonTitle: { lineHeight: '1.8rem' },
    lessonTitles: {
        margin: '0px 80px',
        cursor: 'pointer',
        borderTop: `1px solid ${theme.palette.grey[400]}`,
    },
    lessonIcon: {
        fill: '#ffa147',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '24rem',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #fff',
        borderRadius: '6px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 1, 1),
    },
}));
export const CourseChapterAccordion = ({
    name,
    chapterNumber,
    chapterId,
    course,
    courseId,
    domain,
    time,
}) => {
    const { classes } = useStyles();
    const [open, setOpen] = React.useState(false);
    const [dontRemindMe, setDontRemindMe] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { user: userDetails } = useCurrentUser();
    const { user } = useUser();
    const [promptLogin, setPromptLogin] = useState(false);
    const [chapterContents, setChapterContents] = useState([]);
    const [selectedChapter, setSelectedChapter] = useState(null);
    const { purchasedCourses } = useGetPurchasedCourse();
    const [savedCourse, setSavedCourse] = useState([]);
    const saveDestination = useSetRecoilState(saveRedirectDestination);
    let navigate = useNavigate();
    const [openEnroll, setOpenEnroll] = useState(false);
    const courseCost = 10;

    const handleOpen = (chapter) => {
        if (userDetails) {
            if (!dontRemindMe) {
                setSelectedChapter(chapter);
                console.log(chapter);
                setOpen(true);
            }
        }
        if (!userDetails) {
            setPromptLogin(true);
        }
    };

    useEffect(() => {
        if (userDetails) {
            setSavedCourse(userDetails.user_save || []);
        }
    }, [userDetails]);

    const trackThisCourse = () => {
        const trackingObject = {
            is_user_registered: localStorage.getItem('userId') ? true : false,
            user: localStorage.getItem('userId'),
            page_slug: window.location.pathname,
            activity_type: 'CLICK',
            activity_value: course.name,
            course_id: course.id,
        };
        userTrack('click-enroll-on-demand', trackingObject);
    };
    const handleClose = () => {
        setOpen(false);
        setSelectedChapter(null);
    };

    const purchasedCourseCheck = (id) => {
        const isCoursePresent = purchasedCourses.filter(
            (item) => item.id === id
        );
        if (isCoursePresent.length > 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleGetClass = async () => {
        if (purchasedCourseCheck(course.id)) {
            await goToCourse(course.slug);
            return;
        }
        if (user) {
            saveDestination(window.location.pathname);
            setOpenEnroll(true);
            setOpen(false);
        } else {
            saveDestination(window.location.pathname);
            navigate('/credits/checkout');
        }
    };
    const handleAccess = async (chapter) => {
        const isPurchased = purchasedCourseCheck(course.id);
        if (userDetails?.user_subscription_id === '') {
            if (isPurchased) {
                await goToCourse(course.slug);
            } else {
                setOpenEnroll(true);
            }
        } else {
            if (!savedCourse?.includes(course.id)) {
                console.log('Not saved');
                if (isPurchased) {
                    await goToCourse(course.slug);
                } else {
                    if (chapter) {
                        console.log(chapter);
                        handleOpen(chapter);
                    } else {
                        await onSaveCourse(course.id);
                        await goToCourse(course.slug);
                        setOpen(false);
                    }
                }
            } else {
                console.log('saved');
                await goToCourse(course.slug);
            }
        }
        //setOpen(false);
    };

    const onSaveCourse = async (courseProductID) => {
        if (savedCourse.includes(courseProductID)) {
            /* try {
                const response = await axios.put(
                    `api/users/saveUnSaveCourse/${userDetails._id}`,
                    { id: courseProductID, action: 'unsave' }
                );
               */
        } else {
            try {
                const newCourseObject = { ...course, domain, time };
                const response = await axios.put(
                    `/api/users/saveUnSaveCourse/${userDetails._id}`,
                    {
                        id: courseProductID,
                        action: 'save',
                        course: newCourseObject,
                    }
                );
                const savedCourses = response.data.user.user_save;
                const UniSavedCourses = [...new Set(savedCourses)];
                //setMessage(`You are enrolled for ${course.name}`);
                setSavedCourse(UniSavedCourses);
            } catch (err) {
                // alert(err.message);
            }
        }
    };
    const goToCourse = async (slug) => {
        try {
            // let goto = slug;
            // if (selectedChapter) {
            //     const takeUrl = selectedChapter.take_url;
            //     goto = takeUrl.substr(takeUrl.indexOf('take/') + 5);
            //     console.log(goto);
            // }
            setLoading(true);
            const authtoken = await user.getIdToken();
            await axios.post(`/api/users/thinkific/enroll`, {
                courseId,
                userId: userDetails.user_thinkific_id,
            });
            const response = await axios.get(
                `/api/sso/${encodeURIComponent(slug)}/thinkific?clientId=${
                    userDetails.client_id
                }`,
                {
                    headers: {
                        authtoken,
                        firstName: userDetails.user_firstName,
                        lastName: userDetails.user_lastName,
                    },
                }
            );
            const ssoUrl = response.data;
            window.open(ssoUrl, '_blank');
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (name)
            (async () => {
                try {
                    const res = await axios.get(
                        `/api/content/chapters/${chapterId}/contents`
                    );
                    setChapterContents(res.data);
                } catch (err) {
                    console.log('Error In MainSection.js');
                    console.log(err);
                }
            })();
    }, [chapterId, name]);

    return (
        <Accordion className={classes.accordian}>
            <AccordionSummary
                expandIcon={
                    <ExpandMoreIcon
                        fontSize="large"
                        style={{ fill: 'black' }}
                    />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordianSummary}
            >
                <Grid container alignItems="center">
                    <Grid item xs={1}>
                        <Typography className={classes.chapterNumber}>
                            {chapterNumber}
                        </Typography>
                    </Grid>
                    <Grid item xs={11}>
                        <Typography className={classes.chapterTitle}>
                            {name}
                        </Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            {!userDetails && promptLogin ? (
                <LoginModal
                    onRequestClose={() => {
                        setPromptLogin(false);
                    }}
                    open={!userDetails}
                    title={'Access this course and more.'}
                    signUpLink="/pricing"
                />
            ) : (
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    // BackdropComponent={Backdrop}
                    // BackdropProps={{
                    //     timeout: 500,
                    // }}
                >
                    <Box>
                        <div className={classes.paper}>
                            <Grid
                                container
                                justifyContent="left"
                                direction="row"
                            >
                                <Grid
                                    xs={11}
                                    id="transition-modal-title"
                                    style={{ fontSize: '15px' }}
                                >
                                    To access content, click on the
                                </Grid>
                                <Grid
                                    xs={1}
                                    container
                                    justifyContent="end"
                                    alignItems="end"
                                    onClick={handleClose}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <CloseIcon
                                        style={{
                                            fontSize: '22px',
                                            marginLeft: '10px',
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                xs={12}
                                justifyContent="center"
                                style={{
                                    textAlign: 'center',
                                    margin: '15px 0px',
                                }}
                            >
                                {userDetails &&
                                userDetails.user_subscription_id !== '' ? (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        disabled={loading}
                                        onClick={() => {
                                            handleAccess();
                                        }}
                                    >
                                        {loading ? (
                                            <Loading height="fit-content" />
                                        ) : (
                                            'ENROLL'
                                        )}
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleGetClass}
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <Loading height="fit-content" />
                                        ) : purchasedCourseCheck(course.id) ? (
                                            'Launch'
                                        ) : (
                                            'Get class'
                                        )}
                                    </Button>
                                )}
                            </Grid>
                            <Grid container direction="row">
                                <Grid
                                    xs={7}
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="left"
                                    style={{ color: 'grey' }}
                                >
                                    <label>
                                        button at the top right of page.
                                    </label>
                                </Grid>
                                <Grid
                                    xs={5}
                                    container
                                    // justifyContent="center"
                                    alignItems="end"
                                    justifyContent="end"
                                    style={{
                                        cursor: 'pointer',
                                        display: 'block',
                                        fontSize: '12px',
                                        textAlign: 'end',
                                    }}
                                >
                                    <Checkbox
                                        checked={dontRemindMe}
                                        onClick={() =>
                                            setDontRemindMe(!dontRemindMe)
                                        }
                                        size="small"
                                        required
                                        style={{ padding: '2px' }}
                                    />
                                    Don’t remind me​
                                </Grid>
                            </Grid>
                        </div>
                    </Box>
                </Modal>
            )}

            <AccordionDetails className={classes.lessonTitles}>
                <Grid container direction="column" spacing={1}>
                    {chapterContents
                        .filter(({ name }) => !name.includes('(draft)'))
                        .map((chapter, index) => (
                            <Grid item key={index}>
                                <Grid
                                    container
                                    direction="row"
                                    wrap="nowrap"
                                    spacing={1}
                                    onClick={() => {
                                        handleAccess(chapter);
                                    }}
                                >
                                    <Grid item>
                                        {chapter.contentable_type ===
                                        'Lesson' ? (
                                            <VideocamOutlinedIcon
                                                className={classes.lessonIcon}
                                            />
                                        ) : (
                                            <DescriptionOutlinedIcon
                                                className={classes.lessonIcon}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            className={classes.lessonTitle}
                                        >
                                            {chapter.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                </Grid>
            </AccordionDetails>
            <EnrollDialog
                open={openEnroll}
                setOpen={setOpenEnroll}
                track={trackThisCourse}
                course={course}
                user={userDetails}
                courseCost={courseCost}
            />
        </Accordion>
    );
};
