// import styled from 'styled-components';
import axios from 'axios';
import CoursePageHeader from './CoursePageHeader';
import MyBreadcrumbs from './MyBreadcrumbs';
import CircularSpinner from '../custom-components/CircularSpinner';
import { useState, useEffect, useMemo } from 'react';
import { useUser } from '../auth/useUser';
import { useNavigate, useParams } from 'react-router-dom';
// import { SaveCourse } from './SaveCourse';
import { useCurrentUser } from '../my-account/useCurrentUser';
// import { CommentsButton, CourseLikeButton } from '../comments';
import CourseTabs from './CourseTabs';
import CourseOverview from './CourseOverview';
import SkillsSection from './SkillsSection';
import NewLearningOutcomes from './NewLearningOutcomes';
import NewPrerequisites from './NewPrerequisites';
import Attend from './Attend';
import Curriculum from './Curriculum';
import Instructor from './Instructor';
import {
    // ActionContainer,
    FlexLayout,
    PageWrapper,
    SectionContainer,
    SectionHeading,
    SmallSectionText,
} from '../custom-components/styled-components';
import AllPlans from '../plans/AllPlans';
import CourseFAQ from './CourseFAQ';
import NewRelatedCourses from './NewRelatedCourses';
import { HelmetWrapper } from '../seo';
import { coursesDetailRoute as route } from '../seo/routeMetaData';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    PricingContainer: {
        marginTop: '2rem',
    },
}));
/* COMPONENT */
const NewMainSection = () => {
    const { classes } = useStyles();
    const { user: userDetails } = useCurrentUser();
    const [loading, setLoading] = useState(true);
    const [courseDataWordPress, setCourseDataWordPress] = useState({});
    const [course, setCourse] = useState({});
    const { isLoading: isUserLoading, user } = useUser();
    const { courseSlug } = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                if (isUserLoading === true) return;
                const authtoken = await user?.getIdToken();
                const resCourse = await axios.get(
                    `/api/content/courses/${courseSlug}`,
                    {
                        headers: {
                            authtoken,
                        },
                    }
                );

                // const resCourseDataWordPress = await axios.get(
                //     `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-json/headless/on-demand/single?id=${resCourse.data.productable_id}`
                // );

                const resCourseDataWordPress = await axios.get(
                    `/api/courses/${resCourse.data.productable_id}`
                );

                //console.log(resCourse.data.productable_id);
                if (resCourseDataWordPress.data === null) {
                    navigate('/404');
                }
                /* setCourseDuration(resCourseDuration.data); */
                setCourseDataWordPress(resCourseDataWordPress.data);

                setCourse({
                    ...resCourse.data,
                    /* instructor: { ...resInstructor.data }, */
                });

                setLoading(false);
            } catch (err) {
                navigate('/category/all/courses');
                console.log('Error In MainSection.js');
                setLoading(false);
                console.log(err);
            }
        })();
    }, [courseSlug, navigate, isUserLoading, user]);

    const getCollectionPageNameAndSlug = useMemo(() => {
        if (course.collection_ids && course.collection_ids.includes(248139)) {
            return { name: 'Certification', slug: 'certifications' };
        } else if (
            course.collection_ids &&
            course.collection_ids.includes(636641)
        ) {
            return { name: 'Test Prep', slug: 'test-prep/all/tests' };
        } else {
            return { name: 'Courses', slug: 'category/all/courses' };
        }
    }, [course.collection_ids]);

    if (loading) {
        return <CircularSpinner show={true} />;
    }

    return (
        <PageWrapper>
            <HelmetWrapper
                route={route}
                openGraphImage={
                    course?.thumbnail || courseDataWordPress?.thumbnail
                }
                title={course?.name}
            />
            <FlexLayout>
                {/* BREADCRUMBS */}
                <MyBreadcrumbs
                    current={course?.name}
                    collectionPageName={getCollectionPageNameAndSlug.name}
                    collectionPageSlug={getCollectionPageNameAndSlug.slug}
                />

                {/* COURSE ACTIONS */}
                {/* <ActionContainer>
                    <SaveCourse course={course} user={userDetails} />

                    <CommentsButton
                        courseId={course.id}
                        courseSlug={course.slug}
                        courseName={course.name}
                    />

                    <CourseLikeButton
                        courseId={course.id}
                        courseSlug={course.slug}
                        courseName={course.name}
                        defaultLikes={courseDataWordPress?.baseClaps || 0}
                    />
                </ActionContainer> */}
            </FlexLayout>

            {/* COURSE HEADER */}
            <CoursePageHeader
                courseData={course}
                wpCourseData={courseDataWordPress}
                userDetails={userDetails}
            />

            {/* TABS */}
            <CourseTabs
                userDetails={userDetails}
                course={course}
                wpCourseData={courseDataWordPress}
            />

            {/* OVERVIEW SECTION */}
            <CourseOverview wpCourseData={courseDataWordPress} />

            {/* SKILL GAIN */}
            <SkillsSection wpCourseData={courseDataWordPress} />

            {/* LEARNING OUTCOMES */}
            <NewLearningOutcomes wpCourseData={courseDataWordPress} />

            {/* PREREQUISITES */}
            <NewPrerequisites wpCourseData={courseDataWordPress} />

            {/* ATTERND */}
            <Attend wpCourseData={courseDataWordPress} />

            {/* MODULES */}
            <Curriculum
                courseData={course}
                wpCourseData={courseDataWordPress}
            />

            {/* INSTRUCTOR */}
            <Instructor
                courseData={course}
                wpCourseData={courseDataWordPress}
            />

            {/* RELATED COURSES */}
            {/* <NewRelatedCourses wpCourseData={courseDataWordPress} /> */}

            {/* plan */}
            {!userDetails && (
                <SectionContainer
                    id="pricing"
                    className={classes.PricingContainer}
                >
                    <SectionHeading>
                        Take the next step and invest in a better you
                    </SectionHeading>
                    <AllPlans hideSections={true} hideHelmet={true} />
                </SectionContainer>
            )}

            <CourseFAQ />

            <SmallSectionText>
                *Where courses have been offered multiple times, the "#
                Students" includes all students who have enrolled. The "%
                Recommended" shown is also based on this data.
            </SmallSectionText>
        </PageWrapper>
    );
};

// const PricingContainer = styled(SectionContainer)`
//     margin-top: 2rem;
// `;

export default NewMainSection;
