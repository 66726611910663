import { Container } from '@mui/material';
import NewMainSection from './NewMainSection';

const NewcoursesDetailsPage = () => {
    return (
        <Container disableGutters maxWidth="xl" style={{minHeight: '80vh'}}>
            <NewMainSection />
        </Container>
    );
};

export default NewcoursesDetailsPage;
