import { useEffect, useState } from 'react';
import {
    CLOUD_COLLECTION_ID,
    ALL_COLLECTION_ID,
    FULL_STACK_COLLECTION_ID,
    CYBERSECURITY_COLLECTION_ID,
    FINANCE_COLLECTION_ID,
    BUSINESS_COLLECTION_ID,
    DATA_SCIENCE_COLLECTION_ID,
    DEVOPS_AGILITY_COLLECTION_ID,
} from './collectionIds';

import {
    CATEGORY_AGILITY_URL,
    CATEGORY_BUSINESS_URL,
    CATEGORY_CLOUD_URL,
    CATEGORY_CYBERSECURITY_URL,
    CATEGORY_DATA_SCIENCE_URL,
    CATEGORY_FINANCE_URL,
    CATEGORY_FULL_STACK_URL,
} from '../img';

export const useCategoryDetails = (categoryName) => {
    const [categoryDetails, setCategoryDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (categoryName) {
            const currentCategory = categories.filter(
                ({ categoryName: categoryNameInTabs }) =>
                    categoryNameInTabs === categoryName
            )[0];
            setCategoryDetails(currentCategory);
        }
        setIsLoading(false);
    }, [categoryName]);

    return { categoryDetails, isLoading };
};

export const categories = [
    {
        domain: 'All',
        headingTop: 'On Demand',
        headlineDescription:
            'Time is precious so never delay thoughtful action',
        marketingDetails: {
            heading: `Did you know… `,
            description: `Every second 60,000 searches are performed on Google (>1.2 trillion/per year). But <u style="color:blue"><a href="${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/portfolio/data-science-and-machine-learning-without-mathematics/">you don’t need to be a mathematician</a></u> to learn how.</b> `,
            linkTo: `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/portfolio/data-science-and-machine-learning-without-mathematics/`,
        },
        url:
            'https://firebasestorage.googleapis.com/v0/b/starweaver-group-website.appspot.com/o/Category%20Images%2Fall-courses.png?alt=media&token=02926f19-5073-4f60-b924-742691004170',
        redirect: '/courses',
        //change these
        categoryName: 'all',
        journeyDomain: 'all',
        collectionId: ALL_COLLECTION_ID,
    },
    {
        domain: 'Data Science',
        headlineDescription: 'It’s neither complex nor difficult ',
        marketingDetails: {
            heading: `Did you know… `,
            description: `Every second 60,000 searches are performed on Google (>1.2 trillion/per year). But <u style="color:blue"><a href="${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/portfolio/data-science-and-machine-learning-without-mathematics/">you don’t need to be a mathematician</a></u> to learn how.</b> `,
            linkTo: `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/portfolio/data-science-and-machine-learning-without-mathematics/`,
        },
        url: CATEGORY_DATA_SCIENCE_URL,
        redirect: '/courses/data-science/',
        categoryName: 'data-science',
        journeyDomain: 'data-science',
        collectionId: DATA_SCIENCE_COLLECTION_ID,
    },
    {
        domain: 'Cloud',
        headlineDescription: `“The World is Flat!”  `,
        marketingDetails: {
            heading: `It is “flat” because of the Cloud`,
            description: `Before the Cloud was “a thing,” Thomas Friedman’s famous book described how an "interconnected world" sparked the digital revolution and instantaneously changed everything. <u style="color:blue"><a href="https://go.starweaver.com/courses/cloud-computing-fundamentals-course">Get started.</a></u> `,
            linkTo:
                'https://go.starweaver.com/courses/cloud-computing-fundamentals-course ',
        },
        url: CATEGORY_CLOUD_URL,
        redirect: '/courses/cloud/',
        categoryName: 'cloud',
        journeyDomain: 'cloud',
        collectionId: CLOUD_COLLECTION_ID,
    },
    {
        domain: 'Full Stack',
        headlineDescription: `Modern and innovative applications built to last `,
        marketingDetails: {
            heading: `Full Stack developers build both front- and back-end components`,
            description: `To stand out in the crowd, top developers are learning <u style="color:blue"><a href="${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/portfolio/react-vs-angular-vs-vue/">best-in-class user-facing technologies</a></u> as well as how to integrate the systems and databases that support those. `,
            linkTo: `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/portfolio/react-vs-angular-vs-vue/ `,
        },
        url: CATEGORY_FULL_STACK_URL,
        redirect: '/courses/full-stack/',
        categoryName: 'full-stack',
        journeyDomain: 'full-stack',
        collectionId: FULL_STACK_COLLECTION_ID,
    },
    {
        domain: 'Cybersecurity',
        headlineDescription: `Cyber vulnerabilities are costly `,
        marketingDetails: {
            heading: `Did you know… `,
            description: `Cyber-crime will cost the world USD 6 trillion this year and is now more profitable than the combined global illegal drugs trade. <u style="color:blue"><a href="${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/portfolio/10-reasons-why-cybersecurity-is-important-in-2021/">Why?</a></u> `,
            linkTo: `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/portfolio/10-reasons-why-cybersecurity-is-important-in-2021/`,
        },
        url: CATEGORY_CYBERSECURITY_URL,
        redirect: '/courses/cybersecurity/',
        categoryName: 'cybersecurity',
        journeyDomain: 'cybersecurity',
        collectionId: CYBERSECURITY_COLLECTION_ID,
    },
    {
        domain: 'DevOps/Agility',
        headlineDescription: `Faster, smoother & less costly `,
        marketingDetails: {
            heading: `Why learning Agile and DevOps is essential `,
            description: `While DevOps and Agility used to describe a “culture” and “set of processes” <u style="color:blue"><a href="https://go.starweaver.com/courses/the-world-according-to-devops-an-introduction ">to run a project or organization,</a></u> it is now also supported by a massive infrastructure of tools and technologies. `,
            linkTo:
                'https://go.starweaver.com/courses/the-world-according-to-devops-an-introduction ',
        },
        url: CATEGORY_AGILITY_URL,
        redirect: '/courses/devops-agility/',
        categoryName: 'devops-agility',
        journeyDomain: 'agility',
        collectionId: DEVOPS_AGILITY_COLLECTION_ID,
    },
    {
        domain: 'Finance',
        headlineDescription: `Domain knowledge powers successful careers `,
        marketingDetails: {
            heading: `Both tech and finance skills are essential `,
            description: `Financial services are the pure intangible “information product,” combining technology with ideas (finance). To work effectively (and rise in the ranks), understanding both is essential. <u style="color:blue"><a href="https://go.starweaver.com/journeys/foundations-of-finance-retro-learning-journey">Get started.</a></u> `,
            linkTo:
                'https://go.starweaver.com/journeys/foundations-of-finance-retro-learning-journey ',
        },
        url: CATEGORY_FINANCE_URL,
        redirect: '/courses/finance/',
        categoryName: 'finance',
        journeyDomain: 'finance',
        collectionId: FINANCE_COLLECTION_ID,
    },
    {
        domain: 'Business',
        headlineDescription: `Business skills help technologists shine `,
        marketingDetails: {
            heading: `Did you know… `,
            description: `73%+ of employers more favorably hire and promote people with strong writing skills. Clear writing is a sign of clear thinking, which you can improve by <u style="color:blue"><a href="https://go.starweaver.com/courses/business-and-technical-writing-immersion">perfecting your writing skills.</a></u>`,
            linkTo:
                'https://go.starweaver.com/courses/business-and-technical-writing-immersion ',
        },
        url: CATEGORY_BUSINESS_URL,
        redirect: '/courses/business/',
        categoryName: 'business',
        journeyDomain: 'business',
        collectionId: BUSINESS_COLLECTION_ID,
    },
];
