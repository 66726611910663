// import styled from 'styled-components';
import { StudentOnboardingPage } from '../student-onboarding/StudentOnboardingPage';
import { Box } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    FirstTimeUserContainer: {
        padding: '0',
        margin: '0 0 5rem 0',
        '& > div': {
            margin: '0 !important',
            padding: '0 !important',
            width: '100% !important',
            maxWidth: '100% !important',
        },
    },
}));

// const FirstTimeUserContainer = styled.section`
//     padding: 0;
//     margin: 0 0 5rem 0;
//     & > div {
//         margin: 0 !important;
//         padding: 0 !important;
//         width: 100% !important;
//         max-width: 100% !important;
//     }
// `;

const FirstTimeUser = () => {
    const { classes } = useStyles();
    return (
        <section className={classes.FirstTimeUserContainer}>
            <StudentOnboardingPage />
        </section>
    );
};

export default FirstTimeUser;
