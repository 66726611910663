import React, { useMemo } from 'react';
import { useCurrentUser } from '../my-account/useCurrentUser';
import { useGetPrivateClasses } from '../dashboard';
import { useBundleCourses } from './useBundleCourses';
import { Container, Box, Typography, Loading, Grid } from '../ui';
// import { CourseCard } from './CourseCards';
import SWCourseCards from '../custom-components/SWCourseCards';
import { Button, LinearProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ReactHtmlParser from 'react-html-parser';
// import styled from 'styled-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { alertState, severity } from '../app/recoil';
import { getPurchasedCourseState } from '../dashboard/Recoil/Selector';
import axios from 'axios';
import { useUser } from '../auth';

export const PrivateCourseTab = ({ courses, isLoading, user }) => {
    // const { user } = useCurrentUser();
    // const { privateCourse, isLoading2 } = useGetPrivateClasses(user);
    // const { courses, isLoading } = useBundleCourses(bundleId);
    // const purchasedCourse = useRecoilValue(getPurchasedCourseState);
    const { user: firebaseUser } = useUser();
    const setAlert = useSetRecoilState(alertState);
    // const formattedCourses = useMemo(() => {
    //     return privateCourse.map((data) => {
    //         const newCourse = {
    //             title: data.name,
    //             image: data.course_card_image_url,
    //             instructor: data.instructor_names,
    //             time: data.time,
    //             domain: data.domain || '',
    //             id: data.id,
    //             productId: data.productable_id,
    //             description: data.description,
    //             rating: data.rating,
    //             baseClaps: data.baseClaps,
    //             level: data.level,
    //             link: `/courses/${data.slug}`,
    //             likes: data.likes || 0,
    //             comments: data.comments || 0,
    //             noOfStudents: data.noOfStudents,
    //             courseTag: data.courseTag,
    //             skills: data.keywords,
    //             hours: data.hours,
    //             minutes: data.minutes,
    //             includedInCurationLibrary: data.includedInCurationLibrary,
    //         };

    //         return newCourse;
    //     });
    // }, [privateCourse]);
    // const purchasedCourseCheck = (id) => {
    //     const isCoursePresent = purchasedCourse.filter(
    //         (item) => item.id === id
    //     );
    //     if (isCoursePresent.length > 0) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // };

    const handleLaunch = async (course) => {
        // if (
        //     user.user_subscription_id === '' &&
        //     !purchasedCourseCheck(course.id)
        // ) {
        //     // console.log('UPGRADE_PLAN');
        //     setAlert({
        //         show: true,
        //         message: 'Upgrade plan',
        //         severity: severity.INFO,
        //     });
        //     return;
        // }
        try {
            await axios.post(`/api/users/thinkific/enroll`, {
                courseId: course.productable_id || course.id,
                userId: user.user_thinkific_id,
            });
            const response = await axios.get(
                `/api/sso/${course.slug}/thinkific?clientId=${user.client_id}`,
                {
                    firstName: user.user_firstName,
                    lastName: user.user_lastName,
                }
            );
            const ssoUrl = response.data;
            window.open(ssoUrl, '_blank');
        } catch (e) {
            // console.log(e);
            setAlert({
                show: true,
                severity: severity.ERROR,
                message: 'Something went wrong',
            });
        }
    };

    return (
        <Box>
            <Container maxWidth="xl">
                {!isLoading ? (
                    courses.length > 0 ? (
                        <Grid container>
                            {courses.map((course) => (
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    {/* <CourseCard course={course} domain="" /> */}
                                    {/* <SWCourseCards course={course} domain="" /> */}

                                    <MyContentCourseCard
                                        title={course.title}
                                        thumbnail={
                                            course.card_image_url ||
                                            course.course_card_image_url ||
                                            course?.image
                                        }
                                        content={course.description}
                                        course={course}
                                        handleLaunch={handleLaunch}
                                        hours={course.hours}
                                        minutes={course.minutes}
                                        percentageCompleted={
                                            course.percentage_completed
                                        }
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Typography variant="h3">
                            We are adding more articles please wait!!
                        </Typography>
                    )
                ) : (
                    <>{<Loading />}</>
                )}
            </Container>
            {/* <Container maxWidth="xl">
                {!isLoading2 ? (
                    privateCourse.length > 0 ? (
                        <Grid container>
                            {formattedCourses.map((course) => (
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <CourseCard course={course} domain="" />
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Typography variant="h3">
                            We are adding more articles please wait!!
                        </Typography>
                    )
                ) : (
                    <Loading />
                )}
            </Container> */}
        </Box>
    );
};

const useStyles = makeStyles()((theme) => ({
    root: {
        width: '100%',
        marginTop: '1rem',
        height: 5,
    },
    cardContainer: {
        height: '100%',
        boxShadow:
            '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);',
        borderRadius: '0.5rem',
        overflow: 'hidden',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        transition: 'all 150ms linear',
        '&:hover': {
            transform: 'translateY(-10px)',
        },
    },
    imgContainer: {
        maxHeight: '12.5rem',
        width: '100%',
        '& > img': {
            height: '100%',
            width: '100%',
        },
    },
    cardContentWrapper: {
        padding: '0 0.5rem',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardDescription: {
        position: 'relative',
        overflow: 'hidden',
        height: '5rem',
        border: '2px solid red',
        '&::after': {
            content: '""',
            position: 'absolute',
            border: '2px solid green',
            left: 0,
            bottom: 0,
            width: '100%',
            height: '2em',
            zIndex: '1',
            pointerEvents: 'none',
            backgroundImage:
                'linear-gradient(to bottom, rgba(250,250,250, 0), rgba(250,250,250, 1) 90%)',
        },
    },
    StyledDescription: {
        height: '5rem',
        overflow: 'hidden',
        position: 'relative',
        '&::after': {
            content: '""',
            width: '100%',
            position: 'absolute',
            bottom: '0',
            left: '0',
            height: '2.5rem',
            backgroundImage:
                'linear-gradient(to bottom, rgba(250, 250, 250, 0), rgba(250, 250, 250, 0.9) 90%)',
            zIndex: '1',
            pointerEvents: 'none',
        },
    },
}));

const MyContentCourseCard = (props) => {
    const {
        title,
        thumbnail,
        content,
        course,
        handleLaunch,
        hours,
        minutes,
        percentageCompleted,
    } = props;
    const { classes } = useStyles();

    const percentage = useMemo(() => {
        return Math.round(percentageCompleted * 100 || 0);
    }, [percentageCompleted]);

    return (
        <div
            className={classes.cardContainer}
            onClick={(e) => {
                e.stopPropagation();
                handleLaunch(course);
            }}
        >
            {/* CARD IMG CONTAINER */}
            <div className={classes.imgContainer}>
                <img src={thumbnail} height={200} width={300} alt={title} />
            </div>

            {/* CARD CONTENT CONTAINER */}
            <div className={classes.cardContentWrapper}>
                <h2 className={classes.cardTitle}>
                    {ReactHtmlParser(title?.replace('(Thinkific)', ''))}
                </h2>
                <Box color="#4e4e4e" fontWeight={600}>
                    {course?.instructors?.map((instructor) => {
                        return (
                            <a
                                key={instructor?.instructor}
                                href={`/channels/${instructor?.instructorSlug}/on-air`}
                                target="_blank"
                                onClick={(e) => e.stopPropagation()}
                                rel="noreferrer"
                                style={{ display: 'inline-block' }}
                            >
                                <span className={classes.instructorName}>
                                    {instructor?.instructor}&nbsp;&#124;&nbsp;
                                </span>
                            </a>
                        );
                    })}
                    <span className={classes.instructorName}>
                        {hours && hours != 0 && (
                            <>
                                {hours} hour
                                {hours != 1 && <>s</>}
                            </>
                        )}
                        {minutes && minutes != 0 && <> {minutes} minutes</>}
                    </span>
                </Box>
                <p className={classes.StyledDescription}>{content}</p>
                <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    style={{ marginTop: 'auto' }}
                >
                    Go To Course
                </Button>
            </div>

            {/* CARD PROGRESS CONTAINER */}
            <Box className={classes.cardProgressWrapper} px={1.25}>
                <div className={classes.root}>
                    <LinearProgress
                        color="secondary"
                        variant="determinate"
                        value={percentage}
                        style={{ height: '100%', borderRadius: '100vmax' }}
                    />
                </div>
                <p className={classes.completePercentage}>
                    <span style={{ fontWeight: '500' }}>{percentage}%</span>{' '}
                    Completed
                </p>
            </Box>
        </div>
    );
};

// const StyledDescription = styled.p`
//     height: 5rem;
//     overflow: hidden;
//     position: relative;
//     &::after {
//         content: '""';
//         width: 100%;
//         position: absolute;
//         bottom: 0;
//         left: 0;
//         height: 2.5rem;
//         background-image: linear-gradient(
//             to bottom,
//             rgba(250, 250, 250, 0),
//             rgba(250, 250, 250, 0.9) 90%
//         );
//         z-index: 1;
//         pointer-events: none;
//     }
// `;
