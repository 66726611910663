import React from 'react';
import { useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';
//import { makeStyles } from '@mui/material/styles';
import { ListSearchAndSelect } from '../ui';
import { /* topicsState, */ selectedCoursesState } from './recoil';
import { coursesSlice } from '../courses/coursesSlice';

const { getCourses } = coursesSlice;

/* const useStyles = makeStyles()((theme) => ({
    root: {},
})); */

export const Step3 = () => {
    //const { classes } = useStyles();

    //const [selectedTopics, setSelectedTopics] = useRecoilState(topicsState);

    const courses = useSelector(getCourses);

    const [selectedCourses, setSelectedCourses] = useRecoilState(
        selectedCoursesState
    );

    // const handleCourseSelect = (item) => {
    //     //console.log(item);
    //     setSelectedCourses((prevList) => {
    //         if (prevList.findIndex((elem) => elem.id === item.id) === -1) {
    //             return [item, ...prevList];
    //         } else {
    //             return prevList;
    //         }
    //     });
    // };

    // const handleCourseDelete = (itemToDelete) => {
    //     setSelectedCourses((items) =>
    //         items.filter((item) => item.id !== itemToDelete.id)
    //     );
    // };

    /* const handleTopicSelect = (item) => {
        setSelectedTopics((prevList) => {
            if (prevList.findIndex((elem) => elem.id === item.id) === -1) {
                return [item, ...prevList];
            } else {
                return prevList;
            }
        });
    };

    const handleTopicDelete = (itemToDelete) => {
        setSelectedTopics((items) =>
            items.filter((item) => item.id !== itemToDelete.id)
        );
    }; */

    return (
        <>
            <ListSearchAndSelect
                heading="I also want to learn ..."
                list={courses}
                selectedList={selectedCourses}
                setSelectedList={setSelectedCourses}
                // handleItemSelect={handleCourseSelect}
                // handleItemDelete={handleCourseDelete}
            />
        </>
    );
};
