import { makeStyles } from 'tss-react/mui';
import image from '../img/curvedBg.svg';
import { blue } from '@mui/material/colors';

export const useStyles = makeStyles()((theme) => ({
    // BANNER
    banner: {
        position: 'relative',
        marginBottom: 140,
        overflow: 'visible',
        zIndex: '200',
        marginTop: '-20px',
        padding: '50px 0 20px',
        '&::before': {
            position: 'absolute',
            content: '""',
            top: '-3%',
            left: '-19%',
            width: '125vw',
            height: '150%',
            zIndex: '-300',
            backgroundPosition: 'bottom',
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
        },
        [theme.breakpoints.down('md')]: {
            '&::before': {
                top: '-23%',
            },
        },
        [theme.breakpoints.down('xl')]: {
            '&::before': {
                top: '-30%',
                height: '170%',
            },
        },
    },
    visitBtn: {
        backgroundColor: blue[600],
        width: '100px',
        '&:hover': {
            backgroundColor: blue[800],
        },
    },
    rightRect: {
        position: 'absolute',
        top: '-10%',
        right: '-8vw',
        height: '30vw',
        width: '30vw',
        borderRadius: '50%',
        zIndex: '-200',
        backgroundColor: '#fafafa',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    info: {
        color: '#fafafa',
        margin: ' 20px 0 0 50px',
    },
    instructorName: {
        position: 'relative',
        color: '#fafafa',
        display: 'grid',
        margin: '10px 0',
        placeItems: 'center',
        width: 'fit-content',
        '&::before': {
            position: 'absolute',
            content: '""',
            top: '-6%',
            left: '-30vw',
            clipPath: 'polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%)',
            width: '60vw',
            height: '100%',
            backgroundColor: '#333',
            zIndex: '-10',
        },
        [theme.breakpoints.down('md')]: {
            '&::before': {
                width: '110vw',
                left: '-40vw',
            },
        },
    },
    img: {
        position: 'relative',
        height: '300px',
        width: '350px',
        boxShadow: '0px 5px 10px 1px rgba(0,0,0,0.4)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginBottom: '10px',
    },
    // TOP PICKS COURSE COMPONENT
    link: {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        float: 'right',
        cursor: 'pointer',
        color: '#333',
        margin: '-20px 0 0 0',
    },
    topPicksCourse: {
        position: 'relative',
        // overflow: 'hidden',
        '&:nth-child(even)': {
            marginTop: 25,
        },
    },
    picksBg: {
        position: 'absolute',
        background:
            'linear-gradient(225deg, rgba(89,18,205,1) 0%, rgba(92,14,140,1) 100%)',
        height: '105%',
        width: '85%',
        zIndex: '-10',
        clipPath: 'polygon(15% 1%, 100% 0%, 85% 100%, 0% 100%)',
        [theme.breakpoints.down('md')]: {
            clipPath: 'none',
            width: '200%',
        },
    },
    picksBgCourses: {
        top: 0,
        left: '-20%',
    },
    picksBgOnAir: {
        background: '#e3e3e3',
        right: '-20%',
    },
    sectionHeading: {
        fontSize: 'min(2em,8vw)',
        color: '#fafafa',
        fontWeight: 400,
        cursor: 'default',
    },
    learningTxt: {
        fontSize: 'min(1.8em,8vw)',
        color: '#fafafa',
        fontWeight: 400,
        marginBottom: 20,
        cursor: 'default',
    },
    cardsConatiner: {
        position: 'relative',
        margin: '40px 0',
    },
    card: {
        display: 'flex',
        flexFlow: 'column',
        maxWidth: '350px',
        minHeight: '500px',
        padding: '10px 20px',
        margin: '0 auto',
        cursor: 'pointer',
        borderRadius: 5,
        backgroundColor: '#fafafa',
        transition: '0.2s all ease-out',
        border: '1px solid rgba(0,0,0,0)',
        position: 'relative',
        '&:hover': {
            transform: 'scale(1.05)',
            border: '1px solid rgba(0,0,0,0.1)',
            '& $cardImg': {
                boxShadow: '0px 8px 5px 1px rgba(0,0,0,0.4)',
                transform: 'rotate(-2deg)',
            },
            '& $cardBackdrop': {
                '&::before': {
                    backgroundColor: 'rgba(0,0,0,0.4)',
                },
            },
        },
    },
    cardActions: {
        display: 'flex',
        flexFlow: 'row',
        marginTop: '20px',
        justifyContent: 'center',
        paddingBottom: 25,
    },
    cardBtn: {
        borderRadius: 5,
        width: '100%',
        marginRight: 10,
        paddingBottom: 5,
    },
    cardView: {
        padding: 5,
    },
    cardImg: {
        height: '155px',
        width: '100%',
        'border-top-left-radius': 5,
        'border-top-right-radius': 5,
        'border-bottom-right-radius': 0,
        'border-bottom-left-radius': 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        transition: '0.2s all ease-out',

        boxShadow: '0px 0px 10px 1px rgba(0,0,0,0)',
        overflow: 'hidden',
    },
    // USEING IN ONAIR CARD
    cardBackdrop: {
        position: 'relative',
        borderRadius: 5,
        '&:before': {
            position: 'absolute',
            content: '""',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
            transition: 'all 0.2s ease-in',
            backgroundColor: 'rgba(0,0,0,0)',
        },
    },
    changeside: {
        color: '#333',
    },
    // TIME IN ONAIR
    time: {
        fontSize: '1.2em',
        fontWeight: 500,
        color: '#858585',
        opacity: '1',
    },
    date: {
        marginRight: 10,
        fontSize: '1.2em',
    },
    infoContainer: {
        display: 'flex',
        flexFlow: 'row',
        // justifyContent: 'center',
        alignItems: 'center',
    },
    heading: {
        fontSize: 'min(1.2em, 5vw)',
        fontWeight: 'bold',
        padding: '16px 16px 0px 16px',
        minHeight: 50,
    },
    instructor: {
        width: 'fit-content',
        margin: '10px 0',
    },
    controlBtn: {
        position: 'absolute',
        top: '50%',
        color: '#333',
    },
    right: {
        right: '-30px',
    },
    left: {
        left: '-10px',
    },

    rightArrow: {
        right: '-27px',
    },
    leftArrow: {
        left: '-27px',
    },

    sub: {
        margin: '10px 0',
    },
    load: {
        height: '450px',
        display: 'grid',
        placeItems: 'center',
    },

    // RECOMMENDATIONS
    introRecommendation: {
        marginTop: 50,
        minHeight: 500,
    },

    skipButton: {
        backgroundColor: blue[800],
        color: '#fafafa',
        '&:hover': {
            backgroundColor: blue[900],
        },
    },
    skipBtnContainer: {
        marginLeft: 0,
        marginTop: 15,
    },
    continueBtn: {
        marginTop: 15,
    },
    mainBoxWrap: {
        position: 'relative',
        minHeight: 300,
        cursor: 'pointer',
        [theme.breakpoints.down('lg')]: {
            height: 'auto',
            minHeight: 'auto',
        },
    },
    mainBox: {
        width: '93%',
        borderRadius: '8px',
        marginLeft: '10px',
        marginRight: '10px',
        backgroundColor: '#ffffff',
        boxShadow: '0px 0px 13px 2px #AAA8A8',
        marginTop: '10px',
        position: 'relative',
        '&:hover': {
            position: 'absolute',
            zIndex: 1000,
            transition: 'all 0.3s ease-out',
            transform: 'scale(1.05)',
            '& $desc': {
                visibility: 'visible',
                display: 'block',

                backgroundColor: '#ffffff',
            },
            '& $cardHeading': {
                whiteSpace: 'normal',
            },
        },
    },
    desc: {
        display: 'none',
        padding: '0 8px',
        fontSize: 14,
        marginTop: 0,
    },
    contentBox: {
        width: '100%',
        margin: '0 auto',
        padding: '0px 0px 0px 16px',
        transition: 'all 0.3s ease-in',
        minHeight: '30px',
    },
    subtitle: {
        color: '#9a9a9a',
        fontWeight: 500,
        paddingBottom: 10,
    },
    cardheader: {
        padding: '0px 5px',
        fontWeight: 500,
        position: 'relative',
        '&::before': {
            position: 'absolute',
            content: '""',
            bottom: '-3px',
            left: 7,
            width: '95%',
            height: 2,
            backgroundColor: '#ea6f14',
        },
    },
    cardHeading: {
        margin: '20px 0 5px',
        fontSize: 'min(1.3em, 5vw)',
        fontWeight: 'bold',
        paddingLeft: 10,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
}));
