import {
    Box,
    Button,
    Typography,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from '../ui';
import { useNavigate } from 'react-router-dom';
import { userTrack } from '../util/tracking';
import { StarRateIcon } from '../icons';
import { useStyles } from './styles';
import { Link } from 'react-router-dom';
import { productCheckoutRoute } from '../navigation/route-definitions';
import { useCurrentUser } from '../my-account/useCurrentUser';
import { useMemo } from 'react';
import { astronaut_id } from './PlanDetails';

const COMMANDER = 'Commander';
const ASTRONAUT = 'Astronaut';
const VOYAGER_PLUS = 'Voyager+';
const VOYAGER = 'Voyager';
const NO_USER = '';

export const PlanCard = ({
    value,
    active,
    name,
    features = [],
    originalPrice,
    price,
    creditId,
    creditTooltipText,
    caption,
    id,
    discription,
    trial,
}) => {
    const { classes } = useStyles();
    const { user } = useCurrentUser();
    let navigate = useNavigate();

    // need to make a recoil state for it
    const planName = useMemo(() => {
        if (user) {
            const planName =
                user.user_subscription_id !== ''
                    ? user.user_current_plan === astronaut_id
                        ? 'Astronaut'
                        : 'Commander'
                    : user.plan_name;
            if (planName === 'Voyager+') {
                return VOYAGER_PLUS;
            } else if (planName === 'Astronaut') {
                return ASTRONAUT;
            } else if (planName === 'Commander') {
                return COMMANDER;
            } else {
                return VOYAGER;
            }
        } else {
            return NO_USER;
        }
    }, [user]);

    const handleUpgradeClick = (clickInitiator) => {
        const trackingObject = {
            is_user_registered:
                localStorage.getItem('userId') !== 'null' ? true : false,
            user:
                localStorage.getItem('userId') !== 'null'
                    ? localStorage.getItem('userId')
                    : null,
            page_slug: window.location.pathname,
            activity_type: 'CLICK',
            activity_value: name,
        };
        if (clickInitiator === 'UPGRADE_PLAN') {
            userTrack('upgrade-plan', trackingObject);
        }
        if (clickInitiator === 'CREDITS') {
            userTrack('purchase-credit', trackingObject);
        }
    };

    return (
        <Box
            className={
                active
                    ? `${classes.card} ${classes.activeCard}`
                    : `${classes.card}`
            }
        >
            {active && (
                <Box className={classes.tag}>
                    MOST POPULAR
                    <StarRateIcon color="primary" />
                </Box>
            )}
            <Typography align="left" variant="h3">
                {name}
            </Typography>
            <Box mt={1} mb={1}>
                <Typography variant="subtitle1">{discription}</Typography>
            </Box>
            {(!user || !user?.user_got_free_trial) && (
                <Typography variant="subtitle2" color="#222">
                    {trial ? `Free ${trial}-day trial` : 'Free lifetime access'}
                </Typography>
            )}

            <Typography
                variant="h6"
                style={{ textDecoration: 'line-through' }}
                gutterBottom
            >
                {originalPrice && originalPrice[value] > 0 && (
                    <Box>
                        ${originalPrice[value]}
                        <Typography component="span" variant="subtitle2">
                            {' '}
                            <span>
                                <strong>
                                    {value === 0 ? <>/month</> : <>/year</>}
                                </strong>
                            </span>{' '}
                        </Typography>
                    </Box>
                )}
            </Typography>
            <Typography variant="h4" gutterBottom>
                {/* CHECK FOR THE CURRENCY TYPE HERE */}
                {price !== null && price[value] >= 0 ? (
                    <Box mb={price[value] === 0 && 1}>
                        {price[value] === 0 ? (
                            <Typography variant="h4">Pay-as-you-go</Typography>
                        ) : (
                            `$${price[value]}`
                        )}
                        <Typography component="span" variant="subtitle2">
                            {' '}
                            <span>
                                <strong>
                                    {price[value] === 0 ? (
                                        ''
                                    ) : value === 0 ? (
                                        <>/month</>
                                    ) : (
                                        <>/year</>
                                    )}
                                </strong>
                            </span>{' '}
                        </Typography>
                    </Box>
                ) : (
                    <>On-Hold</>
                )}
            </Typography>
            <Box mb={2} className={classes.cardBtn}>
                {price && price[0] === 0 ? (
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => {
                            navigate(`/credits/checkout?plan_id=${creditId}`);
                        }}
                        disabled={planName === VOYAGER_PLUS}
                        color={active ? 'secondary' : 'primary'}
                    >
                        {planName === VOYAGER_PLUS
                            ? 'I am already a Voyager+'
                            : 'Become a Voyager+'}
                    </Button>
                ) : (
                    // SHOULD PASS PRODUCT ID HERE TO THE BILLING COMPONENT
                    <>
                        {user &&
                        user.user_current_plan === id &&
                        user.user_subscription_status !== 'trialing' ? (
                            <Button
                                fullWidth
                                variant="contained"
                                disabled
                                color="secondary"
                            >
                                I am already{' '}
                                {['a', 'e', 'i', 'o', 'u'].includes(
                                    name.toLocaleLowerCase().charAt(0)
                                )
                                    ? 'an'
                                    : 'a'}{' '}
                                {name}
                            </Button>
                        ) : (
                            <Link
                                to={`${productCheckoutRoute.path}?p_id=${id}&t=${value}`}
                            >
                                <Button
                                    onClick={() =>
                                        handleUpgradeClick('UPGRADE_PLAN')
                                    }
                                    fullWidth
                                    variant="contained"
                                    className={active ? classes.activeBtn : ''}
                                    color="secondary"
                                >
                                    Become{' '}
                                    {['a', 'e', 'i', 'o', 'u'].includes(
                                        name.toLocaleLowerCase().charAt(0)
                                    )
                                        ? 'an'
                                        : 'a'}{' '}
                                    {name}
                                </Button>
                            </Link>
                        )}
                    </>
                )}
            </Box>
            {user && planName === name && (
                <Tooltip title={creditTooltipText}>
                    <Box
                        mb={1}
                        style={{
                            width: '75%',
                        }}
                    >
                        <Link to={`/credits/checkout?plan_id=${creditId}`}>
                            <Button
                                onClick={() => handleUpgradeClick('CREDITS')}
                                fullWidth
                                variant="outlined"
                                color="secondary"
                            >
                                Purchase credits
                            </Button>
                        </Link>
                    </Box>
                </Tooltip>
            )}
            {/* <Typography gutterBottom>{caption}</Typography> */}
            <Box mx="auto" style={{ width: '90%' }}>
                <Divider />
            </Box>
            <List className={classes.list}>
                {features &&
                    features.map((feature, index) => (
                        <ListItem key={feature}>
                            <ListItemIcon>
                                <StarRateIcon
                                    color={active ? 'secondary' : 'primary'}
                                />
                            </ListItemIcon>
                            <ListItemText primary={feature} />
                        </ListItem>
                    ))}
            </List>
            {/* LATER CHANGE TO CHECK THE USERS CURRENT PLAN AND THEN DISPLAY IT */}
        </Box>
    );
};
