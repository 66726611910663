import { Link } from 'react-router-dom';
import { Typography, Box, Button } from '../ui';

export const SignUpSuccess = () => {
    return (
        <Box mt={5}>
            <Typography
                color="secondary"
                style={{ paddingTop: '10px', textAlign: 'center' }}
                variant="h4"
                gutterBottom
            >
                Welcome to the new <strong>Starweaver</strong>
            </Typography>
            <Typography
                variant="subtitle2"
                style={{ textAlign: 'center', fontWeight: 'bold' }}
            >
                Check your email. Please verify within 15 minutes.
            </Typography>
            <Box style={{ margin: '40px auto', width: 'fit-content' }}>
                <Link to="/on-air">
                    <Button variant="contained">
                        Click here to continue
                    </Button>
                </Link>
            </Box>
        </Box>
    );
};
