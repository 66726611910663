import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
    // useRecoilState,
    useSetRecoilState,
    useRecoilValue,
} from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { blue } from '@mui/material/colors';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { PLANS_CARD_DETAILS } from '../plans/PlanDetails';
import { useCurrentUser } from '../my-account/useCurrentUser';
import { useQueryParams } from '../util/useQueryParams';
import {
    Button,
    Container,
    Grid,
    StarweaverLoader,
    StepperProgressBar,
} from '../ui';
import { Step0 } from './Step0';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4Optional } from './Step4Optional';
import {
    jobTitlesState,
    professionalGoalsState,
    isInterestedInLearningWithGroupState,
    selectedDesignationState,
    selectedJobTitlesState,
    selectedProfessionalGoalsState,
    selectedCoursesState,
} from './recoil';
// import { OnBoardingStepper } from './OnBoardingStepper';

const NUM_OF_STEPS = 4;
const LOGGED_OUT_NUM_OF_STEPS = 5;

const useStyles = makeStyles()((theme) => ({
    root: {
        marginTop: 100,
        minHeight: 500,
    },

    skipButton: {
        backgroundColor: blue[800],
        borderRadius: 3,
        color: '#fafafa',
        '&:hover': {
            backgroundColor: blue[900],
        },
    },
    skipBtnContainer: {
        marginLeft: 'auto',
        borderRadius: 5,
    },
    skipBtnStep0Container: {
        marginLeft: 0,
        marginTop: 20,
        borderRadius: 5,
    },
    continueBtn: {
        borderRadius: 5,
    },
    bottomView: {
        marginTop: 20,
        marginBottom: 20,
    },
}));

const ALL_STEPS = [
    'Introduction',
    'Career activities and objectives',
    'Career and segments',
    // 'What do you know?',
    'Activities and objectives',
    // 'What you want to learn?',
    'We are almost done!',
];

export const StudentOnboardingPage = () => {
    const { classes } = useStyles();
    let navigate = useNavigate();
    const { user } = useCurrentUser();
    const { s } = useQueryParams();
    const [isLoading, setIsLoading] = useState(true);
    const [showLoadingScreen, setShowLoadingScreen] = useState(false);
    const [stepNumber, setStepNumber] = useState(s ? parseInt(s) : 0);

    const setJobTitles = useSetRecoilState(jobTitlesState);
    const setProfessionalGoals = useSetRecoilState(professionalGoalsState);

    const STEP_COUNT = useMemo(() => {
        return user ? NUM_OF_STEPS - 1 : LOGGED_OUT_NUM_OF_STEPS - 1;
    }, [user]);

    const planType = useMemo(() => {
        if (user) {
            const plan = PLANS_CARD_DETAILS.filter(
                (item) => item.p_id === user.user_current_plan
            );
            if (plan.length) {
                return plan[0].name;
            } else {
                return user.plan_name;
            }
        } else {
            return 'Voyager';
        }
    }, [user]);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const resJobTitles = await axios.get(
                    `/api/onboarding/job-titles`
                );
                setJobTitles(resJobTitles.data);

                const resProfessionalGoals = await axios.get(
                    `/api/onboarding/professional-goals`
                );
                setProfessionalGoals(
                    resProfessionalGoals.data
                    /* groupBy(resProfessionalGoals.data, 'jobTitleId') */
                );
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
                console.log(err);
            }
        })();
        // eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //     (async () => {
    //         try {
    //             await axios.put(
    //                 `/api/onboarding/status/${user._id}/0% Completed`
    //             );
    //         } catch (err) {
    //             console.log(err);
    //         }
    //     })();
    // }, [user]);

    const selectedDesignation = useRecoilValue(selectedDesignationState);

    const isInterestedInLearningWithGroup = useRecoilValue(
        isInterestedInLearningWithGroupState
    );

    const selectedJobTitles = useRecoilValue(selectedJobTitlesState);

    const selectedProfessionalGoals = useRecoilValue(
        selectedProfessionalGoalsState
    );

    const selectedCourses = useRecoilValue(selectedCoursesState);

    //const selectedTopics = useRecoilValue(topicsState);

    const updateOnBoarding = useCallback(
        async (userId) => {
            setIsLoading(true);
            if (userId) {
                try {
                    await axios.post(`/api/onboarding/answer`, {
                        userId: userId,
                        designation: selectedDesignation,
                        interestedInLearningWithGroups: isInterestedInLearningWithGroup,
                        jobTitleIds: selectedJobTitles.map(({ id }) => id),
                        professionalGoalsIds: selectedProfessionalGoals.map(
                            ({ id }) => id
                        ),
                        //topicsInterested: selectedTopics.map(({ name }) => name),
                        coursesInterestedIds: selectedCourses.map(
                            ({ productable_id }) => productable_id
                        ),
                    });
                    let newStatus = '0% Completed';
                    let percentageCompleted = 0;
                    if (selectedDesignation !== null) percentageCompleted += 25;
                    if (isInterestedInLearningWithGroup !== null)
                        percentageCompleted += 25;
                    if (selectedJobTitles.length !== 0)
                        percentageCompleted += 25;
                    if (selectedProfessionalGoals.length !== 0)
                        percentageCompleted += 25;

                    //if (percentageCompleted !== 100)
                    newStatus = `${percentageCompleted}% Completed`;
                    // if (
                    //     selectedDesignation === null &&
                    //     isInterestedInLearningWithGroup === null &&
                    //     selectedJobTitles.length === 0 &&
                    //     selectedProfessionalGoals.length === 0 &&
                    //     selectedCourses.length === 0
                    // )
                    //     newStatus = 'Skipped';
                    console.log(newStatus);
                    await axios.put(`/api/onboarding/status`, {
                        userId: userId,
                        status: newStatus,
                    });
                    return {
                        success: true,
                        message: ' updated',
                    };
                } catch (error) {
                    setIsLoading(false);
                    return {
                        success: false,
                        message: 'Oops something bad happened!',
                    };
                }
            } else {
                console.log('no-user-id');
            }
        },
        [
            isInterestedInLearningWithGroup,
            selectedCourses,
            selectedDesignation,
            selectedJobTitles,
            selectedProfessionalGoals,
        ]
    );

    const nextStep = useCallback(async () => {
        window.scrollTo(0, 0);
        if (stepNumber === 3) {
            setShowLoadingScreen(true);
            setTimeout(() => {
                setStepNumber(4);
                setShowLoadingScreen(false);
            }, 2000);
        }
        if (stepNumber < STEP_COUNT && !showLoadingScreen)
            setStepNumber((prev) => prev + 1);
        else {
            try {
                const { success } = await updateOnBoarding(user._id);
                if (success) {
                    navigate(`/profile/dashboard/recommendations`);
                } else {
                    console.log('error');
                }
            } catch (err) {
                setIsLoading(false);
                console.log(err);
            }
        }
    }, [
        STEP_COUNT,
        navigate,
        stepNumber,
        showLoadingScreen,
        updateOnBoarding,
        user,
    ]);

    const prevStep = () => {
        window.scrollTo(0, 0);
        if (stepNumber > 0) setStepNumber((prev) => prev - 1);
    };

    const stepSwitch = (stepNum) => {
        switch (stepNum) {
            case 0:
                return (
                    <Container maxWidth="md">
                        <Step0 planType={planType} />
                    </Container>
                );
            case 1:
                return (
                    <Container maxWidth="md">
                        <Step1 />
                    </Container>
                );
            case 2:
                return (
                    <Container maxWidth="md">
                        <Step2 />
                    </Container>
                );
            case 3:
                return (
                    <Container maxWidth="md">
                        <Step3 />
                    </Container>
                );
            case 4:
                return <Step4Optional updateOnBoarding={updateOnBoarding} />;
            default:
                return <div></div>;
        }
    };

    const showContinueBack = useMemo(() => {
        if (stepNumber === 0) return false;
        else return user ? true : stepNumber === STEP_COUNT ? false : true;
    }, [stepNumber, STEP_COUNT, user]);

    return (
        <Container maxWidth="lg" className={classes.root}>
            {showLoadingScreen && (
                <StarweaverLoader loadingText="We're compiling the perfect journeys for you!" />
            )}
            {/* {user && !isLoading && ( */}
            <>
                {/* {stepNumber !== 0 && (
                        <IconButton aria-label="back" onClick={prevStep}>
                            <ArrowBackIcon />
                        </IconButton>
                    )} */}
                <StepperProgressBar
                    steps={ALL_STEPS.slice(0, STEP_COUNT + 1)}
                    step={stepNumber}
                    setTabValue={setStepNumber}
                />
                {stepSwitch(stepNumber)}

                <Container maxWidth="md">
                    {showContinueBack && (
                        <Grid container>
                            <Grid item>
                                <Button variant="outlined" onClick={prevStep}>
                                    Back
                                </Button>
                            </Grid>

                            {/* <Grid item className={classes.skipBtnContainer}>
                                <Button
                                    className={classes.skipButton}
                                    onClick={nextStep}
                                >
                                    Skip
                                </Button>
                            </Grid> */}
                            <Grid item className={classes.skipBtnContainer}>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    className={classes.continueBtn}
                                    onClick={nextStep}
                                >
                                    Continue
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Container>

                {stepNumber === 0 && (
                    <Grid container className={classes.bottomView}>
                        <Button
                            color="secondary"
                            variant="contained"
                            fullWidth
                            className={classes.continueBtn}
                            onClick={nextStep}
                        >
                            Continue
                        </Button>
                        {/* <Grid
                                item
                                className={classes.skipBtnStep0Container}
                            >
                                <Button
                                    className={classes.skipButton}
                                    onClick={skipAll}
                                >
                                    Skip
                                </Button>
                            </Grid> */}
                    </Grid>
                )}
            </>
            {/* )} */}
        </Container>
    );
};
