import { makeStyles } from 'tss-react/mui';
import { grey, blue } from '@mui/material/colors';

export const useStyles = makeStyles()((theme) => ({
    // NAV TAB:
    navTab: {
        borderRadius: '3px',
        borderBottom: 'none',
        boxShadow: '0px 0px 10px 2px rgba(0,0,0,0.3)',
    },
    card: {
        margin: '40px 20px 10px',
        display: 'flex',
        flexFlow: 'column',
        padding: '20px 5px 5px',
        position: 'relative',
        maxWidth: '600px',
        textAlign: 'center',
        alignItems: 'center',
        height: 'auto',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
            margin: '30px 10px',
        },
        [theme.breakpoints.up('lg')]: {
            height: 'auto',
            margin: '40px auto 10px',
        },
    },
    list: {
        margin: '5px 0',
    },
    compareBtn: {
        fontSize: '2em',
        textTransform: 'none',
        backgroundColor: blue[700],
        '&:hover': {
            backgroundColor: blue[900],
        },
    },
    activeCard: {
        boxShadow: '0px 0px 20px 10px rgba(234,113,18,0.2)',
        paddingTop: '45px',
        transform: 'scale(1.07) translateY(-14px)',
        border: '2px solid rgba(234,113,18,0.4)',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
            margin: '30px 10px',
        },
    },
    tag: {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '80%',
        color: 'white',
        backgroundColor: blue[700],
        top: '-15px',
        boxShadow: '0px 5px 20px 1px rgb(0 0 0 / 30%)',
        padding: '5px 10px',
        borderRadius: '2px',
    },
    cardBtn: {
        width: '90%',
    },
    planContainer: {
        margin: '17px 0 0px',
    },
    browsingContainer: {
        margin: '0px auto ',
        padding: '10px ',
        display: 'flex',
        flexFlow: 'row',
    },
    link: {
        transistion: ' all 0.2s ease-out',
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.common.blue,
            textDecoration: 'underline',
        },
    },
    activeBtn: {
        backgroundColor: blue[700],
        color: '#fafafa',
        '&:hover': {
            backgroundColor: blue[900],
        },
    },
    // PLANS COMPARISON STYLES
    // -------------NEW STYLES-------------------
    // -------------Desktop Styles-------------------
    table: {
        tableLayout: 'fixed',
    },
    stickyPlanSummary: {
        backgroundColor: '#fafafa',
        padding: '20px',
        boxShadow: 'none',
    },
    tablePlanHeadingContainer: {
        width: '90%',
        backgroundColor: 'rgba(150,150,150,0.2)',
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 8,
        marginBottom: 8,
        borderRadius: 2,
        margin: '0 auto',
        boxShadow: theme.shadows[2],
    },
    tableContainer: {
        width: '90%',
        margin: '0 auto',
    },
    tablePlanNameHeading: {
        fontSize: '1.2em',
        fontWeight: 700,
        color: '#666',
        margin: '5px 0 20px',
    },
    tableHeadingContainer: {
        width: '90%',
        margin: '0px auto 20px',
    },
    tablePlanName: {
        fontSize: '1.5em',
        fontWeight: 700,
        margin: '5px 0 10px',
    },
    tablePlanNameMobile: {
        fontSize: '1.5em',
        fontWeight: 700,
        // margin: '5px 0 10px',
        backgroundColor: 'rgba(150,150,150,0.2)',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 8,
        marginBottom: 8,
        borderRadius: 2,
        margin: '0 auto',
        boxShadow: theme.shadows[2],
    },

    tableFeatureNameHeading: {
        fontSize: '1.2em',
        fontWeight: 700,
        color: '#666',
    },
    // check: {
    //     color: green[500],
    //     fontWeight: 600,
    // },
    unCheck: {
        fontWeight: 600,
        color: grey[500],
    },
    // -------------Mobile Styles-------------------
    planChangeBarItem: {
        padding: '10px 15px',
        cursor: 'pointer',
        border: '2px solid #c5c5c5',
        borderRight: 'none',
        transition: '0.2s all ease-out',
        '&:hover': {
            backgroundColor: '#e5e5e5',
        },
        '&:last-of-type': {
            borderRight: '2px solid #c5c5c5',
        },
    },
    activePlanItem: {
        color: blue[700],
        fontWeight: 600,
        border: `2px solid ${blue[500]} !important`,
        '& + div': {
            borderLeft: 'none',
        },
    },
    planDetailsCard: {
        border: '3px solid #c5c5c5',
        boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.2)',
    },
    priceHeading: {
        fontSize: '1.3em',
        marginTop: '15px',
        fontWeight: 600,
    },
    // -------------NEW STYLES-------------------
    productAccordion: {
        margin: '10px 0',
    },
    getBtn: {
        margin: '5px 0',
        borderRadius: '2px',
    },
    infoButton: {
        backgroundColor: 'rgba(150,150,150,0.4)',
        borderRadius: '50%',
        display: 'flex',
        width: 'fit-content',
        padding: '2px 9px',
        fontWeight: 900,
        fontSize: '0.8em',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    centerTab: {
        width: 'fit-content',
        margin: '20px auto',
        boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.1)',
    },
    accordionDetails: {
        display: 'flex',
        flexFlow: 'column',
    },
    planName: {
        fontSize: 'min(4vw,1.5em)',
        fontWeight: 900,
    },
    price: {
        fontSize: 'min(4vw,1.5em)',
        fontWeight: 600,
        margin: '5px 0',
    },
    itemName: {
        fontWeight: 600,
        textAlign: 'left',
    },
    valueContainer: {
        display: 'flex',
        flexFlow: 'row',
    },
    column: {
        flexBasis: '25%',
        textAlign: 'center',
    },
    firstColumn: {
        textAlign: 'left',
    },
}));
