import { useMemo, useState } from 'react';
import { CommentSection } from '../comments/CommentSection';
import { CourseAutocompleteSection } from './CourseAutocompleteSection';
import { Box, Grid, Typography } from '../ui';
import { useCurrentUser } from '../my-account/useCurrentUser';
import profilePicPlaceholder from '../img/profilePicPlaceholder.jpg';

export const CourseComments = ({ courses = [] }) => {
    const [selectedOption, setSelectedOption] = useState(
        courses[0].name || courses[0].title
    );

    const { user: userDetails, isLoading: isUserLoading } = useCurrentUser();

    const courseOptions = useMemo(() => {
        if (courses.length) {
            return courses.map((course) => course.name || course.title);
        } else {
            return [];
        }
    }, [courses]);
    const value = useMemo(() => {
        if (selectedOption && courseOptions.length) {
            return courseOptions.indexOf(selectedOption);
        }
    }, [selectedOption, courseOptions]);

    const mentionables = useMemo(() => {
        return (
            courses[value]?.instructors
                ?.filter(({ email }) => userDetails?.user_email !== email)
                ?.map(
                    ({
                        instructor,
                        instructorSlug,
                        email,
                        instructorPic,
                        instructorPicNoBackground,
                    }) => ({
                        id: instructorSlug,
                        name: instructor,
                        _name: instructor,
                        email,
                        profilePicUrl:
                            instructorPic ||
                            instructorPicNoBackground ||
                            profilePicPlaceholder,
                    })
                ) || []
        );
    }, [courses[value], userDetails]);

    return (
        <Box>
            {courses?.length > 0 && (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box>
                            <Typography align="center" variant="h5">
                                {courses[value].name || courses[value].title}{' '}
                                Discussion
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box mt={2}>
                            <CourseAutocompleteSection
                                selectedOption={selectedOption}
                                setSelectedOption={setSelectedOption}
                                courseOptions={courseOptions}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Box mx="auto">
                            <CommentSection
                                courseId={courses[value].id}
                                courseName={
                                    courses[value].name || courses[value].title
                                }
                                courseSlug={courses[value].slug}
                                mentionables={mentionables}
                                placeholder="Tag an instructor with @ to notify them..."
                            />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};
