import { useMemo } from 'react';
import ReactHTMLParser from 'react-html-parser';
import {
    ListBox,
    SectionContainer,
    SectionHeading,
} from '../custom-components/styled-components';

const NewPrerequisites = (props) => {
    const { wpCourseData } = props;
    const prerequisites = useMemo(() => {
        return ReactHTMLParser(wpCourseData?.prerequisites);
    }, [wpCourseData?.prerequisites]);

    return (
        <SectionContainer id="prerequisites">
            <SectionHeading>Prerequisites</SectionHeading>
            <ListBox>{prerequisites}</ListBox>
        </SectionContainer>
    );
};

export default NewPrerequisites;
