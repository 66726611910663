import { useMemo } from 'react';
// import styled from 'styled-components';
import {
    SectionContainer,
    SectionHeading,
} from '../custom-components/styled-components';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    FlexWrapper: { display: 'flex', gap: '1rem', flexWrap: 'wrap' },
    TagContainer: {
        padding: '0.5rem',
        backgroundColor: '#ea7112',
        color: '#fff',
        borderRadius: '0.2rem',
        fontWeight: '500',
        boxShadow: '2px 2px 5px 0px #938d8d',
    },
}));

const SkillsSection = (props) => {
    const { classes } = useStyles();
    const { wpCourseData } = props;

    const tags = useMemo(() => {
        return wpCourseData?.tagList;
    }, [wpCourseData?.tagList]);

    return (
        <SectionContainer id="skills-you-gain">
            <SectionHeading>Skills You Will Gain​</SectionHeading>

            <div className={classes.FlexWrapper}>
                {tags.map((tag) => {
                    return (
                        <div key={tag} className={classes.TagContainer}>
                            {tag}
                        </div>
                    );
                })}
            </div>
        </SectionContainer>
    );
};

// const FlexWrapper = styled.div`
//     display: flex;
//     gap: 1rem;
//     flex-wrap: wrap;
// `;

// const TagContainer = styled.div`
//     padding: 0.5rem;
//     background-color: #ea7112;
//     color: #fff;
//     border-radius: 0.2rem;
//     font-weight: 500;
//     box-shadow: 2px 2px 5px 0px #938d8d;
// `;

export default SkillsSection;
