import {
    Box,
    Chip,
    CircularProgress,
    Grid,
    IconButton,
    Typography,
    Tooltip,
    Button,
} from '../ui';
import Fade from '@mui/material/Fade';
import { useNavigate, Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useStyles } from './styles';
import { ChevronRightIcon, ChevronLeftIcon } from '../icons';

export const UserRsvps = ({
    data,
    onClickRemove = () => {
        console.log('No Remove Function');
    },
}) => {
    const isLarge = useMediaQuery('(min-width:1200px)');
    const isSmall = useMediaQuery('(min-width:900px)');
    const isMedium = useMediaQuery('(min-width:600px)');
    const { classes } = useStyles();
    const currentIndex = useRef(0);
    const [displayCourse, setDisplayCourse] = useState(null);
    const [size, setSize] = useState(null);
    const [loaded, setLoaded] = useState(false);
    let navigate = useNavigate();

    function getSize() {
        if (isLarge || isSmall) {
            return 4;
        } else if (isMedium) {
            return 2;
        } else {
            return 1;
        }
    }

    useEffect(() => {
        const getNewSize = getSize();
        setSize(getNewSize);
    }, [isLarge, isMedium]);

    const setNext = () => {
        currentIndex.current += size;
        if (currentIndex.current > data.length - 1) {
            currentIndex.current = 0;
        }
        const newAr = data.slice(
            currentIndex.current,
            currentIndex.current + size
        );
        setDisplayCourse(newAr);
        setLoaded(true);
    };

    const setPrev = () => {
        currentIndex.current -= size;
        if (currentIndex.current < 0) {
            currentIndex.current = data.length - size;
        }
        const newAr = data.slice(
            currentIndex.current,
            currentIndex.current + size
        );
        setDisplayCourse(newAr);
        setLoaded(true);
    };

    useEffect(() => {
        if (size) {
            currentIndex.current = -size;
            setNext();
        }
    }, [size]);

    useEffect(() => {
        if (data && size) {
            currentIndex.current = -size;
            setNext();
        }
    }, [data]);

    return (
        <Box>
            {/* IF DATA IS NOT THERE */}
            {/* <Typography
                style={{
                    width: '100%',
                    textAlign: 'left',
                    color: 'rgb(51, 51, 51)',
                    fontWeight: 400,
                    fontSize: 'min(2em,8vw)',
                }}
                className={classes.sectionHeading}
            >
                Your Course Registrations
            </Typography> */}
            <Grid container className={classes.cardsConatiner}>
                {displayCourse ? (
                    <>
                        {displayCourse.map((item, index) => {
                            let delay = 0;
                            if (index > 0) {
                                delay = Math.trunc((500 / size) * index);
                            }
                            return (
                                <Fade
                                    in={loaded}
                                    style={{
                                        transitionDelay: loaded
                                            ? `${delay}ms`
                                            : '0ms',
                                    }}
                                >
                                    <Grid
                                        key={index}
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={3}
                                        className={classes.mainBoxWrap}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <Box
                                            className={classes.mainBox}
                                            // onClick={() => handleClick(item)}
                                        >
                                            <Box
                                                component="div"
                                                /* style={{
                                backgroundImage: `url(${item.sliderImage})`,
                            }} */
                                                className={classes.cardImg}
                                            >
                                                <img
                                                    src={item.sliderImage}
                                                    width="100%"
                                                    alt="Register"
                                                ></img>
                                            </Box>
                                            <Typography
                                                className={classes.cardHeading}
                                            >
                                                {item.title}
                                            </Typography>
                                            <Box pl={2} pr={1}>
                                                <Typography
                                                    className={classes.time}
                                                >
                                                    {item.instructor} |{' '}
                                                    {item.domain} | {item.time}{' '}
                                                    | {item.timeZone}
                                                </Typography>
                                            </Box>
                                            <Box
                                                pl={2}
                                                pr={1}
                                                component="div"
                                                className={classes.contentBox}
                                            >
                                                <Box
                                                    className={
                                                        classes.infoContainer
                                                    }
                                                >
                                                    <Typography
                                                        variant="subtitle2"
                                                        className={classes.date}
                                                    >
                                                        {item.date}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    component="p"
                                                    className={classes.desc}
                                                >
                                                    {item.description.length >
                                                    150 ? (
                                                        <>
                                                            {item.description.substr(
                                                                0,
                                                                150
                                                            )}
                                                            ...
                                                        </>
                                                    ) : (
                                                        <>{item.description}</>
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box pl={2} pr={1}>
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    style={{ display: 'flex' }}
                                                    className={
                                                        classes.cardActions
                                                    }
                                                >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={6}
                                                    >
                                                        <Link
                                                            to={`/on-air/${item.name}`}
                                                        >
                                                            <Button
                                                                className={
                                                                    classes.cardBtn
                                                                }
                                                                style={{
                                                                    fontSize:
                                                                        '0.9em',
                                                                }}
                                                                color="secondary"
                                                                variant="contained"
                                                            >
                                                                View
                                                            </Button>
                                                        </Link>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={6}
                                                    >
                                                        <Button
                                                            className={
                                                                classes.cardBtn
                                                            }
                                                            variant="outlined"
                                                            color="secondary"
                                                            onClick={() =>
                                                                onClickRemove(
                                                                    item
                                                                )
                                                            }
                                                        >
                                                            You are in
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Fade>
                            );
                        })}
                    </>
                ) : (
                    <Box className={classes.load}>
                        <CircularProgress color="secondary" />
                    </Box>
                )}

                <>
                    <IconButton
                        className={`${classes.controlBtn} ${classes.left}`}
                        onClick={() => {
                            if (size >= data.length) {
                                return;
                            }
                            setLoaded(false);
                            setTimeout(() => setPrev(), 500);
                        }}
                        size="large"
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                    <IconButton
                        className={`${classes.controlBtn} ${classes.right}`}
                        onClick={() => {
                            if (size >= data.length) {
                                return;
                            }
                            setLoaded(false);
                            setTimeout(() => setNext(), 500);
                        }}
                        size="large"
                    >
                        <ChevronRightIcon />
                    </IconButton>
                </>
            </Grid>
        </Box>
    );
};
