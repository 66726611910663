import React, { useState } from 'react';
import welcomeImg from './assets/student-onboarding-welcome.png';
// import { makeStyles } from '@mui/material/styles';
// import { blue } from '@mui/material/colors';
import { COMMANDER, ASTRONAUT, VOYAGER_PLUS } from '../plans/PlanDetails';
import { Box } from '../ui';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    videoContainer: {
        //width: '900px',
        height: '500px',
        //margin: 'auto',
        [theme.breakpoints.down('md')]: {
            height: '350px',
            //transform: 'translate(0px, -100px)',
        },
    },
}));

export const Step0 = ({ planType }) => {
    const { classes } = useStyles();

    switch (planType) {
        case VOYAGER_PLUS: {
            return (
                <>
                    {' '}
                    <Box className={classes.videoContainer}>
                        <iframe
                            src="https://player.vimeo.com/video/657817452?h=5cb44a3877&autoplay=1&loop=1&title=0&byline=0&portrait=0%22"
                            width="100%"
                            height="100%"
                            frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </Box>
                    {/* <iframe
                        src="https://player.vimeo.com/video/572110163?h=c4ee26fa69&autoplay=1&loop=1&title=0&byline=0&portrait=0%22"
                        width="100%"
                        height="508px"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                    ></iframe> */}
                </>
            );
        }
        case ASTRONAUT: {
            return (
                <>
                    <Box className={classes.videoContainer}>
                        <iframe
                            src="https://player.vimeo.com/video/657817506?h=6dbab08df1&autoplay=1&loop=1&title=0&byline=0&portrait=0%22"
                            width="100%"
                            height="100%"
                            frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </Box>

                    {/* <iframe
                        src="https://player.vimeo.com/video/569362934?h=e5d00ed5bd&autoplay=1&loop=1&title=0&byline=0&portrait=0%22"
                        width="100%"
                        height="508px"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                    ></iframe> */}
                </>
            );
        }
        case COMMANDER: {
            return (
                <>
                    <Box className={classes.videoContainer}>
                        <iframe
                            src="https://player.vimeo.com/video/657828608?h=d4fbb4d601&autoplay=1&loop=1&title=0&byline=0&portrait=0%22"
                            width="100%"
                            height="100%"
                            frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </Box>

                    {/* <iframe
                        src="https://player.vimeo.com/video/569362934?h=e5d00ed5bd&autoplay=1&loop=1&title=0&byline=0&portrait=0%22"
                        width="100%"
                        height="508px"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                    ></iframe> */}
                </>
            );
        }
        default: {
            return (
                <>
                    <Box className={classes.videoContainer}>
                        <iframe
                            src="https://player.vimeo.com/video/657819175?h=d68d07e5b9&autoplay=1&loop=1&title=0&byline=0&portrait=0%22"
                            width="100%"
                            height="480"
                            frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </Box>

                    {/* <iframe
                        title="teaser-video"
                        width="100%"
                        height="508px"
                        src="https://player.vimeo.com/video/589393213?h=f6284c6998&autoplay=1&loop=1&title=0&byline=0&portrait=0%22"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe> */}
                </>
            );
        }
    }
};
