import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useMemo, useState, useEffect } from 'react';
import {
    SectionContainer,
    SectionHeading,
} from '../custom-components/styled-components';
import SWCourseCards from '../custom-components/SWCourseCards';
import Carousel from 'react-material-ui-carousel';
import {
    NavigateNextRounded,
    NavigateBeforeRounded,
} from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import NoCourseImg from '../img/private_course.svg';

const useStyles = makeStyles()((theme) => ({
    cardContainer: {
        '& article': {
            padding: 0,
        },
    },
    courseBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '&  h6': {
            marginTop: '0.5rem',
            color: '#6b7280',
        },
    },
    imgBox: {
        width: '100%',
        maxWidth: 300,
        height: 200,
        '& > img': {
            height: '100%',
            width: '100%',
        },
    },
}));

const NoCoursesBox = () => {
    const { classes } = useStyles();
    return (
        <div className={classes.courseBox}>
            <div className={classes.imgBox}>
                <img src={NoCourseImg} alt="" height={200} width={300} />
            </div>
            <Typography variant="h6">No Related Courses Found!!!</Typography>
        </div>
    );
};

const NewRelatedCourses = (props) => {
    const { wpCourseData } = props;
    const { classes } = useStyles();
    const isLarge = useMediaQuery('(min-width: 64em)');
    const isMedium = useMediaQuery('(min-width: 48em)');
    const [itemPerSlide, setItemPerSlide] = useState(1);

    useEffect(() => {
        if (isLarge) {
            setItemPerSlide(3);
        } else if (isMedium) {
            setItemPerSlide(2);
        } else {
            setItemPerSlide(1);
        }
    }, [isLarge, isMedium]);

    const courses = useMemo(() => {
        const relatedCourses = wpCourseData?.relatedCourses || [];
        return relatedCourses;
    }, [wpCourseData?.relatedCourses]);

    const carouselItems = useMemo(() => {
        const arr = [];
        let currIdx = 0;
        let startIdx = 0;

        while (startIdx < courses.length) {
            if (arr[currIdx]?.length === itemPerSlide) {
                currIdx++;
            } else {
                const endIdx = startIdx + itemPerSlide;
                arr[currIdx] = courses.slice(startIdx, endIdx);
                startIdx = endIdx;
            }
        }

        return arr;
    }, [courses, itemPerSlide]);

    return (
        <SectionContainer id="related-courses">
            <SectionHeading>Related Courses</SectionHeading>
            {carouselItems.length === 0 ? (
                <NoCoursesBox />
            ) : (
                <Carousel
                    NextIcon={<NavigateNextRounded />}
                    PrevIcon={<NavigateBeforeRounded />}
                    // navButtonsAlwaysVisible={true}
                    duration={1500}
                    height={700}
                    indicators={false}
                >
                    {carouselItems.map((item, idx) => {
                        return (
                            <Grid
                                container
                                spacing={2}
                                key={`course-card-container-${idx}`}
                            >
                                {item.map((course) => {
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            lg={4}
                                            className={classes.cardContainer}
                                            key={course.thinkificCourseId}
                                        >
                                            <SWCourseCards
                                                course={course}
                                                domain={course.domain}
                                                parseHTML={true}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        );
                    })}
                </Carousel>
            )}
        </SectionContainer>
    );
};
export default NewRelatedCourses;
